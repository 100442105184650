export const teamData = [
    {
        title: 'ідея і розробка',
        team: [
            {
                img: '',
                name: 'Олекса Стрижак',
                position: 'Курінний отман куреня ЛотиПроти.',
                social: { type: 'twitter', text: '@roqueforeJS', link: '@roqueforeJS' },
            },
            {
                img: '',
                name: 'Mаксим лемич',
                position: 'Військовий  кантаржій проекту. Node.js Developer',
                social: { type: 'linkedin', text: 'lemichmaksym', link: 'lemichmaksym' },
            },
        ],
    },
    {
        title: 'Маркетинг',
        team: [
            {
                img: '',
                name: 'Богдан савчук',
                position: 'Військовий писар. Marketing Zirochka',
                social: { type: '', text: '', link: '' },
            },
            {
                img: '',
                name: 'юлія пронь',
                position: 'Військовий осавул',
                social: { type: 'twitter', text: '@roqueforeJS', link: '@roqueforeJS' },
            },
            {
                img: '',
                name: 'катерина халімончук',
                position: 'Військовий пушкар',
                social: { type: 'twitter', text: '@roqueforeJS', link: '@roqueforeJS' },
            },
        ],
    },
    {
        title: 'Дизайн',
        team: [
            {
                img: '',
                name: 'валерія рудик',
                position: 'Військовий бунчужний. Web Designer',
                social: { type: 'twitter', text: '@roqueforeJS', link: '@roqueforeJS' },
            },
            {
                img: '',
                name: 'вікторія самошина',
                position: 'Військовий хорунжий. Junior UX Designer',
                social: { type: 'twitter', text: '@roqueforeJS', link: '@roqueforeJS' },
            },
        ],
    },
];
