// Core
import React, { FC, ReactElement, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// MUI
import { Backdrop, IconButton, Slide, Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

// Ctx
import { UiContext } from 'domains/ui/context/ui.context';
import { AuthContext } from 'domains/auth/context/auth.context';

// Instruments
import { AppRoutes } from 'routes';
import { MenuContainer } from './styled';
import { useButtonActions, useBreakpoints } from 'domains/ui/hooks';
import { LandingMarkupIds } from 'domains/landing/types';
import { palette } from '../_mixins/_palette';
import { TextButton } from '../TextButton';
import { Typography } from '../_styled/Text';
import { GridItem } from '../_styled/GridItem';
import { SocialNetworkIconWrapper } from '../_styled/SocialNetworkIconWrapper';
import { FooterLink } from '../Footer/styled';

export const Menu: FC = (): ReactElement => {
    const {
        variables: { minPaddingX },
    } = useBreakpoints();
    const [menuOpened, setMenuOpenedState] = useState(false);
    const location = useLocation();

    const navigate = useNavigate();
    const { setOtpDialogState } = useContext(UiContext);
    const {
        handleCreateNewLot,
        openAuction,
        handleOpenTelegram,
        handleOpenTwitter,
        handleOpenFacebook,
        handleOpenInstagram,
        handleOpenFulcrum,
        openProfile,
    } = useButtonActions();

    const { logoutAsync, isAuthenticated } = useContext(AuthContext);

    const handleMenuOpen = () => {
        setMenuOpenedState(true);
    };

    const handleMenuClose = () => {
        setMenuOpenedState(false);
    };

    const handleMenuClick = (action: () => void) => () => {
        handleMenuClose();
        action();
    };

    const linkStyle = {
        height: 30,
    };

    const NavigationLinks = [
        {
            title: 'Офлайн-подія',
            action: () => navigate(`${AppRoutes.CHARITY}`),
        },
        { title: 'Всі лоти', action: handleMenuClick(openAuction) },
        { title: 'Створити лот', action: handleMenuClick(handleCreateNewLot) },
        // { title: 'Команда', action: handleMenuClick(handleCreateNewLot) },
        // {
        //     title: 'Про аукціон',
        //     action: handleMenuClick(() =>
        //         navigate(`${AppRoutes.LANDING}#${LandingMarkupIds.HOW_IT_WORKS}`),
        //     ),
        // },
        {
            title: 'Найпоширеніші запитання',
            action: handleMenuClick(() =>
                navigate(`${AppRoutes.LANDING}#${LandingMarkupIds.WHAT_TO_DO}`),
            ),
        },
        {
            title: 'Результати аукціону',
            action: handleMenuClick(() => navigate(`${AppRoutes.ARCHIVE}`)),
        },
        {
            title: 'Підписатись на оновлення',
            action: handleMenuClick(() =>
                navigate(`${AppRoutes.LANDING}#${LandingMarkupIds.SOCIALS}`),
            ),
        },
    ];

    return (
        <div>
            {/* Button */}
            <IconButton
                aria-label='menu'
                onClick={handleMenuOpen}
                sx={{ position: 'relative', left: 10 }}
            >
                <Tooltip title={<Typography.Tooltip>Навігація</Typography.Tooltip>}>
                    <MenuIcon style={{ width: 48, height: 48 }} />
                </Tooltip>
            </IconButton>

            {/* Modal */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={menuOpened}
            >
                <Slide in={menuOpened} direction='down'>
                    <MenuContainer>
                        <IconButton
                            aria-label='close'
                            onClick={handleMenuClose}
                            sx={{
                                placeSelf: 'start end',
                                position: 'absolute',
                                top: '40px',
                                right: `${minPaddingX}px`,
                            }}
                        >
                            <CloseIcon sx={{ width: '40px', height: '40px' }} />
                        </IconButton>
                        {NavigationLinks.map((item, id) => (
                            <TextButton
                                key={id}
                                onClick={item.action}
                                text={item.title}
                                overrideHoverColor={palette.MainYellow}
                                style={linkStyle}
                                borderless
                                hideArrow
                            />
                        ))}

                        {isAuthenticated ? (
                            <TextButton
                                onClick={
                                    location.pathname === AppRoutes.PROFILE
                                        ? handleMenuClick(logoutAsync)
                                        : handleMenuClick(openProfile)
                                }
                                text={
                                    location.pathname === AppRoutes.PROFILE
                                        ? 'Вихід'
                                        : 'Особистий кабінет'
                                }
                                overrideHoverColor={palette.MainYellow}
                                style={linkStyle}
                                borderless
                                hideArrow
                            />
                        ) : (
                            <TextButton
                                onClick={handleMenuClick(() => setOtpDialogState(true))}
                                text={'Вхід / Реєстрація'}
                                overrideHoverColor={palette.MainYellow}
                                style={linkStyle}
                                borderless
                                hideArrow
                            />
                        )}

                        <GridItem
                            style={{
                                display: 'flex',
                                gap: 30,
                            }}
                        >
                            <SocialNetworkIconWrapper height={40} onClick={handleOpenTwitter}>
                                <TwitterIcon sx={{ color: palette.MainBlue }} />
                            </SocialNetworkIconWrapper>
                            <SocialNetworkIconWrapper height={40} onClick={handleOpenTelegram}>
                                <TelegramIcon sx={{ color: palette.MainBlue }} />
                            </SocialNetworkIconWrapper>
                            <SocialNetworkIconWrapper height={40} onClick={handleOpenInstagram}>
                                <InstagramIcon sx={{ color: palette.MainBlue }} />
                            </SocialNetworkIconWrapper>
                            <SocialNetworkIconWrapper height={40} onClick={handleOpenFacebook}>
                                <FacebookIcon sx={{ color: palette.MainBlue }} />
                            </SocialNetworkIconWrapper>
                        </GridItem>

                        <FooterLink
                            onClick={handleOpenFulcrum}
                            style={{
                                fontSize: 16,
                                textDecoration: 'underline',
                                gridTemplateColumns: '1fr',
                            }}
                        >
                            fulcrum.rocks
                        </FooterLink>
                    </MenuContainer>
                </Slide>
            </Backdrop>
        </div>
    );
};
