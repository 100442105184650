import { useEffect, useState } from 'react';

const getReturnValues = (countDown: number) => {
    // calculate time left
    const seconds = Math.floor(countDown / 1000);

    return { seconds };
};

export const useCountdown = (targetDate: Date | null, onExpired?: () => void) => {
    const countDownDate = new Date(targetDate || Date.now()).getTime();

    const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());

    useEffect(() => {
        if (targetDate) {
            const interval = setInterval(() => {
                const newDate = countDownDate - new Date().getTime();

                if (newDate <= 0 && onExpired) {
                    onExpired();
                } else {
                    setCountDown(newDate);
                }
            }, 1000);

            return () => clearInterval(interval);
        }
        // eslint-disable-next-line
    }, [countDownDate]);

    return targetDate ? getReturnValues(countDown) : getReturnValues(0);
};
