// Core
import React, { FC } from 'react';

// UI
import { Fade, Grid } from '@mui/material';

// Ctx

import { useBreakpoints } from 'domains/ui/hooks';

import { CharityAbout } from 'domains/charity/components/CharityAbout';
import { CharityBanner } from 'domains/charity/components/CharityBanner';
import { CharityHeader } from 'domains/charity/components/CharityHeader';
import { CharityCycledInfo } from 'domains/charity/components/CharityCycledInfo';
import { CharityInfo } from 'domains/charity/components/CharityInfo';

export const CharityAuctionMain: FC = () => {
    const { breakpoints } = useBreakpoints();

    return (
        <Fade in>
            <Grid container style={{ marginBottom: breakpoints.lg ? '150px' : '120px' }}>
                <CharityHeader />
                <CharityBanner />
                <CharityAbout />
                <CharityCycledInfo />
                <CharityInfo />
            </Grid>
        </Fade>
    );
};
