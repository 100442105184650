// Core
import React, { FC, ReactElement } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Instruments
import { AppRoutes } from 'routes';
import { useButtonActions } from 'domains/ui/hooks';
import { LandingMarkupIds } from 'domains/landing/types';
import { palette } from '../_mixins/_palette';
import { TextButton } from '../TextButton';
import { Container } from '@mui/material';

export const HeaderNavigation: FC = (): ReactElement => {
    const navigate = useNavigate();
    const { openAuction } = useButtonActions();
    const location = useLocation();

    const NavigationLinks = [
        { title: 'Всі лоти', action: openAuction, pathname: AppRoutes.APP },
        // { title: 'Команда', action: () => console.log('TEAM') },
        {
            title: 'Про аукціон',
            // pathname: `#${LandingMarkupIds.HOW_IT_WORKS}`,
            action: () => navigate(`${AppRoutes.LANDING}#${LandingMarkupIds.HOW_IT_WORKS}`),
        },
        {
            title: 'Результати',
            pathname: AppRoutes.ARCHIVE,
            action: () => navigate(`${AppRoutes.ARCHIVE}`),
        },
        // {
        //     title: 'Деталі',
        //     // pathname: `#${LandingMarkupIds.WHAT_TO_DO}`,
        //     action: () => navigate(`${AppRoutes.LANDING}#${LandingMarkupIds.WHAT_TO_DO}`),
        // },

        {
            title: 'соціальні мережі',
            // pathname: `#${LandingMarkupIds.SOCIALS}`,
            action: () => navigate(`${AppRoutes.LANDING}#${LandingMarkupIds.SOCIALS}`),
        },
        {
            title: 'Офлайн-подія',
            action: () => navigate(`${AppRoutes.CHARITY}`),
        },
    ];

    return (
        <Container
            sx={{ display: 'grid', gridAutoFlow: 'column' }}
            style={{
                maxWidth: 850,
                marginLeft: 15,
            }}
        >
            {NavigationLinks.map((item, id) => (
                <TextButton
                    key={id}
                    onClick={() => item.action()}
                    text={item.title}
                    overrideHoverColor={palette.MainYellow}
                    style={{
                        fontSize: 16,
                        color:
                            location.pathname + location.search === item.pathname
                                ? palette.MainYellow
                                : '',
                    }}
                    borderless
                    hideArrow
                />
            ))}
        </Container>
    );
};
