import styled from 'styled-components';
import { palette } from '../_mixins/_palette';

export const MenuContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;

    display: grid;
    grid-auto-rows: max-content;
    place-items: center;
    gap: 35px;

    width: 100vw;
    padding: 90px 0 45px;

    background: ${palette.MainBlue};
    overflow: scroll;
    height: 100vh;
`;
