// Core
import React, { FC, ReactElement } from 'react';

// UI
import { DefaultGridContainer } from 'domains/ui/components/_styled/DefaultGridContainer';
import { Typography } from 'domains/ui/components/_styled/Text';

// Assets
import flag from 'theme/assets/svg/flag.svg';
import { GridItem } from 'domains/ui/components/_styled/GridItem';
import { useBreakpoints } from 'domains/ui/hooks';
import { TextButton } from 'domains/ui/components/TextButton';
import { palette } from 'domains/ui/components/_mixins/_palette';
import PlaceIcon from '@mui/icons-material/Place';

export const CharityAbout = (): ReactElement => {
    const { breakpoints } = useBreakpoints();

    const styleItem = breakpoints.lg
        ? { margin: '20px 0 50px' }
        : { margin: '15px 0 40px', fontSize: 16 };

    const styleItemTitle = breakpoints.lg ? {} : { fontSize: 18 };

    return (
        <DefaultGridContainer
            id='about-charity'
            style={{ paddingBottom: breakpoints.sm ? 150 : 120 }}
        >
            <Typography.H2
                gridColumn='1 / -1'
                placeSelf='start'
                style={{
                    paddingBottom: breakpoints.lg ? 50 : 0,
                }}
            >
                про аукціон
            </Typography.H2>

            <Typography.Paragraph
                style={
                    breakpoints.lg
                        ? {}
                        : breakpoints.md
                        ? { display: 'grid', gridTemplateColumns: '1fr 2fr', alignItems: 'center' }
                        : {}
                }
                gridColumn={{
                    xl: '6 / -1',
                    lg: '5 / -1',
                    xs: '1 / -1',
                }}
            >
                <img src={flag} alt='' style={breakpoints.lg ? {} : { gridColumn: 1 }} />
                <br />
                <br />
                Під час заходу кожний участник має змогу вийграти унікальний лот, конкуруючи з
                іншими участниками.
                <br />
                <br />
                Також на вас будуть чекати зеркові гості, яких ми змогли залучити для проведення
                цього над важливого заходу для нас.
                <br />
                <br />
                <TextButton
                    onClick={() => window.open('https://forms.gle/r9Dh4ZCva1Jk3VrN7', '_blank')}
                    text={`Взяти участь офлайн   ₴ ${300}`}
                    placeSelf={{
                        xl: 'start',
                        lg: 'start end',
                        sm: 'start',
                        xs: 'center',
                    }}
                    gridColumn={{
                        xl: '5 / 9',
                        lg: '4 / 6',
                        sm: '1 / 3',
                        xs: '1 / -1',
                    }}
                    style={{
                        whiteSpace: 'pre',
                        gridColumn: 2,
                        gap: '10px',
                        width: breakpoints.sm ? undefined : '100%',
                        fontSize: breakpoints.sm ? 20 : 16,
                        color: palette.MainYellow,
                    }}
                />
                <Typography.Paragraph
                    style={{ fontSize: '14px', marginTop: '20px', gridColumn: 2 }}
                >
                    Кількість вільних місць для відкритої реєстрації{' '}
                    <Typography.Span
                        style={{
                            fontSize: 'inherit',
                            color: palette.MainYellow,
                            display: 'initial',
                        }}
                    >
                        {50}
                    </Typography.Span>
                </Typography.Paragraph>
            </Typography.Paragraph>

            <GridItem
                gridColumn={breakpoints.lg ? '1 / span 4' : '1 / -1'}
                style={
                    breakpoints.lg
                        ? { fontSize: 24, gridRow: '2' }
                        : {
                              gridColumn: '1 / -1',
                              justifySelf: 'start',
                          }
                }
            >
                <Typography.H3 style={styleItemTitle}>1. Коли?</Typography.H3>
                <Typography.Caption style={styleItem}>30 Березня 2023, 18:00</Typography.Caption>

                <Typography.H3 style={styleItemTitle}>2. Де нас знайти</Typography.H3>
                <Typography.Caption style={styleItem}>
                    <PlaceIcon
                        style={{
                            transform: 'translate(-3px, 3px)',
                            color: palette.ProductCardInfoGrey,
                        }}
                    />
                    Львів, Центр Шептицького УКУ
                </Typography.Caption>

                <Typography.H3 style={styleItemTitle}>3. Формат заходу</Typography.H3>
                <Typography.Caption style={styleItem}>
                    Офлайн частина та Онлайн аукціон із прямою трансляцією
                </Typography.Caption>
            </GridItem>
        </DefaultGridContainer>
    );
};
