import { useCallback, useEffect, useState } from 'react';
import { logger } from 'utils/logger';
import { LotsApi } from '../api';
import { Lot, LotsRequest } from '../types/lots.types';

export const useArchiveLots = () => {
    const [archiveLots, setArchive] = useState<Lot[]>([]);
    const [isArchivePage, setIsArchivePage] = useState<boolean>(false);

    const [showMoreAvailable, setshowMoreAvailable] = useState<boolean>(false);
    const [isLoading, setisLoading] = useState<boolean>(false);
    const limit = 20;
    const scrollElementsAdd = 6;

    const [page, setPage] = useState<number>(0);

    const updPage = page + 1;
    const pageMore1 = updPage > 1;

    const fetchArchiveAsync = useCallback(
        async (lotsRequestData: LotsRequest) => {
            if (!isLoading) {
                try {
                    setisLoading(true);

                    const {
                        data: { lots, count },
                    } = await LotsApi.fetchLots({
                        ...lotsRequestData,
                        status: 'paid',
                        orderBy: 'creation-date',
                        order: 'desc',
                    });
                    setArchive([...archiveLots, ...lots]);
                    setshowMoreAvailable((page + 1) * limit < count);

                    setshowMoreAvailable(
                        (pageMore1 ? limit + (updPage - 1) * scrollElementsAdd : updPage * limit) <
                            count,
                    );
                } catch (error) {
                    logger.error('Помилка завантаження лотів', true, error);
                } finally {
                    setisLoading(false);
                }
            }
        },
        [page, archiveLots],
    );

    // const updateOrder = (newOrder: LotsOrderBy) => {
    //     setOrderBy(newOrder);
    // };

    useEffect(() => {
        if (isArchivePage) {
            if (page !== 0) {
                setPage(0);
            }
            setArchive([]);

            fetchArchiveAsync({ limit });
        }
    }, [isArchivePage]);

    useEffect(() => {
        if (isArchivePage) {
            console.log(updPage, 'updPage');
            if (pageMore1) {
                fetchArchiveAsync({
                    skip: limit + (updPage - 2) * scrollElementsAdd,
                    limit: scrollElementsAdd,
                });
            } else {
                fetchArchiveAsync({ skip: page * limit, limit });
            }
        }
    }, [page, isArchivePage]);

    const updatePage = () => {
        setPage(page + 1);
    };

    return {
        archiveLots,
        fetchArchive: fetchArchiveAsync,
        // updateOrder,
        updatePage,
        showMoreAvailable,
        setIsArchivePage,
    };
};
