import styled from 'styled-components';
import { GridPlacement, gridPlacementCss } from '../_mixins/GridPlacement';

export const AuctionGridContainer = styled.div<GridPlacement>`
    ${gridPlacementCss}

    display: flex;
    flex-wrap: wrap;
    justify-content: ${(p) => (p.theme.breakpoints.md ? 'space-between' : 'center')};
    row-gap: 30px;

    width: 100%;
    padding-bottom: 150px;
`;
