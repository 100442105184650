// Core
import { GridPlacement } from 'domains/ui/components/_mixins/GridPlacement';
import React, { FC, ReactElement } from 'react';

// Assets
import logo from '../../../assets/zgraya/zgraya-logo_hor.png';
import { BannerContainer } from './styles';

export const ZgrayaBanner: FC<GridPlacement> = (props): ReactElement => {
    return (
        <BannerContainer {...props}>
            <a href='https://zgraya-help.com/' target='_blank' rel='noopener noreferrer'>
                <img
                    src={logo}
                    alt=''
                    className='vendor-logo'
                    style={{
                        filter: 'invert(1)',
                    }}
                />
            </a>
        </BannerContainer>
    );
};
