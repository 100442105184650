// Core
import React, { FC, ReactElement, MouseEvent, useContext } from 'react';

// MUI
import { Backdrop, Dialog, DialogActions, DialogContent } from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';

// Context
import { UiContext } from 'domains/ui/context/ui.context';
import { Typography } from 'domains/ui/components/_styled/Text';
import { TextButton } from 'domains/ui/components/TextButton';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { useBreakpoints, useButtonActions } from 'domains/ui/hooks';
import { SocialNetworkIconWrapper } from 'domains/ui/components/_styled/SocialNetworkIconWrapper';

export const ConnectTelegamDialog: FC = (): ReactElement => {
    const { telegramDialog, setTelegramDialogState } = useContext(UiContext);
    const { breakpoints } = useBreakpoints();

    const { handleOpenTelegramBot } = useButtonActions();

    const toggleOpen = (e: MouseEvent) => {
        e.stopPropagation();
        setTelegramDialogState((prev) => !prev);
    };

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={telegramDialog}
        >
            <Dialog
                open={telegramDialog}
                onClose={toggleOpen}
                PaperProps={{
                    style: {
                        border: `${breakpoints.sm ? 35 : 10}px solid ${palette.MainYellow}`,
                        margin: breakpoints.sm ? undefined : 5,
                        background: palette.MainBlack,
                        maxWidth: 650,
                    },
                }}
            >
                <Typography.H3
                    style={{
                        padding: '40px 0 0 40px',
                        fontSize: 20,
                    }}
                >
                    Отримуйте сповіщення:
                </Typography.H3>

                <DialogContent
                    sx={{
                        px: '40px',

                        overflow: 'visible',
                    }}
                >
                    <Typography.Caption style={{ padding: '10px 0' }}>
                        <li>коли хтось перебив вашу ставку</li>
                    </Typography.Caption>
                    <Typography.Caption style={{ padding: '10px 0' }}>
                        <li>коли ви виграли лот</li>
                    </Typography.Caption>
                    <Typography.Caption style={{ padding: '10px 0' }}>
                        <li>коли ваш лот було опубліковано</li>
                    </Typography.Caption>
                    <Typography.Caption style={{ padding: '10px 0' }}>
                        <li>коли у вашого лота з'явився переможець</li>
                    </Typography.Caption>
                </DialogContent>

                <DialogActions
                    sx={{
                        px: '40px',
                        pb: 3,
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <TextButton
                        onClick={handleOpenTelegramBot}
                        text={
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '10px',
                                }}
                            >
                                <SocialNetworkIconWrapper height={35} onClick={() => null}>
                                    <TelegramIcon sx={{ color: palette.MainBlue, height: '75%' }} />
                                </SocialNetworkIconWrapper>
                                <span>Підписатися</span>
                            </div>
                        }
                    />
                </DialogActions>
            </Dialog>
        </Backdrop>
    );
};
