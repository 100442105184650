// Core
import React, { FC, ReactElement, useState } from 'react';

// Instruments
import { TextButton } from 'domains/ui/components/TextButton';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { GridItem } from 'domains/ui/components/_styled/GridItem';
import { Typography } from 'domains/ui/components/_styled/Text';
import { VlasviyaBanner } from '../VlasviyaBanner';

type AntytilaHeaderProps = {
    className?: string;
};

export const VlasviyaHeader: FC<AntytilaHeaderProps> = ({ className }): ReactElement => {
    const [detailsOpened, setDetailsOpenedState] = useState(false);

    return (
        <>
            <VlasviyaBanner gridColumn='1 / -1' />

            <Typography.H3
                gridColumn='1 / -1'
                placeSelf='center'
                style={{
                    color: palette.MainYellow,
                    fontSize: 15,
                    textAlign: 'center',
                }}
            >
                Миротворення, порятунок, захист і допомога
                <br />
                потребуючим унаслідок війни, надзвичайних ситуацій, суспільних криз
            </Typography.H3>

            <GridItem
                gridColumn='1  / -1'
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    gap: 20,
                    width: '100%',
                    marginTop: -40,
                    paddingBottom: 40,
                    borderBottom: `1px solid ${palette.MainYellow}`,
                }}
            >
                <TextButton
                    text='Про фонд '
                    onClick={() => window.open('https://m.facebook.com/vlasviya', '_blank')}
                    style={{
                        minWidth: 330,
                    }}
                />

                <TextButton
                    text='Про аукціон'
                    onClick={() => setDetailsOpenedState((prev) => !prev)}
                    style={{
                        minWidth: 330,
                    }}
                />

                <TextButton
                    text='Швидкий донат'
                    onClick={() => window.open('https://send.monobank.ua/jar/6UavGsKWCx', '_blank')}
                    style={{
                        minWidth: 330,
                    }}
                />
            </GridItem>

            <GridItem
                gridColumn='1  / -1'
                style={{
                    width: '100%',
                    borderBottom: `1px solid ${palette.MainYellow}`,
                    marginTop: -81,

                    overflow: 'hidden',

                    maxHeight: detailsOpened ? '100vh' : 0,

                    transition: 'all 0.5s ease-in-out',
                }}
            >
                <Typography.H3 style={{ fontSize: 20, marginTop: 40 }}>
                    ВлАсвія - благодійна організація/благодійний фонд
                </Typography.H3>
                <br />
                <Typography.Paragraph style={{ marginBottom: 40 }}>
                    ВСЕБІЧНА ДОПОМОГА ЗСУ, а саме збір коштів та матеріальне забезпечення для потреб
                    Бригад/батальйонів тощо, які звертаються до нас.
                    <Typography.Span style={{ color: palette.MainYellow, display: 'inline' }}>
                        Миротворення, порятунок, захист і допомога
                    </Typography.Span>{' '}
                    потребуючим унаслідок війни, надзвичайних ситуацій, суспільних криз
                </Typography.Paragraph>
            </GridItem>
        </>
    );
};
