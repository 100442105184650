import UAParser from 'ua-parser-js';

// MUI
import { useMediaQuery } from '@mui/material';
import { APP_UI_COL_GAP, APP_UI_COL_WIDTH } from '../constants';

// Breakpoints marks bottomline
const XL_BREAKPOINT = 1410;
const LG_BREAKPOINT = 1100;
const MD_BREAKPOINT = 900;
const SM_BREAKPOINT = 675;
const XS_BREAKPOINT = 380;

export const useBreakpoints = () => {
    const xl = useMediaQuery(`(min-width:${XL_BREAKPOINT}px)`);
    const lg = useMediaQuery(`(min-width:${LG_BREAKPOINT}px)`);
    const md = useMediaQuery(`(min-width:${MD_BREAKPOINT}px)`);
    const sm = useMediaQuery(`(min-width:${SM_BREAKPOINT}px)`);
    const xs = useMediaQuery(`(min-width:${XS_BREAKPOINT}px)`);


    const gridColumsCount = xl ? 12 : lg ? 8 : sm ? 6 : 4;
    const minPaddingX = xl ? 40 : lg ? 20 : 15;

    const maxAppContainerWidth = xl
        ? XL_BREAKPOINT
        : lg
        ? LG_BREAKPOINT
        : md
        ? MD_BREAKPOINT
        : sm
        ? SM_BREAKPOINT
        : 500;

    const variables = {
        maxAppContainerWidth,
        gridColumsCount,
        minPaddingX,
    };

    const parser = new UAParser();

    const isMobile = parser.getDevice().type === 'mobile';
    const isTablet = parser.getDevice().type === 'tablet';

    return {
        variables,
        isMobile,
        isTablet,

        breakpoints: {
            xl,
            lg,
            md,
            sm,
            xs,
        },

        constants: {
            APP_UI_COL_GAP,
            APP_UI_COL_WIDTH,
        },
    };
};
