import styled from 'styled-components';
import { GridPlacement, gridPlacementCss } from 'domains/ui/components/_mixins/GridPlacement';
import { palette } from 'domains/ui/components/_mixins/_palette';

export const ProfileDataContainer = styled.div<GridPlacement>`
    ${gridPlacementCss}

    display: grid;
    grid-template-columns: ${(p) =>
        p.theme.breakpoints.md ? '300px 1fr 1fr' : p.theme.breakpoints.sm ? '300px 1fr' : '1fr'};
    grid-template-rows: max-content 1fr;
    place-items: ${(p) => (p.theme.breakpoints.sm ? 'start' : 'center')};
    gap: 30px;

    width: 100%;
`;

export const PorfileLinks = styled.div<GridPlacement>`
    ${gridPlacementCss}

    display: grid;
    gap: 15px;
`;

export const ProfileLink = styled.a`
    cursor: pointer;

    display: grid;
    grid-template-columns: repeat(2, max-content);
    place-items: center start;
    gap: 20px;

    width: 100%;

    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.05em;
    font-style: normal;
    font-weight: 400;
    color: ${palette.MainWhite};
    white-space: nowrap;
    text-overflow: ellipsis;

    transition: all 300ms linear;

    overflow: hidden;

    &:hover {
        color: ${palette.MainYellow};
    }
`;
