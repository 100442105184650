// Core
import React, {
    FC,
    ReactElement,
    useState,
    MouseEvent,
    ChangeEvent,
    useContext,
    useEffect,
} from 'react';

// MUI
import { Backdrop, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';

// Context
import { AuthContext } from '../context/auth.context';
import { UiContext } from 'domains/ui/context/ui.context';
import { AuthLocalStorageKeys } from '../types/auth';
import { Typography } from 'domains/ui/components/_styled/Text';
import { TextButton } from 'domains/ui/components/TextButton';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { useBreakpoints, useCountdown } from 'domains/ui/hooks';

export const LoginDialog: FC = (): ReactElement => {
    const { loginDialog, setLoginDialogState } = useContext(UiContext);
    const {
        authenticationInProgress,
        loginAsync,
        otpSendingTimestamp,
        resendOtp,
        clearOtpTimestamp,
        UNSAFE_devOtp,
    } = useContext(AuthContext);
    const { breakpoints } = useBreakpoints();

    const [otpCode, setOtpCode] = useState('');

    const { seconds } = useCountdown(otpSendingTimestamp, clearOtpTimestamp);

    const toggleOpen = (e: MouseEvent) => {
        e.stopPropagation();
        setLoginDialogState((prev) => !prev);
    };

    const handleOtpInput = (e: ChangeEvent<HTMLInputElement>) => {
        setOtpCode(e.target.value);
    };

    const handleLogin = () => {
        const phoneNumber = localStorage.getItem(AuthLocalStorageKeys.PHONE_NUMBER);

        if (phoneNumber) {
            loginAsync({ phoneNumber, otpCode });
        }
    };

    useEffect(() => {
        if (!loginDialog) {
            setOtpCode('');
        }
    }, [loginDialog]);

    useEffect(() => {
        if (process.env.REACT_APP_IS_DEV) {
            setOtpCode(UNSAFE_devOtp);
        }
    }, [UNSAFE_devOtp]);

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loginDialog}
        >
            <Dialog
                open={loginDialog}
                onClose={toggleOpen}
                PaperProps={{
                    style: {
                        border: `${breakpoints.sm ? 35 : 10}px solid ${palette.MainBlue}`,
                        margin: breakpoints.sm ? undefined : 5,
                        background: palette.MainBlack,
                        maxWidth: 650,
                    },
                }}
            >
                <Typography.H3
                    style={{
                        padding: breakpoints.xs ? '40px 0 0 40px' : '20px 0 0 20px',
                        fontSize: 20,
                    }}
                >
                    КОД З СМС
                </Typography.H3>

                <DialogContent
                    sx={{
                        px: breakpoints.xs ? '40px' : '20px',

                        overflow: 'visible',
                    }}
                >
                    <Typography.Caption style={{ padding: '10px 0' }}>
                        Введіть, будь ласка, одноразовий пароль для входу
                    </Typography.Caption>

                    <TextField
                        autoFocus
                        margin='dense'
                        id='login-otp'
                        label='Код з СМС'
                        type='тел'
                        fullWidth
                        variant='standard'
                        inputProps={{
                            inputMode: 'numeric',
                            style: {
                                fontSize: '14px',
                            },
                        }}
                        helperText={
                            seconds > 0 ? (
                                `Повторне надсилання смс буде доступне за ${seconds} сек.`
                            ) : !otpSendingTimestamp ? (
                                <Typography.Span
                                    onClick={resendOtp}
                                    style={{
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                        color: palette.MainYellow,
                                        fontSize: 16,
                                    }}
                                >
                                    Надіслати повторно
                                </Typography.Span>
                            ) : (
                                ''
                            )
                        }
                        FormHelperTextProps={{
                            style: {
                                color: palette.MainYellow,
                            },
                        }}
                        value={otpCode}
                        onChange={handleOtpInput}
                    />
                </DialogContent>

                <DialogActions sx={{ px: breakpoints.xs ? '40px' : '20px' }}>
                    <TextButton
                        onClick={toggleOpen}
                        text='Закрити'
                        hideArrow
                        borderless
                        style={{ fontWeight: 'bold' }}
                    />
                    <TextButton
                        onClick={handleLogin}
                        disabled={!otpCode.length || authenticationInProgress}
                        overrideHoverColor={palette.MainYellow}
                        style={{
                            marginLeft: 20,
                            fontWeight: !(!otpCode.length || authenticationInProgress) ? 'bold' : '',
                        }}
                        text='Вхід'
                        hideArrow
                        borderless
                    />
                </DialogActions>
            </Dialog>
        </Backdrop>
    );
};
