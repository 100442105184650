// Core
import React, { FC, ReactElement, ReactNode, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

// MUI
import { AppBar, Avatar, Container, IconButton, Tooltip } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

// Assets
import flag from 'theme/assets/svg/flag.svg';
import { AuthDialog } from 'domains/auth/components/AuthDialog';
import { Footer } from '../Footer/Footer';

// Instruments
import { AuthContext } from 'domains/auth/context/auth.context';
import { LoginDialog } from 'domains/auth/components/LoginDialog';
import { SignupDialog } from 'domains/auth/components/SignupDialog';

import { Menu } from '../Menu';
import { HeaderNavigation } from '../HeaderNavigation';

import { useButtonActions, useBreakpoints } from 'domains/ui/hooks';
import { AppRoutes } from 'routes';
import { TextButton } from '../TextButton';
import { palette } from '../_mixins/_palette';

type LayoutProps = { children?: ReactNode | undefined };

export const Layout: FC<LayoutProps> = ({ children }): ReactElement => {
    const { logoutAsync, isAuthenticated } = useContext(AuthContext);

    const { openProfile } = useButtonActions();
    const location = useLocation();

    const {
        variables: { minPaddingX, maxAppContainerWidth },
        isMobile,
        breakpoints: { lg, sm },
    } = useBreakpoints();

    return (
        <>
            <AppBar
                position='fixed'
                sx={{
                    backgroundImage: 'none',
                    boxShadow: 'none',
                }}
            >
                <Container
                    sx={{
                        display: 'grid',
                        gridAutoFlow: 'column',
                        gridTemplateColumns: lg
                            ? 'max-content 1fr max-content max-content'
                            : '1fr max-content max-content',
                        gridAutoColumns: 'max-content',
                        placeItems: 'center start',
                        gap: 1,
                        margin: '0 auto',
                        maxWidth: `${maxAppContainerWidth}px`,
                    }}
                    style={{ padding: sm ? `10px ${minPaddingX}px` : undefined }}
                    maxWidth={false}
                >
                    <Link to={AppRoutes.LANDING}>
                        <Avatar alt='Ms.Bulochka' src={flag} style={{ width: 65, height: 65 }} />
                    </Link>

                    {lg && <HeaderNavigation />}
                    {isAuthenticated && lg ? (
                        <>
                            <TextButton
                                onClick={() =>
                                    location.pathname === AppRoutes.PROFILE
                                        ? logoutAsync()
                                        : openProfile()
                                }
                                text={location.pathname === AppRoutes.PROFILE ? 'Вихід' : 'Профіль'}
                                overrideHoverColor={palette.MainYellow}
                                style={{
                                    fontSize: 16,
                                }}
                                borderless
                                hideArrow
                            />
                        </>
                    ) : (
                        <>
                            <AuthDialog />
                        </>
                    )}
                    {!lg && <Menu />}
                </Container>
            </AppBar>
            <Container
                sx={{
                    minHeight: 1,
                    padding: {
                        xs: `8px ${minPaddingX}px`,
                        md: `30px ${minPaddingX}px`,
                    },
                    margin: '0 auto',
                    maxWidth: `${maxAppContainerWidth}px`,
                    overflowX: isMobile ? 'hidden' : undefined,
                }}
                maxWidth={false}
            >
                {children}
            </Container>

            <Footer />

            {/* Modals */}
            <LoginDialog />
            <SignupDialog />
        </>
    );
};
