// Core
import React, { FC, MouseEvent, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// MUI
import { Fade, IconButton } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';

// Instruments
import { Blurhash } from 'react-blurhash';
import { DateTime } from 'luxon';
import { Lot, LotStatus } from 'domains/main/types/lots.types';
import { GridPlacement } from 'domains/ui/components/_mixins/GridPlacement';
import { usePayment } from 'domains/main/hooks/usePayment';
import { AuctionCardContainer, AuctionCardData } from './styled';
import { Typography } from 'domains/ui/components/_styled/Text';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { TextButton } from 'domains/ui/components/TextButton';
import { ShareDialog } from 'domains/ui/components/ShareDialog';
import { useBreakpoints, useButtonActions } from 'domains/ui/hooks';
import { handleMobileShare } from 'utils/handleMobileShare';
import { UserContext } from 'domains/profile/context/user.context';
import { User } from 'domains/profile/types/profile.types';
import { AuctioCardLotWinner } from '../AuctioCardLotWinner';
import { AuctioCardLotData } from '../AuctioCardLotData';
import { PaymentInstructions } from '../PaymentInstructions';
import { UiContext } from 'domains/ui/context/ui.context';
import { InfoLocalStorageKeys } from 'domains/auth/types/auth';

type AuctioCardProps = {
    isDesktop: boolean;
    isOwner: boolean;
    background: 'yellow' | 'blue';
    isWinner?: boolean;
} & Lot &
    GridPlacement;

export const AuctioCard: FC<AuctioCardProps> = ({
    background,
    id,
    endDate,
    title,
    pictureUrl,
    images,
    isLastBidder,
    creator,
    price,
    isDesktop,
    isOwner,
    isWinner,
    status,
    extendedEndDate,
    lastBidAmount,
    merchant,
    additionalInfo,
    ...gridPlacement
}) => {
    const [shareOpen, setShareOpenState] = useState(false);
    const [alternativePaymentInstructions, setAlternativePaymentInstructionsState] =
        useState(false);

    const [lotWinner, setLotWinner] = useState<User>();
    const [loadedImage, setLoadedImage] = useState<string>();

    const navigate = useNavigate();
    const location = useLocation();

    const { isMobile, isTablet, breakpoints } = useBreakpoints();
    const { handlePayment, paymentInProgress } = usePayment();
    const { handleOpenLotFeedback } = useButtonActions();
    const { showTelegramDialog } = useContext(UiContext);

    const { getLotWinnerDataAsync } = useContext(UserContext);

    const openShareDialog = (e: MouseEvent) => {
        e.stopPropagation();
        setShareOpenState(true);
    };

    const closeShareDialog = (e: MouseEvent) => {
        e.stopPropagation();
        setShareOpenState(false);
    };

    const toEndDate = DateTime.fromJSDate(new Date(extendedEndDate || endDate))
        .diffNow(['days', 'hours', 'minutes'])
        .mapUnits((unit) => Math.round(unit))
        .toObject();

    const auctionFinished = (Object.keys(toEndDate) as (keyof typeof toEndDate)[]).every(
        (el) => Number(toEndDate[el]) <= 0,
    );

    const navigateToLotDetails = () =>
        !auctionFinished &&
        !shareOpen &&
        navigate(`?id=${id}`, {
            state: {
                from: `${location.pathname}${location.search}`,
            },
        });

    const handleGetLotWinner = async () => {
        const winner = await getLotWinnerDataAsync(id);

        setLotWinner(winner);
    };

    const hasAlternativePaymentLink = () => {
        return additionalInfo.alternativePaymentLink;
    };

    const handlePaymentButtonClick = () => {
        const merchantsWithoutFondy = [
            'b001a107-2ce9-4ab6-a224-0dac01abd057',
            '7294a64b-696f-4568-9680-460ad5c68023',
            'ef7e9751-fcd4-4d1c-bc2e-2462a46214bf',
            '3ec3dea2-c2ec-4143-a9d7-5af8b1eae8b7',
            '029e260f-8268-49c7-9d52-713ace2370c5',
            'f9cc73c8-ee33-4725-ab96-266a628c664f',
            'a7abad46-1671-4eca-bf03-3852f1b365be',
            '919c42d3-f542-43d3-80dd-810f125ef4e7',
            'e2009967-d81f-44c9-a80d-e4f36b3269a7',
            '5175f016-504c-4a07-a8f8-f4c73fbba55a',
        ];
        showTelegramDialog(InfoLocalStorageKeys.TELEGRAM_POPUP_PAY);

        if (merchantsWithoutFondy.includes(merchant.id) || hasAlternativePaymentLink())
            return setAlternativePaymentInstructionsState(true);

        // Default case
        //
        return handlePayment(id);
    };

    const getButtonData = () => {
        // Owner's button data
        if (isOwner && !auctionFinished)
            return {
                text: 'Редагувати',
                onClick: handleOpenLotFeedback,
            };

        if (status === LotStatus.PAID && isOwner)
            return {
                text: 'Контакти переможця',
                onClick: handleGetLotWinner,
            };

        if (status === LotStatus.FINISHED && !lastBidAmount && isOwner)
            return {
                text: 'Лот не продано',
                onClick: () => null,
            };

        // Client's button data
        if (status === LotStatus.PAID && !isOwner)
            return {
                text: 'Поскаржитися на лот',
                onClick: handleOpenLotFeedback,
            };

        if (!isOwner && isWinner)
            return {
                text: 'Оплатити',
                onClick: handlePaymentButtonClick,
            };

        if (!isOwner && isLastBidder)
            return {
                text: 'Ставку прийнято',
                onClick: () => null,
            };

        // Default
        return {
            text: 'Підняти ставку',
            onClick: () => null,
        };
    };

    const handleShareClick = (e: MouseEvent) => {
        if (isMobile || isTablet) {
            handleMobileShare(
                e,
                {
                    shareLink: `${window.location.href}`,
                    shareText: `Аукціон "Лоти проти Сволоти" - ${title} від ${creator.firstName} ${creator.lastName}\n`,
                },
                openShareDialog,
            );
        } else {
            openShareDialog(e);
        }
    };

    useEffect(() => {
        const img = new Image();

        img.onload = () => {
            setLoadedImage(img.src);
        };

        img.src = images?.[0]?.url || pictureUrl;
    }, [images, pictureUrl]);

    return (
        <Fade in>
            <AuctionCardContainer
                background={background}
                {...gridPlacement}
                onClick={navigateToLotDetails}
                style={status === LotStatus.FINISHED ? { cursor: 'auto' } : {}}
            >
                <div
                    style={{
                        display: 'grid',

                        padding: breakpoints.md ? 40 : 25,
                        maxWidth: '100%',
                        maxHeight: '100%',
                        minWidth: breakpoints.md ? 280 : 200,
                    }}
                >
                    <div
                        style={{
                            gridRow: 1,
                            gridColumn: 1,

                            backgroundImage: `url(${loadedImage})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            width: '100%',
                            height: '100%',

                            opacity: loadedImage || !images[0].blurhash ? 1 : 0,
                            transition: '.3s opacity linear',
                        }}
                    />

                    {images[0].blurhash && (
                        <Blurhash
                            hash={images[0].blurhash}
                            style={{
                                gridRow: 1,
                                gridColumn: 1,

                                width: '100%',
                                height: '100%',
                                opacity: loadedImage || !images[0].blurhash ? 0 : 1,
                                transition: '1s opacity linear',
                            }}
                        />
                    )}
                </div>

                <AuctionCardData>
                    <Typography.ProductCardName gridColumn='1 / -1'>
                        {title}
                    </Typography.ProductCardName>

                    {lotWinner ? (
                        <AuctioCardLotWinner {...lotWinner} />
                    ) : (
                        <AuctioCardLotData
                            id={id}
                            auctionFinished={auctionFinished}
                            extendedEndDate={extendedEndDate}
                            toEndDate={toEndDate}
                            price={price}
                            merchant={merchant}
                        />
                    )}

                    {!auctionFinished && (
                        <IconButton
                            aria-label='settings'
                            onClick={handleShareClick}
                            sx={{
                                placeSelf: 'end start',
                                gridColumn: 1,
                                gridRow: 5,
                                '&:hover': {
                                    background: '#f0f0f0',
                                },
                            }}
                        >
                            <ShareIcon htmlColor={palette.MainBlack} />
                        </IconButton>
                    )}

                    <TextButton
                        onClick={getButtonData().onClick}
                        text={getButtonData().text}
                        disabled={paymentInProgress}
                        placeSelf='end'
                        gridRow={5}
                        gridColumn='1 / -1'
                        hideArrow
                        dark
                        style={{
                            height: 40,
                            fontWeight: 'bold',
                            visibility:
                                !status || status === LotStatus.ACTIVE || isOwner || isWinner
                                    ? 'visible'
                                    : 'hidden',
                        }}
                    />
                </AuctionCardData>

                <ShareDialog
                    inProp={shareOpen}
                    shareLink={`${window.location.href}`}
                    shareText={`Аукціон "Лоти проти Сволоти" - ${title} від ${creator.firstName} ${creator.lastName}\n`}
                    closeDialog={closeShareDialog}
                />

                {alternativePaymentInstructions && (
                    <PaymentInstructions
                        merchant={merchant}
                        closePaymentInstructions={() =>
                            setAlternativePaymentInstructionsState(false)
                        }
                        lotName={title}
                        price={price}
                        alternativePaymentLink={additionalInfo.alternativePaymentLink}
                    />
                )}
            </AuctionCardContainer>
        </Fade>
    );
};
