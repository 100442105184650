// Core
import React, { FC, ReactElement, useState } from 'react';

// Instruments
import { TextButton } from 'domains/ui/components/TextButton';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { GridItem } from 'domains/ui/components/_styled/GridItem';
import { Typography } from 'domains/ui/components/_styled/Text';
import { ValtechBanner } from '../ValtechBanner';

type AntytilaHeaderProps = {
    className?: string;
};

export const ValtechHeader: FC<AntytilaHeaderProps> = ({ className }): ReactElement => {
    const [detailsOpened, setDetailsOpenedState] = useState(false);

    return (
        <>
            <ValtechBanner gridColumn='1 / -1' />

            <Typography.H3
                gridColumn='1 / -1'
                placeSelf='center'
                style={{
                    color: palette.MainYellow,
                    fontSize: 15,
                    textAlign: 'center',
                }}
            >
                Благодійний аукціон від Valtech Ukraine для центру протезування, реконструктивної
                хірургії та реабілітації Superhumans🦿🦾
                <br />
                Всі зібрані кошти будуть подвоєні фондом Valtech One Fund for Relief.
            </Typography.H3>

            <GridItem
                gridColumn='1  / -1'
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    gap: 20,
                    width: '100%',
                    marginTop: -40,
                    paddingBottom: 40,
                    borderBottom: `1px solid ${palette.MainYellow}`,
                }}
            >
                <TextButton
                    text='Про компанію'
                    onClick={() => window.open('https://valtech.com', '_blank')}
                    style={{
                        minWidth: 330,
                    }}
                />

                <TextButton
                    text='Про аукціон'
                    onClick={() => setDetailsOpenedState((prev) => !prev)}
                    style={{
                        minWidth: 330,
                    }}
                />

                <TextButton
                    text='Швидкий донат'
                    onClick={() => window.open('https://send.monobank.ua/jar/nxAPcVWR5', '_blank')}
                    style={{
                        minWidth: 330,
                    }}
                />
            </GridItem>

            <GridItem
                gridColumn='1  / -1'
                style={{
                    width: '100%',
                    borderBottom: `1px solid ${palette.MainYellow}`,
                    marginTop: -81,

                    overflow: 'hidden',

                    maxHeight: detailsOpened ? '100vh' : 0,

                    transition: 'all 0.5s ease-in-out',
                }}
            >
                <Typography.H3 style={{ fontSize: 20, marginTop: 40 }}>
                    Повертаємо українцям super можливості.
                </Typography.H3>
                <br />
                <Typography.Paragraph style={{ marginBottom: 40 }}>
                    Аукціон відкритий з 3 квітня, 10:00, а{' '}
                    <Typography.Span style={{ color: palette.MainYellow, display: 'inline' }}>
                        5 квітня о 18:00
                    </Typography.Span>{' '}
                    платформа більше не приймає ставки. Тож встигніть поборотися за бажаний лот!
                    <br />
                    <br />
                    За годину до закриття запрошуємо приєднатися до онлайн-трансляції (шукай
                    запрошення в своєму календарі). Ми дізнаємося нові цікаві факти про лоти,
                    поспілкуємося із суперами, яким допомагаємо, і найцікавіше — підрахуємо кошти і
                    оголосимо переможців!
                    <br />
                    <br />
                    На вкладці "Швидкий донат" можна додатково зробити внесок в банку і серед
                    донатів на суму від 500 грн ми розіграємо три призи від наших партнерів:
                    брендовану під аукціон пляшку від Uzspace, оздоблену рядками гімну хустку Mantra
                    від Dodo Socks, дизайнерську пляшку від Superhumans. Донат в 1000 грн подвоює
                    шанс на виграш, 1500 – потроює і т.д.
                    <br />
                    <br />
                    Разом повертаємо українцям супер можливості🦿🦾
                </Typography.Paragraph>
            </GridItem>
        </>
    );
};
