// Core
import React, { FC, ReactElement, CSSProperties, useContext } from 'react';

// UI
import { GridItem } from 'domains/ui/components/_styled/GridItem';
import { Typography } from 'domains/ui/components/_styled/Text';
import { TextButton } from 'domains/ui/components/TextButton';
import { useBreakpoints, useButtonActions } from 'domains/ui/hooks';
import { GridPlacement } from 'domains/ui/components/_mixins/GridPlacement';
import { LotsContext } from 'domains/main/context/lots.context';

export const AuctioDoBid: FC<GridPlacement & { style?: CSSProperties; lotsCount: number }> = ({
    style,
    lotsCount,
    ...props
}): ReactElement => {
    const { openAuction } = useButtonActions();
    const { lots } = useContext(LotsContext);
    const { breakpoints } = useBreakpoints();

    return (
        <GridItem {...props} style={{ ...style, maxWidth: '100vw' }}>
            <Typography.Paragraph style={breakpoints.md ? { fontSize: 24 } : { fontSize: 20 }}>
                На сайті доступно лотів: {Math.max(lots?.length || 0 - lotsCount, 0)},
                <br /> Кожна ставка <br /> допомагає нашій армії
            </Typography.Paragraph>
            <br />
            <br />
            <TextButton
                text='Зробити ставку'
                onClick={openAuction}
                style={breakpoints.sm ? {} : { width: '100%' }}
            />
        </GridItem>
    );
};
