// Core
import React, { FC, ReactElement, useState, MouseEvent, ChangeEvent, useContext } from 'react';

// MUI
import {
    Backdrop,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    FormGroup,
    TextField,
} from '@mui/material';

// Context
import { AuthContext } from '../context/auth.context';

// Instruments
import { phoneNumberTransform } from 'utils/phoneNumberTransform';
import { UiContext } from 'domains/ui/context/ui.context';
import { TextButton } from 'domains/ui/components/TextButton';
import { Typography } from 'domains/ui/components/_styled/Text';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { useBreakpoints } from 'domains/ui/hooks';

export const AuthDialog: FC = (): ReactElement => {
    const [checked, setCheckedState] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [inputError, setInputErrorState] = useState(false);

    const { breakpoints } = useBreakpoints();
    const { otpDialog, setOtpDialogState } = useContext(UiContext);
    const { authenticationInProgress, handleSendOtp } = useContext(AuthContext);

    const toggleCheck = (e: ChangeEvent) => {
        e.stopPropagation();
        setCheckedState((prev) => !prev);
    };

    const toggleOpen = (e: MouseEvent) => {
        e.stopPropagation();
        setOtpDialogState((prev) => {
            if (prev) {
                clearData();
            }

            return !prev;
        });
    };

    const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(e.target.value);
    };

    const clearData = () => {
        setCheckedState(false);
        setPhoneNumber('');
    };

    const handleOtpSend = async () => {
        const validPhoneNumber = phoneNumberTransform(phoneNumber);

        if (!validPhoneNumber) return setInputErrorState(true);

        await handleSendOtp(validPhoneNumber);
    };

    return (
        <div>
            {/* Button */}
            {breakpoints.lg && (
                <TextButton
                    hideArrow
                    borderless
                    onClick={toggleOpen}
                    text='Вхід / Реєстрація'
                    style={{ fontSize: '16px' }}
                />
            )}

            {/* Modal */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={otpDialog}
            >
                <Dialog
                    open={otpDialog}
                    onClose={toggleOpen}
                    PaperProps={{
                        style: {
                            border: `${breakpoints.sm ? 35 : 10}px solid ${palette.MainYellow}`,
                            background: palette.MainBlack,
                            maxWidth: 650,
                            margin: 0,
                        },
                    }}
                >
                    <Typography.H3
                        style={{
                            padding: breakpoints.xs ? '40px 0 0 40px' : '20px 0 0 20px',
                            fontSize: 20,
                            color: palette.MainWhite,
                        }}
                    >
                        АВТОРИЗАЦІЯ
                    </Typography.H3>

                    <DialogContent
                        sx={{ px: breakpoints.xs ? '40px' : '20px', overflow: 'visible' }}
                    >
                        <Typography.Caption style={{ padding: '10px 0' }}>
                            Введіть, будь ласка, свій номер телефону — на нього буде висланий
                            одноразовий пароль для входу
                        </Typography.Caption>

                        <TextField
                            autoFocus
                            margin='dense'
                            id='phone'
                            label='Номер телефону'
                            type='tel'
                            fullWidth
                            variant='standard'
                            inputProps={{
                                inputMode: 'numeric',
                                style: {
                                    fontSize: '14px',
                                },
                            }}
                            value={phoneNumber}
                            onChange={handlePhoneNumberChange}
                            error={inputError}
                        />

                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={checked} onChange={toggleCheck} />}
                                label={
                                    <Typography.Caption
                                        style={{
                                            fontSize: breakpoints.sm ? 18 : 15,
                                        }}
                                    >
                                        Я погоджуюся на отримання смс (обов’язково)
                                    </Typography.Caption>
                                }
                            />
                        </FormGroup>
                    </DialogContent>

                    <DialogActions sx={{ px: breakpoints.xs ? '40px' : '20px' }}>
                        <TextButton
                            onClick={toggleOpen}
                            text='Закрити'
                            hideArrow
                            borderless
                            style={{ fontWeight: 'bold' }}
                        />
                        <TextButton
                            onClick={handleOtpSend}
                            disabled={!checked || authenticationInProgress}
                            overrideHoverColor={palette.MainYellow}
                            style={{
                                marginLeft: 20,
                                fontWeight: !(!checked || authenticationInProgress) ? 'bold' : '',
                            }}
                            text='Вхід'
                            hideArrow
                            borderless
                        />
                    </DialogActions>
                </Dialog>
            </Backdrop>
        </div>
    );
};
