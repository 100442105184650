// Core
import React, {
    FC,
    ReactElement,
    useState,
    MouseEvent,
    useContext,
    useEffect,
    useCallback,
    useRef,
} from 'react';

// MUI
import { Backdrop, Dialog, DialogActions, DialogContent } from '@mui/material';

// Context
import { AuthContext } from '../context/auth.context';
import { UiContext } from 'domains/ui/context/ui.context';
import { AuthLocalStorageKeys, SignupDto } from '../types/auth';
import { Typography } from 'domains/ui/components/_styled/Text';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { useBreakpoints } from 'domains/ui/hooks';
import { PhoneNumberStep } from './signupSteps/PhoneNumber';
import { NameStep } from './signupSteps/Name';
import { SocialsStep } from './signupSteps/Socials';
import { OtpStep } from './signupSteps/OtpStep';
import { SignupActions } from './signupComponents/SignupActions';
import { TextButton } from 'domains/ui/components/TextButton';

const steps = ['Registration', OtpStep];

const initialSignupData: SignupDto = {
    phoneNumber: '',
    firstName: '',
    lastName: '',
    socialProfiles: [],
    avatar: '',
    otpCode: '',
};

type SignupDataKeys = keyof typeof initialSignupData;

export const SignupDialog: FC = (): ReactElement => {
    const containerRef = useRef<HTMLDivElement>();
    const { signupDialog, setSignupDialogState } = useContext(UiContext);
    const { UNSAFE_devOtp, signupAsync } = useContext(AuthContext);
    const { breakpoints } = useBreakpoints();

    const [currentStep, setCurrentStep] = useState(0);
    const [signupData, setSignupData] = useState(initialSignupData);

    const StepToRender = steps[currentStep];

    const handleSignupDataChange = useCallback(
        <K extends SignupDataKeys>(key: K, value: typeof initialSignupData[K]) => {
            setSignupData((prev) => ({
                ...prev,
                [key]: value,
            }));
        },
        [],
    );

    const toggleOpen = (e: MouseEvent) => {
        e.stopPropagation();
        setSignupDialogState((prev) => !prev);
    };

    const clearData = () => {
        setSignupData(initialSignupData);
    };

    const prevStep = () => {
        setCurrentStep((prev) => prev - 1);
    };

    const nextStep = () => {
        setCurrentStep((prev) => prev + 1);
    };
    const handleSignup = () => {
        signupAsync({
            ...signupData,
            avatar: 'https://cdn-icons-png.flaticon.com/512/149/149071.png',
        });
    };

    useEffect(() => {
        if (signupDialog) {
            handleSignupDataChange(
                'phoneNumber',
                localStorage.getItem(AuthLocalStorageKeys.PHONE_NUMBER) || '',
            );
        } else {
            localStorage.removeItem(AuthLocalStorageKeys.PHONE_NUMBER);
        }
    }, [signupDialog, handleSignupDataChange]);

    useEffect(() => {
        if (!signupDialog) {
            clearData();
        }
    }, [signupDialog]);

    useEffect(() => {
        if (process.env.REACT_APP_IS_DEV) {
            handleSignupDataChange('otpCode', UNSAFE_devOtp);
        }
    }, [UNSAFE_devOtp, handleSignupDataChange]);

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={signupDialog}
        >
            <Dialog
                open={signupDialog}
                onClose={toggleOpen}
                PaperProps={{
                    style: {
                        border: `${breakpoints.sm ? 35 : 10}px solid ${palette.MainYellow}`,
                        margin: breakpoints.sm ? undefined : 0,
                        background: palette.MainBlack,
                        maxWidth: 650,
                    },
                }}
                scroll={'paper'}
            >
                <Typography.H3
                    style={{
                        padding: breakpoints.sm ? '40px 0 0 40px' : '10px 0 0 20px',
                        fontSize: 20,
                    }}
                >
                    РЕЄСТРАЦІЯ
                </Typography.H3>

                <DialogContent
                    sx={{
                        mx: breakpoints.sm ? '40px' : '20px',
                        pl: 0,
                        pr: 1,
                        py: 0.5,

                        overflow: 'visible',

                        '&::-webkit-scrollbar': {
                            width: '3px',

                            background: palette.MainBlack,
                        },

                        '&::-webkit-scrollbar-thumb': {
                            background: palette.MainYellow,
                        },
                    }}
                    ref={containerRef}
                >
                    {/* <Typography.Caption style={{ padding: '10px 0', fontSize: 14 }}>
                        * Поля обов'язкові до заповнення
                    </Typography.Caption> */}
                    {typeof StepToRender === 'string' ? (
                        <>
                            <PhoneNumberStep
                                handleSignupDataChange={handleSignupDataChange}
                                {...signupData}
                            />
                            <NameStep
                                handleSignupDataChange={handleSignupDataChange}
                                {...signupData}
                            />
                            <SocialsStep
                                handleSignupDataChange={handleSignupDataChange}
                                {...signupData}
                            />
                        </>
                    ) : (
                        <StepToRender
                            handleSignupDataChange={handleSignupDataChange}
                            {...signupData}
                        />
                    )}
                </DialogContent>

                {typeof StepToRender === 'string' ? (
                    <SignupActions
                        signupData={signupData}
                        containerRef={containerRef}
                        prevStep={prevStep}
                        nextStep={nextStep}
                    />
                ) : (
                    <DialogActions
                        sx={{
                            px: breakpoints.xs ? '40px' : '20px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                        }}
                    >
                        <TextButton
                            onClick={prevStep}
                            text='Назад'
                            hideArrow
                            borderless
                            disabled={currentStep === 0}
                            style={{
                                visibility: currentStep > 0 ? 'visible' : 'hidden',
                            }}
                        />

                        <TextButton
                            onClick={handleSignup}
                            text='Зареєструватися'
                            hideArrow
                            borderless
                        />
                    </DialogActions>
                )}
            </Dialog>
        </Backdrop>
    );
};
