// Core
import React, { FC, ReactElement, CSSProperties } from 'react';

// UI
import { GridItem } from 'domains/ui/components/_styled/GridItem';
import { Typography } from 'domains/ui/components/_styled/Text';
import { TextButton } from 'domains/ui/components/TextButton';
import { GridPlacement } from 'domains/ui/components/_mixins/GridPlacement';
import { useBreakpoints, useButtonActions } from 'domains/ui/hooks';

export const AuctioCreateLot: FC<GridPlacement & { style?: CSSProperties }> = ({
    style,
    ...props
}): ReactElement => {
    const { handleCreateNewLot } = useButtonActions();
    const { breakpoints } = useBreakpoints();

    return (
        <GridItem {...props} style={{ ...style, maxWidth: '100vw' }}>
            <Typography.Paragraph style={breakpoints.md ? { fontSize: 24 } : { fontSize: 20 }}>
                Хочете створити більше лотів? <br />
                Не стримуйтесь
            </Typography.Paragraph>
            <br />
            <Typography.Paragraph>Разом до перемоги!</Typography.Paragraph>
            <br />
            <TextButton text='Розмістити лот' onClick={handleCreateNewLot} />
        </GridItem>
    );
};
