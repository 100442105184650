// Core
import React, { FC, ReactElement, CSSProperties } from 'react';

// UI
import { GridItem } from 'domains/ui/components/_styled/GridItem';
import { Typography } from 'domains/ui/components/_styled/Text';
import { TextButton } from 'domains/ui/components/TextButton';
import { useButtonActions } from 'domains/ui/hooks';
import { GridPlacement } from 'domains/ui/components/_mixins/GridPlacement';

export const AuctioShowMore: FC<GridPlacement & { style?: CSSProperties }> = ({
    style,
    ...props
}): ReactElement => {
    const { openAuction } = useButtonActions();

    return (
        <GridItem
            {...props}
            style={{ ...style, minWidth: Math.min(window.innerWidth - 10, 410), maxWidth: '100vw' }}
        >
            <Typography.Paragraph>
                Це ще не все! <br />
                Ми маємо більше лотів для вас :)
            </Typography.Paragraph>
            <br />
            <TextButton text='Всі лоти' onClick={openAuction} />
        </GridItem>
    );
};
