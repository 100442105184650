// Core
import React, { FC, ReactElement } from 'react';
import { TextButton } from '../TextButton';
import { Typography } from '../_styled/Text';
import { SocialsContainer } from './styled';
import flag from 'theme/assets/svg/flag.svg';
import { useButtonActions, useBreakpoints } from 'domains/ui/hooks';

type SocialsProps = {};

export const Socials: FC<SocialsProps> = (): ReactElement => {
    const { handleOpenTelegram, handleOpenTwitter } = useButtonActions();
    const { breakpoints } = useBreakpoints();

    return (
        <SocialsContainer>
            <TextButton
                text='Телеграм канал'
                onClick={handleOpenTelegram}
                gridColumn={{
                    xl: 1,
                    lg: 1,
                    md: 1,
                    sm: 1,
                    xs: 1,
                }}
                gridRow={{ lg: 1, md: 2, xs: 2 }}
                style={{
                    width: breakpoints.lg ? 295 : '100%',
                }}
            />
            <TextButton
                text='Наш твіттер'
                onClick={handleOpenTwitter}
                gridColumn={{
                    xl: 2,
                    lg: 2,
                    md: 2,
                    sm: 2,
                    xs: 1,
                }}
                gridRow={{
                    xl: 1,
                    lg: 1,
                    md: 2,
                    sm: 2,
                    xs: 3,
                }}
                style={{
                    width: breakpoints.lg ? 295 : '100%',
                }}
            />
            <Typography.Paragraph
                gridRow={{
                    lg: 2,
                    md: 3,
                    xs: 4,
                }}
                gridColumn='1 / 3'
                style={
                    breakpoints.lg
                        ? { paddingTop: 80, fontSize: 18, width: '75%' }
                        : { fontSize: 16 }
                }
            >
                *Підписуйтесь на наші соціальні мережі та діліться цікавими лотами з друзями. Війна
                триває і ми маємо докладати усіх зусиль, щоб наблизити перемогу. Давайте робити це
                ще й отримуючи приємні бонуси і насолоджуючись життям.
            </Typography.Paragraph>
            <Typography.Paragraph
                gridRow={{
                    xl: '1 / 3',
                    lg: '1 / 3',
                    md: '1',
                    xs: 3,
                }}
                gridColumn={{ lg: 'auto', xs: '1 / -1' }}
                style={
                    breakpoints.lg
                        ? { fontSize: 24 }
                        : breakpoints.md
                        ? {
                              gridColumn: '1 / -1',
                              display: 'grid',
                              gridAutoFlow: 'column',
                              gridTemplateColumns: '1fr 2fr',
                              fontSize: 18,
                              paddingBottom: 50,
                          }
                        : {
                              gridColumn: '1 / -1',
                              display: 'grid',
                              fontSize: 16,
                              paddingBottom: 40,
                              gap: '30px 0',
                              gridRow: 1,
                          }
                }
            >
                Щодня на нашій платформі з’являються цікаві лоти.
                <br />
                <br />
                Щоб отримувати інформацію про оновлення серед перших — підписуйтесь на оновлення в
                Telegram або Twitter.
                <br />
                <img
                    src={flag}
                    alt=''
                    style={breakpoints.lg ? { marginTop: 60 } : { gridColumn: 1 }}
                />
            </Typography.Paragraph>
        </SocialsContainer>
    );
};
