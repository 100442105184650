import { toast } from 'react-toastify';

export const notifications = {
    success: (msg: string) => {
        toast.success(msg, {
            position: toast.POSITION.TOP_CENTER,
            theme: localStorage.getItem('auctio-rp-dark-mode') === 'true' ? 'dark' : 'light',
        });
    },

    error: (msg: string) => {
        toast.error(msg, {
            position: toast.POSITION.TOP_CENTER,
            theme: localStorage.getItem('auctio-rp-dark-mode') === 'true' ? 'dark' : 'light',
        });
    },

    warning: (msg: string) => {
        toast.warning(msg, {
            position: toast.POSITION.TOP_CENTER,
            theme: localStorage.getItem('auctio-rp-dark-mode') === 'true' ? 'dark' : 'light',
        });
    },

    info: (msg: string) => {
        toast.info(msg, {
            position: toast.POSITION.TOP_CENTER,
            theme: localStorage.getItem('auctio-rp-dark-mode') === 'true' ? 'dark' : 'light',
        });
    },
};
