type ShareData = {
    media: 'facebook' | 'twitter' | 'telegram' | 'linkedin' | string;
    link: string;
    text: string;
    hashtag?: string;
    twitterHashtags?: string[];
};

export const shareLinkGenerator = ({ media, link, text, hashtag, twitterHashtags }: ShareData) => {
    switch (media) {
        case 'facebook':
            return `https://www.facebook.com/sharer/sharer.php?u=${link}&quote=${text}&hashtag=${hashtag}`;

        case 'twitter':
            // return `https://twitter.com/intent/tweet?url=${link}&text=${text}&via=auctio_rp&hashtags=${twitterHashtags?.join(
            //     '%2C%20',
            // )}`;
            return `https://twitter.com/intent/tweet?url=${link}&text=${text}&hashtags=${twitterHashtags?.join(
                '%2C%20',
            )}`;

        case 'telegram':
            return `https://telegram.me/share/url?url=${link}&text=${text}`;

        case 'linkedin':
            return `https://www.linkedin.com/sharing/share-offsite/?url=${link}`;

        default:
            return `https://twitter.com/intent/tweet?url=${link}&text=${text}&via=auctio_rp&hashtags=${twitterHashtags?.join(
                '%2C%20',
            )}`;
    }
};
