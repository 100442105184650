export enum LandingMarkupIds {
    RESULTS = 'results',
    WHAT_TO_DO = 'what-to-do',
    HOW_IT_WORKS = 'how-it-works',
    SOCIALS = 'socials',
}

export type DashboardStats = {
    paidLotsSum: number;
    paidLotsCount: number;
    registeredUsers: number;
};
