import { useState } from 'react';
import { logger } from 'utils/logger';
import { PaymentsApi } from '../api';

export const usePayment = () => {
    const [paymentInProgress, setPaymentProgressState] = useState(false);

    const handlePayment = async (id: string) => {
        setPaymentProgressState(true);

        try {
            const { data } = await PaymentsApi.paymentInit(id);

            window.open(data.checkoutUrl, '_blank');
        } catch (err) {
            logger.error('Помилка створення платежу', false, err);
        } finally {
            setPaymentProgressState(false);
        }
    };

    return {
        paymentInProgress,

        handlePayment,
    };
};
