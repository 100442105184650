import { AuthContext } from 'domains/auth/context/auth.context';
import { InfoLocalStorageKeys } from 'domains/auth/types/auth';
import { UserContext } from 'domains/profile/context/user.context';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'routes';
import { UiContext } from '../context/ui.context';

export const useButtonActions = () => {
    const navigate = useNavigate();
    const { isAuthenticated } = useContext(AuthContext);
    const { setOtpDialogState, showTelegramDialog } = useContext(UiContext);

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const handleCreateNewLot = () => {
        if (!isAuthenticated) return setOtpDialogState(true);

        window.open(process.env.REACT_APP_NEW_LOT_FORM_URL, '_blank');

        showTelegramDialog(InfoLocalStorageKeys.TELEGRAM_POPUP_PAY);
    };

    const handleOpenTelegramBot = () => {
        window.open('https://t.me/lotyproty_bot', '_blank');
    };

    const handleOpenTelegram = () => {
        window.open('https://t.me/LotyProty', '_blank');
    };

    const handleOpenFacebook = () => {
        window.open(
            'https://www.facebook.com/%D0%9B%D0%BE%D1%82%D0%B8-%D0%9F%D1%80%D0%BE%D1%82%D0%B8-103150999130327',
            '_blank',
        );
    };

    const handleOpenInstagram = () => {
        window.open('https://www.instagram.com/loty_proty/', '_blank');
    };

    const handleOpenTwitter = () => {
        window.open('https://twitter.com/LotyProty', '_blank');
    };

    const handleOpenFulcrum = () => {
        window.open('https://twitter.com/LotyProty', '_blank');
    };

    const handleOpenFeedback = () => {
        window.open(process.env.REACT_APP_FEEDBACK_FORM_URL, '_blank');
    };

    const handleOpenLotUpdate = () => {
        window.open('https://forms.gle/CbedYzsGUiyQefkb7', '_blank');
    };

    const handleOpenLotFeedback = () => {
        window.open('https://forms.gle/XNSdNhPNufDaxpMw9', '_blank');
    };

    const openAuction = () => {
        navigate(AppRoutes.APP);
        scrollToTop();
    };

    const openArchive = () => {
        navigate(AppRoutes.ARCHIVE);
        scrollToTop();
    };

    const openProfile = (tab?: number) => {
        scrollToTop();
        navigate(AppRoutes.PROFILE, {
            state: tab ? { tab } : undefined,
        });
    };

    const openPolicy = () => {
        scrollToTop();
        navigate(AppRoutes.PRIVACY_POLICY);
    };

    const openRules = () => {
        scrollToTop();
        navigate(AppRoutes.TERMS_OF_USE);
    };

    return {
        handleCreateNewLot,
        openAuction,
        openArchive,
        openProfile,
        handleOpenTelegram,
        handleOpenTwitter,
        handleOpenFeedback,
        handleOpenLotUpdate,
        handleOpenLotFeedback,
        handleOpenFacebook,
        handleOpenInstagram,
        handleOpenTelegramBot,
        handleOpenFulcrum,
        openRules,
        openPolicy,
    };
};
