// Core
import React, { FC, ReactElement, useState } from 'react';

// Instruments
import { TextButton } from 'domains/ui/components/TextButton';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { GridItem } from 'domains/ui/components/_styled/GridItem';
import { Typography } from 'domains/ui/components/_styled/Text';
import { RiyBanner } from '../RiyBanner';

type AntytilaHeaderProps = {
    className?: string;
};

export const RiyHeader: FC<AntytilaHeaderProps> = ({ className }): ReactElement => {
    const [detailsOpened, setDetailsOpenedState] = useState(false);

    return (
        <>
            <RiyBanner gridColumn='1 / -1' />

            <Typography.H3
                gridColumn='1 / -1'
                placeSelf='center'
                style={{
                    color: palette.MainYellow,
                    fontSize: 15,
                    textAlign: 'center',
                }}
            >
                Дбаємо про боєздатність війська
            </Typography.H3>

            <GridItem
                gridColumn='1  / -1'
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    gap: 20,
                    width: '100%',
                    marginTop: -40,
                    paddingBottom: 40,
                    borderBottom: `1px solid ${palette.MainYellow}`,
                }}
            >
                <TextButton
                    text='Про фонд '
                    onClick={() => window.open('https://www.riyukraine.com', '_blank')}
                    style={{
                        minWidth: 330,
                    }}
                />

                <TextButton
                    text='Про аукціон'
                    onClick={() => setDetailsOpenedState((prev) => !prev)}
                    style={{
                        minWidth: 330,
                    }}
                />

                <TextButton
                    text='Швидкий донат'
                    onClick={() => window.open('https://send.monobank.ua/jar/5uE8svR4Ym', '_blank')}
                    style={{
                        minWidth: 330,
                    }}
                />
            </GridItem>

            <GridItem
                gridColumn='1  / -1'
                style={{
                    width: '100%',
                    borderBottom: `1px solid ${palette.MainYellow}`,
                    marginTop: -81,

                    overflow: 'hidden',

                    maxHeight: detailsOpened ? '100vh' : 0,

                    transition: 'all 0.5s ease-in-out',
                }}
            >
                <Typography.H3 style={{ fontSize: 20, marginTop: 40 }}>
                    Зараз у нас відкрито 3 збори:
                </Typography.H3>
                <br />
                <Typography.Paragraph>
                    1. На 2 мобільних лазне-пральних комплекси (10 та 11) збираємо ще 300 000 грн,
                    щоб вчасно завершити комплектування і передати 22 та 66 окремим механізованим
                    бригадам!
                </Typography.Paragraph>
                <br />
                <Typography.Paragraph>
                    2. На маскувальне спорядження для снайперів та розвідки потрібно ще 1 400 000
                    грн, щоб забезпечити 600 маскувальних курток і 1800 вуалей для снайперів та
                    розвідки{' '}
                </Typography.Paragraph>
                <br />
                <Typography.Paragraph>
                    3. Збір 600 000 грн на спальні системи RiYMSS-2 для військових, які сплять не в
                    приміщеннях.
                </Typography.Paragraph>
                <br />
            </GridItem>
        </>
    );
};
