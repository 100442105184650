import styled from 'styled-components';
import { palette } from '../_mixins/_palette';

export const SocialNetworkIconWrapper = styled.div<{ dark?: boolean; height?: number }>`
    cursor: pointer;

    display: grid;
    place-items: center;

    width: ${(p) => `${p.height || 40}px`};
    height: ${(p) => `${p.height || 40}px`};

    border-radius: 100%;

    background: ${(p) => (p.dark ? palette.MainBlack : palette.MainWhite)};

    transition: all 300ms linear;

    &:hover {
        background: ${(p) => (p.dark ? palette.MainBlue : palette.MainYellow)};
    }
`;
