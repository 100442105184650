import { useCallback, useEffect, useRef, useState } from 'react';
import { logger } from 'utils/logger';
import { LotsApi } from '../api';
import {
    Lot,
    LotsRequest,
    LotStatus,
    LotsOrderBy,
    LotsOrder,
    LotsOrderByFE,
} from '../types/lots.types';

export const useLotsContext = () => {
    const [activelots, setLots] = useState<Lot[]>([]);

    const [showMoreAvailable, setshowMoreAvailable] = useState<boolean>(false);
    const firstUpdate = useRef(true);

    const limit = 20;
    const scrollElementsAdd = 20;

    const [page, setPage] = useState<number>(0);
    const updPage = page + 1;
    const pageMore1 = updPage > 1;

    const [order, setOrder] = useState<LotsOrder>('asc');
    const [orderBy, setOrderBy] = useState<LotsOrderBy>('end-date');

    const updateLotPriceAfterBid = (id: string, newPrice: number) => {
        setLots(
            (prev) =>
                prev?.map((el) =>
                    el.id === id ? { ...el, price: newPrice, isLastBidder: true } : el,
                ) || null,
        );
    };

    const updateLotStatusLocally = useCallback((id: string, status: LotStatus) => {
        setLots((prev) => prev?.map((el) => (el.id === id ? { ...el, status } : el)) || null);
    }, []);

    const updateLots = (data: Lot[]) => {
        setLots(data);
    };

    const clearLots = () => {
        setLots([]);
    };

    const fetchLotsAsync = useCallback(
        async (lotsRequestData: Partial<LotsRequest>) => {
            try {
                const {
                    data: { lots, count },
                } = await LotsApi.fetchLots({
                    ...lotsRequestData,
                });

                updateLots([...activelots, ...lots]);

                setshowMoreAvailable(
                    (pageMore1 ? limit + (updPage - 1) * scrollElementsAdd : updPage * limit) <
                        count,
                );
            } catch (error) {
                logger.error('Помилка завантаження лотів', true, error);
            }
        },
        [page, activelots],
    );

    const updateOrder = (newOrder: LotsOrderByFE) => {
        setPage(0);
        setLots([]);
        firstUpdate.current = true;

        switch (newOrder) {
            case 'end-date': {
                setOrderBy(newOrder);
                return setOrder('asc');
            }
            case 'creation-date': {
                setOrderBy(newOrder);
                return setOrder('desc');
            }
            case 'low-bid': {
                setOrderBy('bid-amount');
                return setOrder('asc');
            }
            default: {
                setOrderBy('bid-amount');
                setOrder('desc');
            }
        }
    };

    useEffect(() => {
        if (page !== 0) {
            setPage(0);
        }
        setLots([]);
        fetchLotsAsync({ limit, order, orderBy });
        firstUpdate.current = false;
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (firstUpdate.current) {
            fetchLotsAsync({ limit, order, orderBy });
        }
        // eslint-disable-next-line
    }, [order, orderBy]);

    useEffect(() => {
        if (page > 0) {
            console.log(updPage, 'updPage');
            if (pageMore1) {
                fetchLotsAsync({
                    skip: limit + (updPage - 2) * scrollElementsAdd,
                    limit: scrollElementsAdd,
                    order,
                    orderBy: orderBy,
                });
            } else {
                fetchLotsAsync({
                    skip: page * limit,
                    limit,
                    order,
                    orderBy: orderBy,
                });
            }
        }
        // eslint-disable-next-line
    }, [page, order, orderBy]);

    const updatePage = () => {
        setPage(page + 1);
    };

    return {
        lots: activelots,
        updateLots,
        updateLotPriceAfterBid,
        updateLotStatusLocally,
        clearLots,
        updateOrder,
        updatePage,
        showMoreAvailable,
    };
};
