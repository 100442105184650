// Core
import React, { FC, ReactElement } from 'react';

// Instruments
import { TextField } from '@mui/material';
import { SignupDto } from 'domains/auth/types/auth';
import { palette } from 'domains/ui/components/_mixins/_palette';

const fields = ['firstName', 'lastName'] as (keyof SignupDto)[];
const labels = ["Ім'я", 'Прізвище'];

type NameStepProps = {
    firstName: string;
    lastName: string;
    handleSignupDataChange: <K extends keyof SignupDto>(key: K, value: SignupDto[K]) => void;
};

export const NameStep: FC<NameStepProps> = ({
    handleSignupDataChange,
    ...restProps
}): ReactElement => {
    return (
        <>
            {fields.map((el, index) => (
                <TextField
                    margin='dense'
                    label={`${labels[index]} *`}
                    FormHelperTextProps={{
                        style: {
                            color: palette.MainBlue,
                            fontSize: 16,
                        },
                    }}
                    type={'text'}
                    fullWidth
                    variant='standard'
                    inputProps={{
                        inputMode: 'text',

                        style: {
                            fontFamily: 'Montserrat',
                        },
                    }}
                    style={{ marginTop: 0 }}
                    // @ts-ignore
                    value={restProps[fields[index]]}
                    onChange={(e) => handleSignupDataChange(el, e.target.value)}
                    key={index}
                />
            ))}
        </>
    );
};
