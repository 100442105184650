import { LocationWithBackRoute } from 'types/routing.types';

export const isLocationWithBackRoute = (value: any): value is LocationWithBackRoute => {
    return (
        typeof value.search === 'string' &&
        //
        typeof value.pathname === 'string' &&
        value.pathname.length > 0 &&
        //
        value.state &&
        typeof value.state.from === 'string' &&
        value.state.from.length > 0
    );
};
