// Core
import React, { CSSProperties, FC, MouseEvent, ReactElement, ReactNode, useState } from 'react';

// Instruments
import { Arrow } from '../Arrow';
import { GridPlacement } from '../_mixins/GridPlacement';
import { palette } from '../_mixins/_palette';
import { TextButtonContainer } from './styled';

type TextButtonProps = {
    onClick: (e: MouseEvent) => void;
    disabled?: boolean;
    style?: CSSProperties;
    borderless?: boolean;
    hideArrow?: boolean;
    dark?: boolean;
    overrideHoverColor?: string;
    text: string | ReactNode;
} & GridPlacement;

export const TextButton: FC<TextButtonProps> = (props): ReactElement => {
    const { text, hideArrow, disabled, overrideHoverColor } = props;

    const [hovered, setHoveredState] = useState(false);

    const handleMouseEnter = () => {
        setHoveredState(true);
    };

    const handleMouseLeave = () => {
        setHoveredState(false);
    };

    return (
        <TextButtonContainer
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            {...props}
        >
            {text}
            {!hideArrow && (
                <Arrow
                    color={
                        disabled
                            ? palette.DisabledGrey
                            : hovered
                            ? overrideHoverColor || palette.MainBlue
                            : palette.MainWhite
                    }
                    style={{
                        position: 'relative',
                        left: hovered ? 5 : 0,
                    }}
                />
            )}
        </TextButtonContainer>
    );
};
