export const getEndingLotWord = (count?: number) => {
    if (!count) return '';

    const string = count.toString();

    if (string.endsWith('1')) return '';

    if (string.endsWith('2')) return 'и';
    if (string.endsWith('3')) return 'и';
    if (string.endsWith('4')) return 'и';

    return 'ів';
};
