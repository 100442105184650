// Core
import React, { FC, ReactElement, useContext } from 'react';

// MUI
import VerifiedIcon from '@mui/icons-material/Verified';
import PhoneIcon from '@mui/icons-material/Phone';
import TelegramIcon from '@mui/icons-material/Telegram';

// UI
import { PorfileLinks, ProfileDataContainer, ProfileLink } from './styled';

// Instruments
import { socialNetworkLinkParse } from 'utils/socialNetworkLinkParse';
import { UserContext } from 'domains/profile/context/user.context';
import { GridPlacement } from 'domains/ui/components/_mixins/GridPlacement';
import { ProfilePhoto } from '../ProfilePhoto';
import { Typography } from 'domains/ui/components/_styled/Text';
import { Tooltip } from '@mui/material';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { SocialNetworkIconWrapper } from 'domains/ui/components/_styled/SocialNetworkIconWrapper';
import { useButtonActions } from 'domains/ui/hooks';

export const ProfileData: FC<GridPlacement> = (props): ReactElement => {
    const { userData } = useContext(UserContext);
    const { handleOpenTelegramBot } = useButtonActions();

    return (
        <ProfileDataContainer {...props}>
            <ProfilePhoto src={userData?.avatar} gridRow='1 / -1' />

            <Typography.H3>
                {userData?.firstName} {userData?.lastName}
                {userData?.isVerified && (
                    <>
                        <Tooltip
                            title={<Typography.Tooltip>Підтверджений профіль</Typography.Tooltip>}
                        >
                            <VerifiedIcon sx={{ color: palette.MainYellow }} />
                        </Tooltip>
                    </>
                )}
            </Typography.H3>

            <PorfileLinks
                gridColumn={{
                    xl: 2,
                    lg: 2,
                    md: 2,
                    sm: 2,
                    xs: 1,
                }}
                style={{ alignSelf: 'self-end' }}
            >
                {!userData?.telegramLinked && (
                    <ProfileLink onClick={handleOpenTelegramBot}>
                        <SocialNetworkIconWrapper>
                            <TelegramIcon sx={{ color: palette.MainBlue }} />
                        </SocialNetworkIconWrapper>
                        Підключити телеграм-бота
                    </ProfileLink>
                )}
                <ProfileLink>
                    <SocialNetworkIconWrapper>
                        <PhoneIcon sx={{ color: palette.MainBlue }} />
                    </SocialNetworkIconWrapper>
                    {userData?.phoneNumber}
                </ProfileLink>

                {userData?.socialProfiles.map((el, index) => {
                    const { fullLink, shortLink, SocialNetworkIcon } = socialNetworkLinkParse(el);

                    return (
                        <ProfileLink href={fullLink} target='_blank' key={index}>
                            <SocialNetworkIconWrapper>
                                <SocialNetworkIcon sx={{ color: palette.MainBlue }} />
                            </SocialNetworkIconWrapper>
                            {shortLink}
                        </ProfileLink>
                    );
                })}
            </PorfileLinks>
        </ProfileDataContainer>
    );
};
