// Core
import React, { FC, ReactElement, useContext } from 'react';

// UI
import { DefaultGridContainer } from 'domains/ui/components/_styled/DefaultGridContainer';
import { Typography } from 'domains/ui/components/_styled/Text';
import { TextButton } from 'domains/ui/components/TextButton';
import { useButtonActions, useBreakpoints } from 'domains/ui/hooks';
import { LotsContext } from 'domains/main/context/lots.context';
import { AuctionLotsCarousel } from 'domains/ui/components/AuctionLotsCarousel';

type ShowcaseProps = {
    type: 'current' | 'archive';
};

export const Showcase: FC<ShowcaseProps> = ({ type }): ReactElement => {
    const { lots } = useContext(LotsContext);
    const { breakpoints } = useBreakpoints();
    const { openAuction } = useButtonActions();

    return (
        <DefaultGridContainer
            rowGap={breakpoints.xl ? 80 : 50}
            style={{ paddingTop: breakpoints.lg ? '150px' : '120px' }}
        >
            <Typography.H2 gridColumn='1 / -1' gridRow={1} placeSelf='end start'>
                {type === 'archive' ? 'Архів аукції' : 'Актуальні лоти'}
            </Typography.H2>

            <TextButton
                onClick={openAuction}
                text={type === 'archive' ? 'Переглянути поточні лоти' : 'Переглянути всі'}
                gridColumn='1 / -1'
                placeSelf={{
                    md: 'end',
                    xs: 'start center',
                }}
                gridRow={{
                    md: 1,
                    xs: 3,
                }}
                style={{ width: breakpoints.md ? 'auto' : '100%' }}
            />

            <AuctionLotsCarousel data={lots} maxLength={6} gridColumn='1 / -1' />
        </DefaultGridContainer>
    );
};
