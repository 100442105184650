// Core
import { TextButton } from 'domains/ui/components/TextButton';
import { DefaultGridContainer } from 'domains/ui/components/_styled/DefaultGridContainer';
import { GridItem } from 'domains/ui/components/_styled/GridItem';
import { Typography } from 'domains/ui/components/_styled/Text';
import { useBreakpoints } from 'domains/ui/hooks';
import React, { FC, ReactElement, useState } from 'react';
import { Accordion } from './accordion';

type CharityInfoProps = {
    className?: string;
};

export const CharityInfo: FC<CharityInfoProps> = ({ className }): ReactElement => {
    const { breakpoints } = useBreakpoints();
    return (
        <DefaultGridContainer
            rowGap={breakpoints.lg ? 80 : 50}
            style={{ paddingTop: breakpoints.lg ? '150px' : breakpoints.md ? '120px' : '0' }}
        >
            <GridItem
                gridColumn={{
                    xs: '1 / -1',
                }}
                gridRow={{
                    lg: 2,
                    xs: 3,
                }}
                style={{ width: '100%', height: '100%' }}
            >
                <Accordion
                    data={[
                        {
                            time: '18:00-18:30',
                            title: 'Відкриття аукціону',
                            content: (
                                <>
                                    <Typography.Caption></Typography.Caption>

                                    <TextButton
                                        onClick={() => console.log('')}
                                        text='розмістити лот'
                                        borderless
                                    />
                                </>
                            ),
                        },
                        {
                            time: '18:30-20:00',
                            title: 'Аукціон',
                            content: (
                                <>
                                    <Typography.Caption></Typography.Caption>

                                    <TextButton
                                        onClick={() => console.log('')}
                                        borderless
                                        text='Переглянути лоти'
                                    />
                                </>
                            ),
                        },
                        {
                            time: '20:00-20:30',
                            title: 'Coffee-break',
                            content: (
                                <>
                                    <Typography.Caption></Typography.Caption>
                                </>
                            ),
                        },
                        {
                            time: '20:30-20:45',
                            title: 'Оголошення переможців',
                            content: (
                                <>
                                    <Typography.Caption></Typography.Caption>

                                    <TextButton
                                        onClick={() => console.log('')}
                                        text='Служба підтримки'
                                        borderless
                                    />
                                </>
                            ),
                        },
                        {
                            time: '20:45-21:00',
                            title: 'Підбиття результатів ',
                            content: (
                                <>
                                    <Typography.Caption></Typography.Caption>
                                </>
                            ),
                        },
                    ]}
                />
            </GridItem>
        </DefaultGridContainer>
    );
};
