import { css } from 'styled-components';
import { palette } from './_palette';

export const MainText = css`
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    line-height: 140%;
    font-style: normal;
    font-weight: 400;

    color: ${palette.MainWhite};
`;

export const ButtonText = css`
    font-family: 'Montserrat', sans-serif;

    color: ${palette.MainWhite};
    font-size: 20px;
    line-height: 120%;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    white-space: nowrap;

    &:hover {
        color: ${palette.MainBlue};
    }

    &:disabled {
        color: ${palette.DisabledGrey};
    }
`;

export const DarkButtonText = css`
    font-family: 'Montserrat', sans-serif;

    color: ${palette.MainBlack};
    font-size: 20px;
    line-height: 120%;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    white-space: nowrap;

    &:hover {
        color: ${palette.MainBlack};
    }

    &:disabled {
        color: ${palette.DisabledGrey};
    }
`;
