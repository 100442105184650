// Core
import React, { FC, useState, ReactElement, useEffect, useContext } from 'react';

// MUI / Styles
import { palette } from 'domains/ui/components/_mixins/_palette';
import { Tab, FilterBox } from './styled';

// Instruments
import { useBreakpoints } from 'domains/ui/hooks';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { LotsContext } from 'domains/main/context/lots.context';
import { LotsOrderByFE } from 'domains/main/types/lots.types';

type FiltersProps = {};

export const Filters: FC<FiltersProps> = ({}): ReactElement => {
    const { breakpoints } = useBreakpoints();
    const [expand, setExpand] = useState(false);
    const [currentFilter, setcurrentFilter] = useState('end-date');
    const { updateOrder } = useContext(LotsContext);

    const filterValues: Record<LotsOrderByFE, string> = {
        'end-date': 'За датою завершення',
        'creation-date': 'Спочатку найновіші',
        'low-bid': 'Від найнижчої ставки',
        'high-bid': 'Від найвищої ставки',
    };

    return (
        <FilterBox
            gridColumn='1 / -1'
            gridRow={1}
            style={
                breakpoints.md
                    ? {
                          height: expand ? 55 * 4 : 55,
                          right: 0,
                      }
                    : {
                          height: expand ? 55 * 4 : 55,
                          left: 0,
                      }
            }
            onClick={() => setExpand(!expand)}
        >
            {Object.entries(filterValues)
                .filter(([key, _]) => key === currentFilter)
                .map(([key, value]) => (
                    <Tab
                        key={key}
                        active={currentFilter === key}
                        onClick={() => {
                            setcurrentFilter(key);
                            setExpand(false);
                        }}
                        text={value}
                        hideArrow
                        borderless
                        style={{ background: !expand ? palette.Bg : palette.MainBlue }}
                    />
                ))}
            {Object.entries(filterValues)
                .filter(([key, _]) => key !== currentFilter)
                .map(([key, value]) => (
                    <Tab
                        key={key}
                        onClick={() => {
                            setcurrentFilter(key);
                            updateOrder(key as LotsOrderByFE);
                            setExpand(false);
                        }}
                        text={value}
                        hideArrow
                        borderless
                    />
                ))}

            <ArrowBackIosNewIcon
                fontSize='large'
                style={{
                    ...{
                        position: 'absolute',
                        right: 10,
                        top: 10,
                    },
                    ...{ transform: expand ? 'rotate(90deg)' : 'rotate(270deg)' },
                }}
            />
        </FilterBox>
    );
};
