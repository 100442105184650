import { api } from 'api/api';
import { AxiosResponse } from 'axios';
import { AccessTokenResponse, OtpRequestDto, SigninDto, SignupDto } from '../types/auth';

export const AuthApi = {
    // GETs

    // POSTs
    signup: async (data: SignupDto): Promise<AccessTokenResponse> => api.post('/auth/signup', data),

    signin: async (data: SigninDto): Promise<AccessTokenResponse> => api.post('/auth/login', data),

    logout: async () => api.post('/auth/logout'),

    sendOtp: async (data: OtpRequestDto) => api.post('/otp/send', data),

    refreshTokens: async (): Promise<AccessTokenResponse> => api.post('/auth/refresh-tokens'),

    // Misc
    checkUserExists: async (phoneNumber: string): Promise<AxiosResponse<{ exists: boolean }>> =>
        api.get(`/users/exists?phoneNumber=${phoneNumber.replace('+', '%2B')}`),
};
