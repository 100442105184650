// Core
import React, { FC, ReactNode, SyntheticEvent, useState } from 'react';

// MUI
import { Accordion as MUIAccordion, AccordionDetails, AccordionSummary } from '@mui/material';
import styled from '@emotion/styled';
import { GridItem } from 'domains/ui/components/_styled/GridItem';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { RoundArrowButton } from 'domains/ui/components/RoundArrowButton';
import { Typography } from 'domains/ui/components/_styled/Text';
import { GridPlacement } from 'domains/ui/components/_mixins/GridPlacement';
import { useBreakpoints } from 'domains/ui/hooks';

type AccordionProps = {
    data: {
        title: ReactNode;
        time: string;
        content: ReactNode;
    }[];
} & GridPlacement;

const CustomizedAccordion = styled(MUIAccordion)(() => ({
    '& .MuiPaper-root': {
        borderRadius: 0,
        background: 'transparent',
    },
    '& .MuiAccordionSummary-content': {
        margin: 0,
        display: 'grid',
        gridTemplateColumns: '2fr 1fr',
        alignItems: 'center',
    },
    '& .Mui-expanded': { transform: 'none !important' },
}));

export const Accordion: FC<AccordionProps> = ({ data, ...gridPlacement }) => {
    const [expanded, setExpanded] = useState<number | false>(false);
    const { breakpoints } = useBreakpoints();

    const handleChange = (key: number) => (event: SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? key : false);
    };

    return (
        <GridItem {...gridPlacement}>
            {data.map(({ title, content, time }, index) => (
                <CustomizedAccordion
                    key={index}
                    elevation={0}
                    // onChange={handleChange(index)}
                    expanded={expanded === index}
                    disableGutters
                >
                    <AccordionSummary
                        style={{
                            borderBottom: '1px solid',
                            minHeight: 80,
                            padding: breakpoints.sm ? '0 16px' : 0,
                            display: 'grid',
                            gridAutoFlow: 'column',
                            gap: 10,
                            gridTemplateColumns: '1fr max-content',
                            cursor: 'auto',
                        }}
                        expandIcon={
                            // <div></div>
                            <></>
                            // <div
                            //     onClick={() => null}
                            //     style={{
                            //         color:
                            //             expanded === index
                            //                 ? palette.MainYellow
                            //                 : palette.CommonWhite,
                            //         fontSize: breakpoints.md ? '18px' : '14px',
                            //     }}
                            // >
                            //     Опис події
                            // </div>
                        }
                    >
                        <Typography.Paragraph
                            style={{
                                color: palette.MainYellow,
                                fontWeight: '700',
                                fontSize: breakpoints.md
                                    ? '30px'
                                    : breakpoints.sm
                                    ? '20px'
                                    : '16px',
                            }}
                        >
                            {title}
                        </Typography.Paragraph>
                        <Typography.Paragraph
                            style={{
                                fontSize: breakpoints.md ? '18px' : '14px',
                                justifySelf: 'flex-end',
                            }}
                        >
                            {time}
                        </Typography.Paragraph>
                    </AccordionSummary>
                    <AccordionDetails
                        style={{ padding: '20px 0' }}
                        sx={{
                            borderBottom: `1px solid ${palette.MainYellow}`,
                        }}
                    >
                        {content}
                    </AccordionDetails>
                </CustomizedAccordion>
            ))}
        </GridItem>
    );
};
