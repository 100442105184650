import { api } from 'api/api';
import { AxiosResponse } from 'axios';
import { Lot, LotsRequest, OrganizationData } from '../types/lots.types';

export const LotsApi = {
    // GETs
    fetchLots: async ({
        limit = 20,
        order = 'desc',
        status = 'active',
        orderBy = 'bid-amount',
        skip = 0,
        organizationId,
    }: Partial<LotsRequest>): Promise<AxiosResponse<{ lots: Lot[]; count: number }>> =>
        await api.get(
            `/lots/filter?limit=${limit}&order=${order}&status=${status}&orderBy=${orderBy}&skip=${skip}${
                organizationId ? `&organizationId=${organizationId}` : ''
            }`,
        ),
    fetchLot: async (id: string): Promise<AxiosResponse<Lot>> =>
        await api.get(`/lots/${id}/details`),

    fetchOrganizations: async (): Promise<AxiosResponse<OrganizationData[]>> =>
        await api.get(`/organizations`),
    // POSTs
    placeBid: async (id: string, amount: number): Promise<AxiosResponse> =>
        await api.post(`/lots/${id}/bids/place`, { amount }),
};

export const PaymentsApi = {
    // POSTs
    paymentInit: async (
        lotId: string,
    ): Promise<
        AxiosResponse<{
            checkoutUrl: string;
        }>
    > => api.post('/payments', { lotId }),
};
