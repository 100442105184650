import styled from 'styled-components';
import { GridPlacement, gridPlacementCss } from '../_mixins/GridPlacement';
import { MainText } from '../_mixins/TextStyles';
import { palette } from '../_mixins/_palette';

const Paragraph = styled.p<GridPlacement & { withScroll?: boolean }>`
    ${MainText}
    ${gridPlacementCss}

    ${(p) =>
        p.withScroll
            ? `
    
    &::-webkit-scrollbar {
        width: 3px;

    }

    &::-webkit-scrollbar-thumb {
        background: ${palette.MainYellow};
    }

    overflow-x: hidden;
    `
            : ''}
`;

const Span = styled.span<GridPlacement>`
    ${MainText}
    ${gridPlacementCss}

    display: block;
`;

const Inline = styled.span<GridPlacement>`
    ${MainText}
    ${gridPlacementCss}
`;

const LandingTitle = styled.p<GridPlacement & { big?: boolean }>`
    ${gridPlacementCss}

    font-family: 'Playfair Display', serif;

    font-size: ${(p) => (p.big && p.theme.breakpoints.lg ? '220px' : p.big ? '140px' : '50px')};
    line-height: ${(p) => (p.big ? '100%' : '125%')};
    letter-spacing: 0.05em;
    font-style: normal;
    font-weight: 400;

    color: $paletteMainWhite;
`;

const H1 = styled.h1<GridPlacement>`
    ${gridPlacementCss}

    font-family: 'Neutral Face', 'Montserrat', sans-serif;
    font-size: 50px;
    line-height: 140%;
    letter-spacing: 0.05em;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;

    color: $paletteMainWhite;
`;

const H2 = styled.h2<GridPlacement>`
    ${gridPlacementCss}

    font-family: 'Neutral Face', 'Montserrat', sans-serif;
    font-size: 30px;
    line-height: 120%;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;

    color: $paletteMainWhite;
`;

const H3 = styled.h3<GridPlacement>`
    ${gridPlacementCss}

    font-family: 'Neutral Face', 'Montserrat', sans-serif;
    font-size: 25px;
    line-height: 120%;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;

    color: $paletteMainYellow;
`;

const Caption = styled.p<GridPlacement>`
    ${gridPlacementCss}

    ${MainText}
    font-size: 18px;
`;

const Tooltip = styled.p<GridPlacement>`
    ${gridPlacementCss}

    ${MainText}
    font-size: 12px;
`;

const ButtonText = styled.p<GridPlacement>`
    ${gridPlacementCss}

    font-family: 'Neutral Face', 'Montserrat', sans-serif;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 0.05em;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;

    color: $paletteMainWhite;
`;

const DecorativeLabel = styled.p<GridPlacement>`
    ${gridPlacementCss}

    ${MainText}
    font-size: 30px;
    color: ${palette.MainYellow};
    font-weight: bold;
    text-transform: uppercase;
`;

const ProductCardName = styled.p<GridPlacement>`
    ${MainText}
    ${gridPlacementCss}

    color: ${palette.MainBlack};
    letter-spacing: 0.05em;
    font-size: 18px;
    text-transform: uppercase;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 75px;
`;

const ProductCardInfo = styled.p<GridPlacement>`
    ${MainText}
    ${gridPlacementCss}

    color: ${palette.ProductCardInfoGrey};
    font-size: ${(p) => (p.theme.breakpoints.md ? '18px' : '14px')};
`;

const ProductCardInfoBold = styled.p<GridPlacement>`
    ${MainText}
    ${gridPlacementCss}

    color: ${palette.MainBlack};
    font-size: 18px;
`;

const FooterHeading = styled.p<GridPlacement>`
    ${gridPlacementCss}

    font-family: 'Neutral Face', 'Montserrat', sans-serif;
    font-size: 20px;
    line-height: 120%;
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;

    color: $paletteMainWhite;
`;

const FooterText = styled.p<GridPlacement>`
    ${gridPlacementCss}

    font-family: 'Neutral Face', 'Montserrat', sans-serif;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.05em;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;

    color: $paletteMainWhite;
`;

export const Typography = {
    Paragraph,
    Span,
    Inline,
    LandingTitle,
    H1,
    H2,
    H3,
    Caption,
    Tooltip,
    ButtonText,
    DecorativeLabel,
    ProductCardName,
    ProductCardInfo,
    ProductCardInfoBold,
    FooterHeading,
    FooterText,
};
