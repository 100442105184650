import { Lot } from 'domains/main/types/lots.types';
import { useState } from 'react';
import { logger } from 'utils/logger';
import { UserLotsApi } from '../api';

export const useUserLotsContext = () => {
    const [userLots, setUserLots] = useState<Lot[] | null>(null);
    const [userBids, setUserBids] = useState<Lot[] | null>(null);
    const [userWins, setUserWins] = useState<Lot[] | null>(null);

    const updateUserLots = (data: Lot[]) => {
        setUserLots(data);
    };

    const updateUserBids = (data: Lot[]) => {
        setUserBids(data);
    };

    const updateUserWins = (data: Lot[]) => {
        setUserWins(data);
    };

    const getUserLotsAsync = async () => {
        try {
            const { data } = await UserLotsApi.fetchUserLots();

            updateUserLots(data);
        } catch (error) {
            logger.error('Помилка завантаження лотів користувача', true, error);
        }
    };

    const getUserBidsAsync = async () => {
        try {
            const { data } = await UserLotsApi.fetchUserBids();

            updateUserBids(data);
        } catch (error) {
            logger.error('Помилка завантаження ставок користувача', true, error);
        }
    };

    const getUserWinsAsync = async () => {
        try {
            const { data } = await UserLotsApi.fetchUserWins();

            updateUserWins(data);
        } catch (error) {
            logger.error('Помилка завантаження виграних лотів', true, error);
        }
    };

    return {
        // data
        userLots,
        userBids,
        userWins,

        // sync
        updateUserLots,
        updateUserBids,
        updateUserWins,

        // async
        getUserLotsAsync,
        getUserBidsAsync,
        getUserWinsAsync,
    };
};
