import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import InstagramIcon from '@mui/icons-material/Instagram';
import LanguageIcon from '@mui/icons-material/Language';

const getShortLink = (fullLink: string) => {
    const arr = fullLink.split('/');

    return arr[arr.length - 1];
};

export const socialNetworkLinkParse = (fullLink: string) => {
    if (fullLink.includes('twitter.com'))
        return {
            type: 'twitter',
            fullLink,
            shortLink: getShortLink(fullLink),
            SocialNetworkIcon: TwitterIcon,
        };

    if (fullLink.includes('facebook.com'))
        return {
            type: 'facebook',
            fullLink,
            shortLink: getShortLink(fullLink),
            SocialNetworkIcon: FacebookIcon,
        };

    if (fullLink.includes('https://t.me'))
        return {
            type: 'telegram',
            fullLink,
            shortLink: getShortLink(fullLink),
            SocialNetworkIcon: TelegramIcon,
        };

    if (fullLink.includes('https://instagram.com'))
        return {
            type: 'instagram',
            fullLink,
            shortLink: getShortLink(fullLink),
            SocialNetworkIcon: InstagramIcon,
        };

    return {
        type: 'unknown',
        fullLink,
        shortLink: fullLink,
        SocialNetworkIcon: LanguageIcon,
    };
};
