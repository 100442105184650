import styled from 'styled-components';

export const AuctionCarouselOuterContainer = styled.div`
    width: 100%;

    overflow-x: scroll;
`;

export const AuctionCarouselInnerContainer = styled.div`
    display: flex;
    flex-wrap: 'nowrap';
    gap: 30px;

    transition: all 300ms ease-in-out;
    overflow: visible;
`;
