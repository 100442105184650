import { gridPlacementCss } from 'domains/ui/components/_mixins/GridPlacement';
import { palette } from 'domains/ui/components/_mixins/_palette';
import styled from 'styled-components';

export const Container = styled.div`
    height: 500px;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    // background-position-x: right !important;
    background-position-y: bottom !important;
    width: 100%;
    display: grid;
    padding: 30px;

    & .title {
        letter-spacing: 0.05em;
        font-style: normal;
        font-weight: 700;
        text-transform: uppercase;
    }
    & .when {
        font-weight: 700;
        font-size: 19px;
        line-height: 120%;
        color: ${palette.MainYellow};

        letter-spacing: 0.05em;
        margin-bottom: 25px;
    }
    & .where {
        font-weight: 400;
        font-size: 19px;
        line-height: 131%;
        text-transform: uppercase;
        white-space: break-spaces;
        letter-spacing: 0.05em;
    }
    & .leftBorder {
        font-weight: 400;
        font-size: 20px;
        text-transform: uppercase;
        line-height: 120%;
        height: max-content;
        letter-spacing: 0.05em;
        padding-left: 20px;
        border-left: 4px solid ${palette.MainYellow};
    }
`;
