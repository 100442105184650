import { useState } from 'react';
import { logger } from 'utils/logger';
import { LotsApi } from '../api';
import { Lot } from '../types/lots.types';

export const useLotInfo = () => {
    const [lot, setLot] = useState<Lot>({} as Lot);
    const [fetchedLotData, setFetchedLotDataState] = useState(false);

    const fetchLot = async (id: string) => {
        try {
            const data = await LotsApi.fetchLot(id);

            setLot(data.data);
        } catch (error) {
            logger.error('Помилка завантаження лоту', true, error);
        } finally {
            setFetchedLotDataState(true);
        }
    };

    return {
        lot,
        fetchLot,
        fetchedLotData,
    };
};
