// Core
import React, { FC, MouseEvent, ReactElement, useContext } from 'react';

// MUI / Styles
// IconButton, Tooltip
import { Backdrop, Dialog } from '@mui/material';
import { Typography } from 'domains/ui/components/_styled/Text';
import { TextButton } from 'domains/ui/components/TextButton';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { ButtonsGrid } from './styled';

// Context
import { AuthContext } from 'domains/auth/context/auth.context';
import { UiContext } from 'domains/ui/context/ui.context';
import { LotsContext } from 'domains/main/context/lots.context';

// Instruments
import { Lot } from 'domains/main/types/lots.types';
import { useLots } from 'domains/main/hooks/useLots';
import { notifications } from 'utils/notifications';
import { useButtonActions, useBreakpoints } from 'domains/ui/hooks';
// import CloseIcon from '@mui/icons-material/Close';

type ConfirmBidProps = {
    id: string;
    closeConfirmBid: (e: MouseEvent) => void;
    cardData?: Lot;
    bid: string;
};

export const ConfirmBid: FC<ConfirmBidProps> = ({
    id,
    closeConfirmBid,
    cardData,
    bid,
}): ReactElement => {
    const { isAuthenticated } = useContext(AuthContext);
    const { setOtpDialogState } = useContext(UiContext);
    const { updateLotPriceAfterBid } = useContext(LotsContext);

    const { placeBidAsync } = useLots();
    const { openProfile } = useButtonActions();

    const { title } = cardData || ({} as Lot);
    const { breakpoints } = useBreakpoints();

    const handleBidding = async () => {
        if (!bid) return null;

        if (!isAuthenticated) return setOtpDialogState(true);

        const success = await placeBidAsync(id, Number(bid));

        if (success) {
            notifications.success('Ставка успішна!');
            updateLotPriceAfterBid(id, Number(bid));
            openProfile(1);
        }
    };

    return (
        <Backdrop open onClick={closeConfirmBid}>
            <Dialog
                open
                onClose={closeConfirmBid}
                PaperProps={{
                    style: {
                        border: `${breakpoints.sm ? 35 : 10}px solid ${palette.MainYellow}`,
                        background: palette.MainBlack,
                        maxWidth: 650,
                        margin: 0,
                    },
                }}
            >
                {/* <IconButton
                    aria-label='logout'
                    onClick={closeConfirmBid}
                    style={
                        breakpoints.sm
                            ? { position: 'absolute', zIndex: 1, right: 10, top: 30 }
                            : { position: 'absolute', zIndex: 1, right: 0, top: 0 }
                    }
                >
                    <Tooltip title='Закрити'>
                        <CloseIcon style={{  width: 35, height: 35 }} />
                    </Tooltip>
                </IconButton> */}
                <Typography.Paragraph
                    style={{
                        padding: breakpoints.sm ? '40px 40px 0' : '20px 20px 0',
                        fontSize: 24,
                        textAlign: 'center',
                    }}
                >
                    Ви піднімаєте ставку на лот
                    <br />
                    <Typography.Inline style={{ fontWeight: 'bold', fontSize: 24 }}>
                        {' '}
                        {title}{' '}
                    </Typography.Inline>
                    до{' '}
                    <Typography.Inline
                        style={{ fontWeight: 'bold', color: palette.MainBlue, fontSize: 28 }}
                    >
                        {' '}
                        {bid}₴
                    </Typography.Inline>
                </Typography.Paragraph>
                <ButtonsGrid>
                    <TextButton onClick={closeConfirmBid} text={'Скасувати'} hideArrow borderless />
                    <TextButton
                        onClick={handleBidding}
                        text={'Підтвердити'}
                        overrideHoverColor={palette.MainYellow}
                        hideArrow
                        borderless
                    />
                </ButtonsGrid>
            </Dialog>
        </Backdrop>
    );
};
