// Core
import React, { FC, useContext, useEffect } from 'react';

// UI
import { DefaultGridContainer } from 'domains/ui/components/_styled/DefaultGridContainer';
import { Typography } from 'domains/ui/components/_styled/Text';
import { ImageWithPadding } from 'domains/ui/components/_styled/ImageWithPadding';

// Assets
import img from 'theme/assets/png/results-img.png';
import flag from 'theme/assets/svg/flag.svg';
import { GridItem } from 'domains/ui/components/_styled/GridItem';
import { useBreakpoints, useButtonActions } from 'domains/ui/hooks';
import { LandingMarkupIds } from '../types';
import { UiContext } from 'domains/ui/context/ui.context';
import { TextButton } from 'domains/ui/components/TextButton';
import { delayedScrollingToHash } from 'utils/delayedScrolling';
import { useLocation } from 'react-router-dom';
import { ResultsCounters } from 'domains/main/components/ResultsCounters';

export const Results: FC = () => {
    const location = useLocation();

    const { breakpoints } = useBreakpoints();

    const { openArchive } = useButtonActions();

    const { dashboardStats } = useContext(UiContext);

    useEffect(() => {
        if (dashboardStats) {
            if (location.hash === `#${LandingMarkupIds.RESULTS}`) {
                delayedScrollingToHash(location.hash);
            }
        }
    }, [dashboardStats, location]);

    return (
        dashboardStats && (
            <DefaultGridContainer
                rowGap={breakpoints.lg ? 80 : 50}
                id={LandingMarkupIds.RESULTS}
                style={{ paddingTop: breakpoints.lg ? '150px' : '120px' }}
            >
                <Typography.H2 gridColumn='1 / -1' placeSelf='start'>
                    Результати аукціону
                </Typography.H2>

                <ImageWithPadding
                    src={img}
                    alt=''
                    padding='left'
                    color='yellow'
                    gridColumn={{
                        xl: '6 / -1',
                        lg: '4 / -1',
                        xs: '1 / -1',
                    }}
                />

                <GridItem
                    gridColumn={{
                        xl: '1 / 5',
                        lg: '1 / 4',
                        md: '1 / -1',
                        xs: '1 / -1',
                    }}
                    gridRow={{ sm: 2, xs: 3 }}
                    placeSelf='start'
                    style={{
                        display: 'grid',
                        gap: breakpoints.md ? 50 : 40,
                        justifyContent: 'space-between',
                        gridTemplateColumns: breakpoints.lg || !breakpoints.sm ? '1fr' : '1fr 1fr',
                        width: '100%',
                    }}
                >
                    <ResultsCounters styles={{ display: 'grid', gap: breakpoints.md ? 50 : 40 }} />
                    <TextButton
                        text='Виграні лоти'
                        onClick={openArchive}
                        style={{
                            marginTop: breakpoints.lg ? 45 : 0,
                            width: breakpoints.lg ? 295 : '100%',
                            gridRow: breakpoints.lg ? undefined : 1,
                        }}
                        gridColumn={{ lg: 1, sm: 2, xs: 1 }}
                    />
                    {!breakpoints.lg && (
                        <img
                            src={flag}
                            alt=''
                            style={
                                breakpoints.sm
                                    ? {
                                          gridRow: 1,
                                          gridColumn: 2,
                                          display: 'flex',
                                          justifySelf: 'flex-end',
                                          alignSelf: 'self-end',
                                      }
                                    : {
                                          gridRow: 3,
                                          gridColumn: 1,
                                      }
                            }
                        />
                    )}
                </GridItem>
            </DefaultGridContainer>
        )
    );
};
