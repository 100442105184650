import styled from 'styled-components';
import { GridPlacement, gridPlacementCss } from '../_mixins/GridPlacement';
import { palette } from '../_mixins/_palette';

type Props = {
    variant: 'small' | 'big';
    hoverColor: 'blue' | 'yellow';
    disabled?: boolean;
} & GridPlacement;

export const RoundButtonContainer = styled.button<Props>`
    cursor: pointer;

    display: grid;
    place-items: center;
    ${gridPlacementCss}

    height: ${(p) => (p.variant === 'big' ? '80px' : '50px')};
    width: ${(p) => (p.variant === 'big' ? '80px' : '50px')};
    padding: ${(p) => (p.variant === 'big' ? '12px' : '6px')};

    background: ${palette.MainWhite};

    border-radius: 100%;
    border: none;

    &:hover {
        background: ${(p) => (p.hoverColor === 'blue' ? palette.MainBlue : palette.MainYellow)};
    }

    &:disabled {
        background: ${palette.DisabledGrey};
    }

    transition: all 300ms linear;
`;
