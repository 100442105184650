import { gridPlacementCss } from 'domains/ui/components/_mixins/GridPlacement';
import { palette } from 'domains/ui/components/_mixins/_palette';
import styled from 'styled-components';

export const Container = styled.div`
    background: ${palette.MainBlue};
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    transform: rotate(-2deg);

    width: 100.5%;
    position: absolute;
    left: -2px;

    & div {
        width: 100%;
        font-weight: 400;
        font-size: 19px;
        line-height: 131%;
        letter-spacing: 0.05em;
        white-space: pre;
    }
    & p {
        text-transform: uppercase;
        text-align: center;
    }
    & span {
        font-weight: 700;
    }
    & .marquee {
        margin: 0 auto;
        display: flex;
        gap: 50px;
        justify-content: center;
        overflow: hidden;
    }
`;
