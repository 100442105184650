// Core
import React, { FC, MouseEvent, ReactElement } from 'react';

// UI
import { Backdrop } from '@mui/material';
import { CopyLinkField, ShareDialogContainer } from './styled';

// Assets
import TelegramIcon from '@mui/icons-material/Telegram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { SocialNetworkIconWrapper } from '../_styled/SocialNetworkIconWrapper';
import { GridItem } from '../_styled/GridItem';
import { Typography } from '../_styled/Text';
import { palette } from '../_mixins/_palette';
import { TextButton } from '../TextButton';
import { shareLinkGenerator } from './shareLinkGenerator';
import { notifications } from 'utils/notifications';

type ShareDialogProps = {
    inProp: boolean;
    shareLink: string;
    shareText: string;
    closeDialog: (e: MouseEvent) => void;
};

export const ShareDialog: FC<ShareDialogProps> = ({
    inProp,
    shareLink,
    shareText,
    closeDialog,
}): ReactElement => {
    const shareData = {
        link: shareLink,
        text: shareText,
        hashtag: '#аукція_рп',
        twitterHashtags: ['#аукція_рп'],
    };

    const shareIcons = [
        {
            SocialIcon: TwitterIcon,
            title: 'Twitter',
        },
        {
            SocialIcon: FacebookIcon,
            title: 'Facebook',
        },
        {
            SocialIcon: TelegramIcon,
            title: 'Telegram',
        },
        {
            SocialIcon: LinkedInIcon,
            title: 'LinkedIn',
        },
    ];

    const handleCopy = async (e: MouseEvent) => {
        e.stopPropagation();

        try {
            await navigator.clipboard.writeText(shareLink);
            notifications.success('Скопійовано!');
        } catch (err) {
            notifications.error('Не вдалося скопіювати посилання');
        }
    };

    return (
        <Backdrop open={inProp} onClick={closeDialog} style={{ zIndex: 1 }}>
            <ShareDialogContainer>
                {shareIcons.map(({ SocialIcon, title }, index) => (
                    <GridItem
                        style={{
                            display: 'grid',
                            placeItems: 'center',
                            gap: 5,
                        }}
                        key={index}
                    >
                        <SocialNetworkIconWrapper
                            onClick={(e) => {
                                e.stopPropagation();

                                window.open(
                                    shareLinkGenerator({
                                        media: title.toLowerCase(),
                                        ...shareData,
                                    }),
                                    '_blank',
                                );
                            }}
                            dark
                        >
                            <SocialIcon />
                        </SocialNetworkIconWrapper>
                        <Typography.Span style={{ color: palette.MainBlack, fontSize: 14 }}>
                            {title}
                        </Typography.Span>
                    </GridItem>
                ))}

                <CopyLinkField gridColumn='1 / -1'>
                    <Typography.ProductCardInfoBold
                        onClick={handleCopy}
                        style={{
                            cursor: 'pointer',
                            width: '100%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {shareLink}
                    </Typography.ProductCardInfoBold>

                    <TextButton
                        text='Скопіювати'
                        onClick={handleCopy}
                        hideArrow
                        borderless
                        style={{
                            height: 40,
                            color: palette.MainBlue,
                        }}
                    />
                </CopyLinkField>
            </ShareDialogContainer>
        </Backdrop>
    );
};
