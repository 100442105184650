// Core
import React, { FC, MouseEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// MUI
import { Fade, IconButton } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';

// Instruments
import { DateTime } from 'luxon';
import { Lot, LotStatus } from 'domains/main/types/lots.types';
import { GridPlacement } from 'domains/ui/components/_mixins/GridPlacement';
import { usePayment } from 'domains/main/hooks/usePayment';
import { AuctionCardContainer, AuctionCardData } from './styled';
import { Typography } from 'domains/ui/components/_styled/Text';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { TextButton } from 'domains/ui/components/TextButton';
import { ShareDialog } from 'domains/ui/components/ShareDialog';
import { useBreakpoints } from 'domains/ui/hooks';
import { handleMobileShare } from 'utils/handleMobileShare';
import { AuctioCardLotData } from '../AuctioCardLotData';
import { MonoBankaInstructions } from '../MonoBankaInstructions';

type AuctioCardWithBankaProps = {
    isDesktop: boolean;
    isOwner: boolean;
    background: 'yellow' | 'blue';
    isWinner?: boolean;
} & Lot &
    GridPlacement;

export const AuctioCardWithBanka: FC<AuctioCardWithBankaProps> = ({
    background,
    id,
    endDate,
    title,
    pictureUrl,
    images,
    isLastBidder,
    creator,
    price,
    isDesktop,
    isOwner,
    isWinner,
    status,
    extendedEndDate,
    lastBidAmount,
    merchant,
    additionalInfo,
    ...gridPlacement
}) => {
    const [shareOpen, setShareOpenState] = useState(false);
    const [alternativePaymentInstructions, setAlternativePaymentInstructionsState] =
        useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const { isMobile, isTablet, breakpoints } = useBreakpoints();
    const { paymentInProgress } = usePayment();

    const openShareDialog = (e: MouseEvent) => {
        e.stopPropagation();
        setShareOpenState(true);
    };

    const closeShareDialog = (e: MouseEvent) => {
        e.stopPropagation();
        setShareOpenState(false);
    };

    const toEndDate = DateTime.fromJSDate(new Date(extendedEndDate || endDate))
        .diffNow(['days', 'hours', 'minutes'])
        .mapUnits((unit) => Math.round(unit))
        .toObject();

    const auctionFinished = (Object.keys(toEndDate) as (keyof typeof toEndDate)[]).every(
        (el) => Number(toEndDate[el]) <= 0,
    );

    const navigateToLotDetails = () =>
        !auctionFinished &&
        !shareOpen &&
        navigate(`?id=${id}`, {
            state: {
                from: `${location.pathname}${location.search}`,
            },
        });

    const getButtonData = () => {
        return {
            text: 'Відкрити банку',
            onClick: () => setAlternativePaymentInstructionsState(true),
        };
    };

    const handleShareClick = (e: MouseEvent) => {
        if (isMobile || isTablet) {
            handleMobileShare(
                e,
                {
                    shareLink: `${window.location.href}`,
                    shareText: `Аукціон "Лоти проти Сволоти" - ${title} від ${creator.firstName} ${creator.lastName}\n`,
                },
                openShareDialog,
            );
        } else {
            openShareDialog(e);
        }
    };
    return (
        <Fade in>
            <AuctionCardContainer
                background={background}
                {...gridPlacement}
                onClick={navigateToLotDetails}
                style={status === LotStatus.FINISHED ? { cursor: 'auto' } : {}}
            >
                <div
                    style={{
                        backgroundImage:
                            images && images.length > 0
                                ? `url(${images[0].url})`
                                : `url(${pictureUrl})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        minWidth: breakpoints.md ? 280 : 200,

                        margin: breakpoints.md ? 40 : 25,
                    }}
                />

                <AuctionCardData>
                    <Typography.ProductCardName gridColumn='1 / -1'>
                        {title}
                    </Typography.ProductCardName>

                    <AuctioCardLotData
                        id={id}
                        auctionFinished={auctionFinished}
                        extendedEndDate={extendedEndDate}
                        toEndDate={toEndDate}
                        price={price}
                        merchant={merchant}
                    />

                    {!auctionFinished && (
                        <IconButton
                            aria-label='settings'
                            onClick={handleShareClick}
                            sx={{
                                placeSelf: 'end start',
                                gridColumn: 1,
                                gridRow: 5,
                                '&:hover': {
                                    background: '#f0f0f0',
                                },
                            }}
                        >
                            <ShareIcon htmlColor={palette.MainBlack} />
                        </IconButton>
                    )}

                    <TextButton
                        onClick={getButtonData().onClick}
                        text={getButtonData().text}
                        disabled={paymentInProgress}
                        placeSelf='end'
                        gridRow={5}
                        gridColumn='1 / -1'
                        hideArrow
                        dark
                        style={{
                            height: 40,
                            fontWeight: 'bold',
                            visibility:
                                !status || status === LotStatus.ACTIVE || isOwner || isWinner
                                    ? 'visible'
                                    : 'hidden',
                        }}
                    />
                </AuctionCardData>

                <ShareDialog
                    inProp={shareOpen}
                    shareLink={`${window.location.href}`}
                    shareText={`Аукціон "Лоти проти Сволоти" - ${title} від ${creator.firstName} ${creator.lastName}\n`}
                    closeDialog={closeShareDialog}
                />

                {alternativePaymentInstructions && (
                    <MonoBankaInstructions
                        merchant={merchant}
                        alternativePaymentLink={additionalInfo.alternativePaymentLink}
                        closeMonoBankaInstructions={() =>
                            setAlternativePaymentInstructionsState(false)
                        }
                        lotName={title}
                        price={price}
                        id={id}
                    />
                )}
            </AuctionCardContainer>
        </Fade>
    );
};
