// Common
const CommonBlack = '#000000';
const CommonWhite = '#ffffff';

// Color Palette
const Bg = '#0e0e0e';

const MainYellow = '#fdd835';
const MainBlue = '#2196f3';
const MainWhite = '#ffffff';
const MainBlack = '#1a1a1a';
const MainError = '#f86363';

const PlaceholderGrey = '#494949';
const DisabledGrey = '#646464';
const ProductCardInfoGrey = '#767676';

export const palette = {
    CommonBlack,
    CommonWhite,
    Bg,
    MainYellow,
    MainBlue,
    MainWhite,
    MainBlack,
    MainError,
    PlaceholderGrey,
    DisabledGrey,
    ProductCardInfoGrey,
};
