// Core
import React, { FC, ReactElement, useContext, useState } from 'react';
import { DialogActions } from '@mui/material';
import { TextButton } from 'domains/ui/components/TextButton';
import { AuthContext } from 'domains/auth/context/auth.context';
import { SignupDto } from 'domains/auth/types/auth';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { SignupCheckboxes } from './SignupCheckBoxes';
import { useBreakpoints } from 'domains/ui/hooks';

type SignupActionsProps = {
    signupData: SignupDto;
    containerRef: React.MutableRefObject<HTMLDivElement | undefined>;
    prevStep: () => void;
    nextStep: () => void;
};

export const SignupActions: FC<SignupActionsProps> = ({
    signupData,
    containerRef,
    prevStep,
    nextStep,
}): ReactElement => {
    const [checkboxesChecked, setCheckboxesChecked] = useState(false);
    const { authenticationInProgress } = useContext(AuthContext);
    const { breakpoints } = useBreakpoints();

    return (
        <>
            <SignupCheckboxes
                containerRef={containerRef}
                setCheckboxesChecked={setCheckboxesChecked}
            />

            <DialogActions
                sx={{
                    px: breakpoints.sm ? '40px' : '20px',
                    display: 'flex',
                    flexWrap: breakpoints.sm ? 'wrap-reverse' : 'nowrap',
                }}
            >
                <TextButton onClick={prevStep} text='Закрити' hideArrow borderless />

                <TextButton
                    onClick={nextStep}
                    disabled={!checkboxesChecked || authenticationInProgress}
                    overrideHoverColor={palette.MainYellow}
                    style={{
                        marginLeft: 20,
                    }}
                    text={'Далі'}
                    hideArrow
                    borderless
                />
            </DialogActions>
        </>
    );
};
