import { LeanUser } from 'domains/profile/types/profile.types';

export type MerchantData = {
    id: string;
    name: string;
};

export type OrganizationData = {
    id: string;
    name: string;
    pictureUrl: string;
};
export type ImageType = {
    url: string;
    blurhash: string;
};
export type AdditionalLotInfo = {
    alternativePaymentLink?: string;
    [key: string]: string | undefined;
};
export type Lot = {
    id: string;
    title: string;
    description: string;
    pictureUrl: string;
    images: ImageType[];
    price: number;
    endDate: Date;
    extendedEndDate: Date | null;
    isLastBidder: true;
    lastBidAmount: number;
    creator: LeanUser;
    merchant: MerchantData;
    additionalInfo: AdditionalLotInfo;
    status?: LotStatus;
    type: 'BIDDABLE' | 'LOTTERY';
};

export enum LotStatus {
    ACTIVE = 'ACTIVE',
    FINISHED = 'FINISHED',
    PAID = 'PAID',
}
export type LotsOrder = 'asc' | 'desc';
// export type LotsOrderByFE = 'low-bid' | 'high-bid';
export type LotsOrderBy = 'end-date' | 'creation-date' | 'bid-amount';
export type LotsOrderByFE = 'end-date' | 'creation-date' | 'low-bid' | 'high-bid';

export type LotsStatus = 'active' | 'finished' | 'paid';
export type LotsRequest = {
    limit?: number;
    order?: LotsOrder;
    orderBy?: LotsOrderBy;
    status?: LotsStatus;
    skip?: number;
    organizationId?: string;
};
