import { notifications } from './notifications';

export const logger = {
    error: (message: string, showNotification = true, ...args: any[]) => {
        console.error(message);
        console.error(...args);

        if (showNotification) {
            notifications.error(message);
        }
    },
};
