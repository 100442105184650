import { palette } from 'domains/ui/components/_mixins/_palette';
import styled from 'styled-components';
import { TextButton } from 'domains/ui/components/TextButton';
import { GridItem } from 'domains/ui/components/_styled/GridItem';

export const Tab = styled(TextButton)<{ active?: boolean }>`
    ${(p) => (p.active ? `background: ${palette.MainBlue}; color: ${palette.MainWhite};` : '')}
    padding: 15px;
    padding-right: 100px;
    max-width: 410px;
    width: 100%;

    &:hover {
        background: ${palette.MainBlue};
        color: ${palette.MainWhite};
    }
`;
export const FilterBox = styled(GridItem)<{}>`
    display: grid;
    grid-template-columns: 1fr;
    overflow: hidden;
    position: absolute;
    transition: all 300ms ease-in-out 0s;
    border: 1px solid white;
    max-width: 410px;
    width: 100%;
    background: ${palette.Bg};
    right: 0;
`;
