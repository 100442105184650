import styled, { css } from 'styled-components';

type Props = {
    placeItems?: string;
    rowGap?: number;
};

export const defaultGridCss = css`
    display: grid;
    grid-template-columns: ${(p) =>
        `repeat(${p.theme.variables.gridColumsCount}, ${p.theme.constants.APP_UI_COL_WIDTH}px)`};
    grid-auto-rows: max-content;

    /* column-gap: ${(p) => `${p.theme.constants.APP_UI_COL_GAP}px`}; */

    justify-content: space-between;

    width: 100%;
`;

export const DefaultGridContainer = styled.div<Props>`
    ${defaultGridCss}
    ${(p) => `row-gap: ${p.rowGap || 30}px;`}
    place-items: ${(props) => props.placeItems || 'start center'};

    width: 100%;
    height: 100%;
`;
