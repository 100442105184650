import styled from 'styled-components';

export const SocialsContainer = styled.div`
    display: grid;
    grid-template-rows: max-content 1fr;
    grid-template-columns: ${(p) =>
        p.theme.breakpoints.lg
            ? 'repeat(3, 1fr)'
            : p.theme.breakpoints.sm
            ? 'repeat(2, 1fr)'
            : '1fr'};
    place-items: start;
    gap: 30px;
`;
