// Core
import React, { FC, ReactElement } from 'react';
import { YoutubeVideo } from '../YoutubeVideo';

type DescriptionLinkProps = {
    href: string;
    children: string;
};

const youtubeLinkTransformer = (link: string) => {
    const embededLinkStart = 'https://www.youtube.com/embed/';

    if (link.startsWith('https://youtube.com/embed/')) {
        return embededLinkStart + link.split('https://youtube.com/embed/')[0];
    }

    if (link.startsWith('https://www.youtube.com/watch?v=')) {
        return embededLinkStart + link.split('https://www.youtube.com/watch?v=')[0];
    }

    if (link.startsWith('https://youtube.com/watch?v=')) {
        return embededLinkStart + link.split('https://youtube.com/watch?v=')[0];
    }

    if (link.startsWith('https://youtu.be/')) {
        return embededLinkStart + link.split('https://youtu.be/')[0];
    }

    return link;
};

export const DescriptionLink: FC<DescriptionLinkProps> = ({ href, children }): ReactElement => {
    return href.startsWith('https://youtube.com/embed') ||
        href.startsWith('https://youtu.be/') ||
        href.startsWith('https://www.youtube.com/watch?v=') ||
        href.startsWith('https://youtube.com/watch?v=') ||
        href.startsWith('https://www.youtube.com/embed/') ? (
        <YoutubeVideo link={youtubeLinkTransformer(href)} />
    ) : (
        <a href={href} rel='noopener noreferrer' target='_blank'>
            {children}
        </a>
    );
};
