import { FC, ReactElement, useContext, useEffect } from 'react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AuthContext } from 'domains/auth/context/auth.context';
import { AuthLocalStorageKeys } from 'domains/auth/types/auth';

export const MAIN_URL = process.env.REACT_APP_MAIN_URL;

const ignoreList = ['/auth/refresh-tokens'];

export const api = axios.create({
    baseURL: MAIN_URL,
    withCredentials: true,
    headers: {
        'content-type': 'application/json',
    },
});

const getReqHandler = (accessToken?: string) => async (config: AxiosRequestConfig) => {
    const token = localStorage.getItem(AuthLocalStorageKeys.ACCESS_TOKEN);
    // const token = accessToken;

    if (!token) return config;

    return {
        ...config,
        headers: {
            ...config.headers,
            Authorization: `Bearer ${token}`,
        },
    };
};

export const AxiosInterceptor: FC<{ children: ReactElement<any, any> | null }> = ({ children }) => {
    const { accessToken, refreshTokensAsync, unauthenticate } = useContext(AuthContext);

    useEffect(() => {
        const resHandler = (response: AxiosResponse<any, any>) => {
            return response;
        };

        const errHandler = async (err: any) => {
            if (!err.response) return Promise.reject(new Error('Unable to reach the server'));

            const originalReq = err.config;

            // Prevent infinite loops
            if (err.response.status === 401 && ignoreList.includes(originalReq.url)) {
                unauthenticate();
                return Promise.reject(err.response.data);
            }

            if (err.response.status === 401) {
                const accessToken = await refreshTokensAsync();

                return api({
                    ...originalReq,
                    headers: {
                        ...originalReq.headers,
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
            }

            return Promise.reject(err.response.data);
        };

        const reqInterceptor = api.interceptors.request.use(getReqHandler(accessToken));
        const resInterceptor = api.interceptors.response.use(resHandler, errHandler);

        return () => {
            api.interceptors.request.eject(reqInterceptor);
            api.interceptors.response.eject(resInterceptor);
        };
        // eslint-disable-next-line
    }, [accessToken]);

    return children;
};
