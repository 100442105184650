// Core
import React, { FC, ReactNode, SyntheticEvent, useState } from 'react';

// MUI
import { Accordion as MUIAccordion, AccordionDetails, AccordionSummary } from '@mui/material';
import styled from '@emotion/styled';
import { GridPlacement } from '../_mixins/GridPlacement';
import { GridItem } from '../_styled/GridItem';
import { palette } from '../_mixins/_palette';
import { RoundArrowButton } from '../RoundArrowButton';
import { Typography } from '../_styled/Text';

type AccordionProps = {
    data: {
        title: ReactNode;
        content: ReactNode;
    }[];
} & GridPlacement;

const CustomizedAccordion = styled(MUIAccordion)(() => ({
    '& .MuiPaper-root': {
        borderRadius: 0,
        background: 'transparent',
    },
    '& .MuiAccordionSummary-content': {
        margin: 0,
    },
}));

export const Accordion: FC<AccordionProps> = ({ data, ...gridPlacement }) => {
    const [expanded, setExpanded] = useState<number | false>(false);

    const handleChange = (key: number) => (event: SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? key : false);
    };

    return (
        <GridItem {...gridPlacement}>
            {data.map(({ title, content }, index) => (
                <CustomizedAccordion
                    key={index}
                    elevation={0}
                    onChange={handleChange(index)}
                    expanded={expanded === index}
                    disableGutters
                >
                    <AccordionSummary
                        expandIcon={
                            <RoundArrowButton
                                onClick={() => null}
                                hoverColor='yellow'
                                variant='small'
                                rotation={90}
                            />
                        }
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                        sx={{
                            padding: '20px 0',
                            borderBottom: `1px solid ${
                                expanded === index ? 'transparent' : palette.MainYellow
                            }`,
                            transition: 'all 300ms linear',
                        }}
                    >
                        <Typography.H3>{title}</Typography.H3>
                    </AccordionSummary>
                    <AccordionDetails
                        style={{ padding: '20px 0' }}
                        sx={{
                            borderBottom: `1px solid ${palette.MainYellow}`,
                        }}
                    >
                        {content}
                    </AccordionDetails>
                </CustomizedAccordion>
            ))}
        </GridItem>
    );
};
