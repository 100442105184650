// Core
import React, { FC, ReactElement, useState } from 'react';

// Instruments
import { TextButton } from 'domains/ui/components/TextButton';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { GridItem } from 'domains/ui/components/_styled/GridItem';
import { Typography } from 'domains/ui/components/_styled/Text';
import { AntytilaBanner } from '../AntytilaBanner';

type AntytilaHeaderProps = {
    className?: string;
};

export const AntytilaHeader: FC<AntytilaHeaderProps> = ({ className }): ReactElement => {
    const [detailsOpened, setDetailsOpenedState] = useState(false);

    return (
        <>
            <AntytilaBanner gridColumn='1 / -1' />

            <Typography.H3
                gridColumn='1 / -1'
                placeSelf='center'
                style={{
                    color: palette.MainYellow,
                    fontSize: 15,
                    textAlign: 'center',
                }}
            >
                Конвертуємо трофеї з фронту на засоби захисту, ураження та розвідки для наших бійців
            </Typography.H3>

            <GridItem
                gridColumn='1  / -1'
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    gap: 20,
                    width: '100%',
                    marginTop: -40,
                    paddingBottom: 40,
                    borderBottom: `1px solid ${palette.MainYellow}`,
                }}
            >
                <TextButton
                    text='Про фонд '
                    onClick={() => window.open('https://www.antytila.ua/pro-fond/', '_blank')}
                    style={{
                        minWidth: 330,
                    }}
                />

                <TextButton
                    text='Про аукціон'
                    onClick={() => setDetailsOpenedState((prev) => !prev)}
                    style={{
                        minWidth: 330,
                    }}
                />

                <TextButton
                    text='Швидкий донат'
                    onClick={() => window.open('https://send.monobank.ua/9wFQ4PMZcg', '_blank')}
                    style={{
                        minWidth: 330,
                    }}
                />
            </GridItem>

            <GridItem
                gridColumn='1  / -1'
                style={{
                    width: '100%',
                    borderBottom: `1px solid ${palette.MainYellow}`,
                    marginTop: -81,

                    overflow: 'hidden',

                    maxHeight: detailsOpened ? '100vh' : 0,

                    transition: 'all 0.5s ease-in-out',
                }}
            >
                <Typography.H3 style={{ fontSize: 20, marginTop: 40 }}>
                    Аукціон від Благодійного Фонду «Антитіла»
                </Typography.H3>
                <br />
                <Typography.Paragraph>
                    Конвертуємо трофеї з фронту на засоби захисту, ураження та розвідки для наших
                    бійців!
                </Typography.Paragraph>
                <br />
                <Typography.Paragraph style={{ marginBottom: 40 }}>
                    Наші лоти - це{' '}
                    <Typography.Span style={{ color: palette.MainYellow, display: 'inline' }}>
                        найрізноманітніші трофеї війни
                    </Typography.Span>
                    , які доповнять вашу колекцію, або ж залишаться згадкою про те, як здобувалась
                    наша перемога.
                </Typography.Paragraph>
            </GridItem>
        </>
    );
};
