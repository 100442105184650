import { InfoLocalStorageKeys } from 'domains/auth/types/auth';
import { UserContext } from 'domains/profile/context/user.context';
import { UiContext } from 'domains/ui/context/ui.context';
import { useContext, useState } from 'react';
import { LotsApi } from '../api';

export const useLots = () => {
    const [biddingInProgress, setBiddingProgressState] = useState(false);
    const { showTelegramDialog } = useContext(UiContext);

    const placeBidAsync = async (id: string, amount: number) => {
        setBiddingProgressState(true);

        try {
            await LotsApi.placeBid(id, amount);

            showTelegramDialog(InfoLocalStorageKeys.TELEGRAM_POPUP_BID);

            return true;
        } catch (err) {
            return false;
        } finally {
            setBiddingProgressState(false);
        }
    };

    return {
        biddingInProgress,

        placeBidAsync,
    };
};
