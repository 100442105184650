// Core
import React, { FC, ReactElement } from 'react';

// UI
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LanguageIcon from '@mui/icons-material/Language';
import { FooterInnerContainer, FooterLink, FooterOuterContainer } from './styled';
import { DefaultGridContainer } from '../_styled/DefaultGridContainer';
import { Typography } from '../_styled/Text';
import { GridItem } from '../_styled/GridItem';
import { useButtonActions, useBreakpoints } from 'domains/ui/hooks';
import { SocialNetworkIconWrapper } from '../_styled/SocialNetworkIconWrapper';
import { palette } from '../_mixins/_palette';
import { TextButton } from '../TextButton';

export const Footer: FC = (): ReactElement => {
    const {
        handleCreateNewLot,
        openAuction,
        openPolicy,
        openRules,
        handleOpenFeedback,
        handleOpenTelegram,
        handleOpenTwitter,
        handleOpenFacebook,
        handleOpenInstagram,
        handleOpenFulcrum,
    } = useButtonActions();

    const { breakpoints } = useBreakpoints();

    const NavigationLinks = [
        { title: 'Всі лоти', action: openAuction },
        { title: 'Створити лот', action: handleCreateNewLot },
        { title: 'Правила спільноти', action: openRules },
        { title: 'Політика конфіденційності', action: openPolicy },
        { title: 'Зворотній зв`язок', action: handleOpenFeedback },
    ];

    const NetworkLinks = [
        {
            title: '@LotyProty',
            iconComponent: <TwitterIcon sx={{ color: palette.MainBlue }} />,
            action: handleOpenTwitter,
        },
        {
            title: '@LotyProty',
            iconComponent: <TelegramIcon sx={{ color: palette.MainBlue }} />,
            action: handleOpenTelegram,
        },
        {
            title: '@loty_proty',
            iconComponent: <InstagramIcon sx={{ color: palette.MainBlue }} />,
            action: handleOpenInstagram,
        },
        {
            title: '@lotyproty',
            iconComponent: <FacebookIcon sx={{ color: palette.MainBlue }} />,
            action: handleOpenFacebook,
        },
        {
            title: 'fulcrum.rocks',
            iconComponent: <LanguageIcon sx={{ color: palette.MainBlue }} />,
            action: handleOpenFulcrum,
        },
    ];

    return (
        <FooterOuterContainer>
            <FooterInnerContainer>
                <DefaultGridContainer rowGap={50} placeItems='start'>
                    <Typography.FooterHeading
                        gridColumn={{
                            lg: '1 / span 6',
                            xs: '1 / -1',
                        }}
                        gridRow={1}
                    >
                        Благодійний онлайн-аукціон
                    </Typography.FooterHeading>

                    <GridItem
                        gridRow={2}
                        gridColumn={{
                            xl: '1 / span 5',
                            lg: '1 / span 4',
                            md: '1 / span 2',
                            xs: '1 / -1',
                        }}
                        style={{
                            display: 'grid',
                            width: '100%',

                            justifyContent: 'space-between',
                            gap: breakpoints.xl ? 30 : 15,
                            gridTemplateColumns: breakpoints.md
                                ? '1fr'
                                : breakpoints.sm
                                ? '1fr 1fr'
                                : '1fr',
                            gridTemplateRows: breakpoints.lg
                                ? '1fr'
                                : breakpoints.md
                                ? '1fr 1fr'
                                : breakpoints.sm
                                ? '1fr 1fr 1fr'
                                : '1fr 1fr 1fr 1fr 1fr',
                            gridAutoFlow: breakpoints.md ? undefined : 'column',
                        }}
                    >
                        {NavigationLinks.map((item, id) => (
                            <TextButton
                                key={id}
                                borderless
                                overrideHoverColor={palette.MainYellow}
                                onClick={item.action}
                                text={item.title}
                                hideArrow
                                style={{
                                    fontSize: 16,
                                    height: 'max-content',
                                }}
                            />
                        ))}
                    </GridItem>

                    <GridItem
                        gridRow={{
                            xl: '1 / 3',
                            lg: '1 / 3',
                            md: '1 / 3',
                            xs: '3 / 4',
                        }}
                        gridColumn={{
                            xl: '7 / -1',
                            lg: '5 / -1',
                            md: '4 / -1',
                            xs: '1 / -1',
                        }}
                        style={{
                            display: 'grid',
                            gap: 50,
                            width: '100%',
                        }}
                    >
                        <Typography.FooterHeading
                            gridColumn={{
                                lg: '1 / span 6',
                                xs: '1 / -1',
                            }}
                            gridRow={1}
                        >
                            наші соціальні мережі
                        </Typography.FooterHeading>
                        <GridItem
                            style={{
                                display: 'grid',
                                gap: 20,
                                gridTemplateColumns: breakpoints.lg
                                    ? '1fr 1fr 1fr'
                                    : breakpoints.sm
                                    ? '1fr 1fr'
                                    : '1fr',
                                gridTemplateRows: '1fr 1fr',
                            }}
                        >
                            {NetworkLinks.map((item, id) => (
                                <FooterLink
                                    onClick={item.action}
                                    key={id}
                                    style={{ textDecoration: 'underline' }}
                                >
                                    <SocialNetworkIconWrapper height={30}>
                                        {item.iconComponent}
                                    </SocialNetworkIconWrapper>
                                    {item.title}
                                </FooterLink>
                            ))}
                        </GridItem>
                    </GridItem>
                </DefaultGridContainer>
            </FooterInnerContainer>
        </FooterOuterContainer>
    );
};
