// Core
import React, { FC, MouseEvent, ReactElement, useEffect } from 'react';

import { Typography } from 'domains/ui/components/_styled/Text';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { notifications } from 'utils/notifications';
import { delayedScrollingToTop } from 'utils/delayedScrolling';

const contactEmail = 'loty.proty@gmail.com';

export const PrivacyPolicyPage: FC = (): ReactElement => {
    useEffect(() => {
        delayedScrollingToTop();
    }, []);

    const handleCopyEmail = async (e: MouseEvent) => {
        e.stopPropagation();

        try {
            await navigator.clipboard.writeText(contactEmail);
            notifications.success('Скопійовано!');
        } catch (err) {
            notifications.error('Не вдалося скопіювати посилання');
        }
    };

    return (
        <div
            style={{
                display: 'grid',
                gap: 30,
                paddingTop: 80,
            }}
        >
            <Typography.H3>Політика конфіденційності</Typography.H3>

            {/* Intro */}
            <Typography.Paragraph>
                Ласкаво просимо до Політики конфіденційності сайту {window.location.origin} (надалі
                - сайт). ФОП Стрижак Олексій Андрійович (місцезнаходження: 04214, м. Київ, вул.
                Північна, 6), який здійснює управління сайтом, поважає персональні дані кожної
                особи. Викладена нижче Політика конфіденційності проінформує з приводу основних
                питань щодо захисту та обробки Ваших персональних даних.
            </Typography.Paragraph>

            <Typography.Paragraph>
                Дану Політику розроблено відповідно до вимог Загального регламенту про захист даних
                (GDPR; Regulation (EU) 2016/679), Закону України «Про захист персональних даних» та
                інших нормативних актів українського законодавства, що регламентують правові
                відносини, пов'язані зі збором, обробкою, зберіганням персональних даних, а також
                правом громадян на невтручання в особисте життя та правом на самовираження.
            </Typography.Paragraph>

            {/* 1 */}
            <Typography.H3>На кого розповсюджується ця Політика?</Typography.H3>

            <Typography.Paragraph>
                Ця Політика розповсюджується на всіх осіб, які в процесі використання сайту надали
                власні персональні дані. Персональними даними є відомості чи сукупність відомостей
                про фізичну особу, яка ідентифікована або може бути конкретно ідентифікована.
            </Typography.Paragraph>

            <Typography.Paragraph>
                Сайт НЕ призначений для дітей віком до 18 років, і ми свідомо не збираємо
                персональні дані дітей віком до 18 років без згоди батьків. Якщо Вам не виповнилося
                18 років, просимо не залишати свої персональні дані.
            </Typography.Paragraph>

            {/* 2 */}
            <Typography.H3>Яку інформацію ми збираємо?</Typography.H3>

            <ul>
                <li>
                    <Typography.Inline>ПІБ (Прізвище, ім'я, по батькові);</Typography.Inline>
                </li>
                <li>
                    <Typography.Inline>номер телефону;</Typography.Inline>
                </li>
                <li>
                    <Typography.Inline>адреса електронної пошти;</Typography.Inline>
                </li>
                <li>
                    <Typography.Inline>URL Ваших сторінок в соціальних мережах.</Typography.Inline>
                </li>
            </ul>

            <Typography.Paragraph>
                За допомогою системи Google Analytics ми збираємо статистику про відвідування сайту,
                таку як сторінки, що відвідуються, кількість переглядів сторінок, інформацію, яка
                була завантажена, домени інтернет-провайдерів та країни походження відвідувачів, а
                також адреси інших сайтів, що були відвідані до та після сайту, тощо. Жодна з цих
                дій не пов’язана з відвідувачем особисто та вимірюється тільки у сукупності.
            </Typography.Paragraph>

            <Typography.Paragraph>
                Володільцем (контролером) та розпорядником (процесором/оператором) Ваших
                персональних даних є ФОП Стрижак Олексій Андрійович.
            </Typography.Paragraph>

            {/* 3 */}
            <Typography.H3>Реп'яшки (cookie)</Typography.H3>

            <Typography.Paragraph>
                В процесі роботи сайту ми можемо збирати певну інформацію за допомогою таких
                технологій як кукі (cookie). За допомогою кукі (cookie), веб-сервер зберігає
                уподобання та налаштування на Вашому комп'ютері, мобільному телефоні або іншому
                пристрої(ях), яке потім автоматично відновлюється при наступному відвідуванні сайту,
                що робить його використання більш зручним. Ми використовуємо постійні кукі, які
                залишаються на комп'ютері користувача протягом більш тривалого періоду часу.
            </Typography.Paragraph>

            {/* 4 */}
            <Typography.H3>Навіщо ми збираємо цю інформацію?</Typography.H3>

            <Typography.Paragraph>
                Надані Вами персональні дані будуть використані в процесі підготовки до проведення
                та безпосередньо проведення на сайті благодійного онлайн-аукціону для допомоги ЗСУ,
                у тому числі:
            </Typography.Paragraph>
            <ul>
                <li>
                    <Typography.Inline>
                        для відправки сповіщень/повідомлень щодо реєстрації користувача учасником
                        аукціону, у тому числі надсилання повідомлення з одноразовим паролем для
                        авторизації на сайті;
                    </Typography.Inline>
                </li>
                <li>
                    <Typography.Inline>
                        для інформування щодо статусу лотів та результатів аукціону;
                    </Typography.Inline>
                </li>
                <li>
                    <Typography.Inline>
                        для ініціації комунікації користувачів з метою реалізації лоту шляхом
                        передачі контактної інформації переможця в аукціоні власнику лота.
                    </Typography.Inline>
                </li>
            </ul>

            <Typography.Paragraph>
                Крім того, Ваші персональні дані можуть бути використані для:
            </Typography.Paragraph>
            <ul>
                <li>
                    <Typography.Inline>
                        отримання відповідей на Ваші питання та запити;
                    </Typography.Inline>
                </li>
                <li>
                    <Typography.Inline>
                        моніторингу використання нашого сайту, у тому числі контролю загальної та
                        індивідуальної активності для управління трафіком на сайті.
                    </Typography.Inline>
                </li>
            </ul>

            {/* 5 */}
            <Typography.H3>Як ми зберігаємо та обробляємо цю інформацію?</Typography.H3>

            <Typography.Paragraph>
                Вся отримана інформація буде зберігатися та оброблятися у нашій базі даних, яка
                знаходиться в хмарному сховищі Amazon Web Services (AWS).
            </Typography.Paragraph>

            <Typography.Paragraph>
                Ми зберігаємо Ваші дані до тих пір, поки це необхідно для цілей, зазначених у цій
                Політиці конфіденційності. Іноді ми можемо зберегти Ваші дані протягом більш
                тривалого періоду, як це дозволено або вимагається законом.
            </Typography.Paragraph>

            <Typography.Paragraph>
                Ми вживаємо всіх необхідних заходів для захисту вашої інформації від
                несанкціонованого доступу, зміни, розкриття чи знищення. У той же час, звертаємо
                вашу увагу на те, що жоден з існуючих способів передачі даних не може бути абсолютно
                безпечним. Тому, незважаючи на всі вжиті заходи щодо забезпечення безпеки, ми не
                можемо гарантувати повну безпеку інформації і даних.
            </Typography.Paragraph>

            {/* 5 */}
            <Typography.H3>Яким третім особам ми надаємо дані?</Typography.H3>

            <Typography.Paragraph>
                Ваш номер телефону буде надано власнику лота, який Ви виграєте шляхом участі в
                аукціоні.
            </Typography.Paragraph>

            <Typography.Paragraph>
                Ми також можемо розкривати та/або передавати персональні дані третім особам у
                наступних випадках:
            </Typography.Paragraph>
            <ul>
                <li>
                    <Typography.Inline>
                        Отримання Вашої згоди на таке розкриття/передачу;
                    </Typography.Inline>
                </li>
                <li>
                    <Typography.Inline>
                        У випадку залучення третіх сторін, що надають технічну підтримку та/або
                        послуги щодо удосконалення сайту на умовах договору та за умови
                        нерозголошення персональних даних;
                    </Typography.Inline>
                </li>
                <li>
                    <Typography.Inline>
                        В інтересах національної безпеки, економічного добробуту та прав людини,
                        зокрема, але не обмежуючись за обґрунтованими запитами державних органів, що
                        мають право подавати заявки та отримувати такі дані;
                    </Typography.Inline>
                </li>
                <li>
                    <Typography.Inline>
                        Для здійснення правосуддя, виконання судового акта, акта іншого органу або
                        посадової особи, що підлягають виконанню відповідно до законодавства України
                        про виконавче провадження;
                    </Typography.Inline>
                </li>
                <li>
                    <Typography.Inline>
                        Для захисту прав і законних інтересів ФОП Стрижак Олексій Андрійович або
                        третіх осіб.
                    </Typography.Inline>
                </li>
            </ul>

            {/* 6 */}
            <Typography.H3>Ваші права</Typography.H3>

            <Typography.Paragraph>
                Як суб'єкту персональних даних вам належать наступні права:
            </Typography.Paragraph>
            <ul>
                <li>
                    <Typography.Inline>
                        знати про джерела збирання, місцезнаходження своїх персональних даних, мету
                        їх обробки, місцезнаходження або місце проживання (перебування) володільця
                        чи розпорядника персональних даних або дати відповідне доручення щодо
                        отримання цієї інформації уповноваженим ним особам, крім випадків,
                        встановлених законом;
                    </Typography.Inline>
                </li>
                <li>
                    <Typography.Inline>
                        отримувати інформацію про умови надання доступу до персональних даних,
                        зокрема інформацію про третіх осіб, яким передаються Ваші персональні дані;
                    </Typography.Inline>
                </li>
                <li>
                    <Typography.Inline>на доступ до своїх персональних даних;</Typography.Inline>
                </li>
                <li>
                    <Typography.Inline>
                        отримувати не пізніш як за тридцять календарних днів з дня надходження
                        запиту, крім випадків, передбачених законом, відповідь про те, чи
                        обробляються Ваші персональні дані, а також отримувати зміст таких
                        персональних даних;
                    </Typography.Inline>
                </li>
                <li>
                    <Typography.Inline>
                        пред’являти вмотивовану вимогу володільцю персональних даних із запереченням
                        проти обробки своїх персональних даних;
                    </Typography.Inline>
                </li>
                <li>
                    <Typography.Inline>
                        пред'являти вмотивовану вимогу щодо зміни або знищення своїх персональних
                        даних будь-яким володільцем та розпорядником персональних даних, якщо ці
                        дані обробляються незаконно чи є недостовірними;
                    </Typography.Inline>
                </li>
                <li>
                    <Typography.Inline>
                        на захист своїх персональних даних від незаконної обробки та випадкової
                        втрати, знищення, пошкодження у зв'язку з умисним приховуванням, ненаданням
                        чи несвоєчасним їх наданням, а також на захист від надання відомостей, що є
                        недостовірними чи ганьблять честь, гідність та ділову репутацію фізичної
                        особи;
                    </Typography.Inline>
                </li>
                <li>
                    <Typography.Inline>
                        звертатися із скаргами на обробку своїх персональних даних до Уповноваженого
                        або до суду;
                    </Typography.Inline>
                </li>
                <li>
                    <Typography.Inline>
                        застосовувати засоби правового захисту в разі порушення законодавства про
                        захист персональних даних;
                    </Typography.Inline>
                </li>
                <li>
                    <Typography.Inline>
                        вносити застереження стосовно обмеження права на обробку своїх персональних
                        даних під час надання згоди;
                    </Typography.Inline>
                </li>
                <li>
                    <Typography.Inline>
                        відкликати згоду на обробку персональних даних;
                    </Typography.Inline>
                </li>
                <li>
                    <Typography.Inline>
                        знати механізм автоматичної обробки персональних даних;
                    </Typography.Inline>
                </li>
                <li>
                    <Typography.Inline>
                        на захист від автоматизованого рішення, яке має для Вас правові наслідки;
                    </Typography.Inline>
                </li>
                <li>
                    <Typography.Inline>
                        на виправлення Ваших персональних даних та внесення доповнень до них;
                    </Typography.Inline>
                </li>
            </ul>

            <Typography.Paragraph>
                Для отримання доступу, внесення змін або доповнень до своїх персональних даних,
                відкликання згоди на обробку персональних даних, яку Ви нам надали відповідно до
                цієї Політики, або в разі наявності будь-яких зауважень, побажань або претензій щодо
                Ваших персональних даних, які обробляються нами, будь-ласка, звертайтеся за адресою:
                <Typography.Inline
                    onClick={handleCopyEmail}
                    style={{
                        cursor: 'pointer',
                        color: palette.MainYellow,
                    }}
                >
                    loty.proty@gmail.com
                </Typography.Inline>
            </Typography.Paragraph>

            {/* 7 */}
            <Typography.H3>Згода на розсилку повідомлень</Typography.H3>

            <Typography.Paragraph>
                Приймаючи умови цієї Політики, Ви автоматично погоджуєтесь на отримання від нас
                повідомлень, описаних у пункті 4 цієї Політики. Ви можете відмовитися від такої
                розсилки у письмовому повідомленні на адресу електронної пошти:{' '}
                <Typography.Inline
                    onClick={handleCopyEmail}
                    style={{
                        cursor: 'pointer',
                        color: palette.MainYellow,
                    }}
                >
                    loty.proty@gmail.com
                </Typography.Inline>
            </Typography.Paragraph>

            {/* 8 */}
            <Typography.H3>Зміни щодо Політики конфіденційності</Typography.H3>

            <Typography.Paragraph>
                Ми повідомимо Вас про будь-які зміни, опублікувавши нову Політику конфіденційності
                на цій сторінці. Ми повідомимо вас електронною поштою та/або помітним повідомленням
                на нашому сайті, перш ніж зміни набудуть чинності, і оновимо «дату набрання
                чинності» у верхній частині цієї Політики конфіденційності.
            </Typography.Paragraph>

            <Typography.Paragraph>
                Рекомендуємо періодично переглядати цю Політику конфіденційності на предмет
                будь-яких змін. Зміни до цієї Політики конфіденційності вступають в силу з моменту
                їх розміщення на цій сторінці.
            </Typography.Paragraph>

            {/* 9 */}
            <Typography.H3>Інші умови</Typography.H3>

            <Typography.Paragraph>
                У разі, якщо будь-яке положення Політики конфіденційності, включаючи будь-яку
                пропозицію, пункт або їх частину, визнається таким, що суперечить закону, або
                недійсним, то інші положення, які не суперечать закону, залишаються в силі і є
                дійсними, а будь-яке недійсне положення, або положення, яке не може бути виконано,
                вважається зміненим, виправленим в тій мірі, в якій це необхідно для забезпечення
                його дійсності і можливості виконання.
            </Typography.Paragraph>

            <Typography.H3 style={{ fontSize: 20 }}>
                Залишилися питання? З радістю відповімо на них! Поставити своє питання можна{' '}
                <a
                    href='https://forms.gle/1i8hh5aRomC8JVVLA'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    тут
                </a>
            </Typography.H3>
        </div>
    );
};
