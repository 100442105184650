import { MouseEvent } from 'react';

type ShareData = { shareLink: string; shareText: string };

export const handleMobileShare = async (
    e: MouseEvent,
    data: ShareData,
    fallback: (e: MouseEvent, data: ShareData) => void,
) => {
    e.stopPropagation();

    try {
        await navigator.share({
            title: data.shareText,
            url: data.shareLink,
        });
    } catch (err: any) {
        if (!err.toString().includes('AbortError')) {
            fallback(e, data);
        }
    }
};
