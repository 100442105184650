import { TUserContext } from 'domains/profile/hooks/useUserContext';
import { UiContext } from 'domains/ui/hooks';
import { useState } from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import { AppRoutes } from 'routes';
import { isApiError } from 'utils/guards/isApiError';
import { logger } from 'utils/logger';
import { phoneNumberTransform } from 'utils/phoneNumberTransform';
import { AuthApi } from '../api';
import {
    AuthLocalStorageKeys,
    InfoLocalStorageKeys,
    OtpAction,
    SigninDto,
    SignupDto,
} from '../types/auth';
import { parseAuthErrorMessage } from '../utils/parseAuthErrorMessage';

const OTP_RESEND_INTERVAL = 120;

export const useAuthContext = (uiContext: UiContext, userContext: TUserContext) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [accessToken, setAccessToken] = useState<string>();
    const [isAuthenticated, setAuthenticationState] = useState(false);
    const [authenticationInProgress, setAuthenticationProgressState] = useState(false);
    const [otpSendingTimestamp, setOtpSendingTimestamp] = useState<Date | null>(null);
    const [UNSAFE_devOtp, setDevOtp] = useState('');

    const { setOtpDialogState, setLoginDialogState, setSignupDialogState } = uiContext;
    const { fetchUserDataAsync } = userContext;

    const setTokenAndNavigate = (token: string, setDialogState: (state: boolean) => void) => {
        setAccessToken(token);
        localStorage.setItem(AuthLocalStorageKeys.ACCESS_TOKEN, token);

        setDialogState(false);

        fetchUserDataAsync(setAuthenticationState);
    };

    const unauthenticate = () => {
        setAuthenticationState(false);
        localStorage.removeItem(AuthLocalStorageKeys.ACCESS_TOKEN);
        localStorage.removeItem(InfoLocalStorageKeys.TELEGRAM_POPUP_PAY);
        localStorage.removeItem(InfoLocalStorageKeys.TELEGRAM_POPUP_BID);
        localStorage.removeItem(InfoLocalStorageKeys.TELEGRAM_POPUP_VALUE);

        navigate(AppRoutes.APP);
        document.getElementById('root')?.scrollTo(0, 0);
    };

    const loginAsync = async (dto: SigninDto) => {
        try {
            const { data } = await AuthApi.signin(dto);

            setTokenAndNavigate(data.accessToken, setLoginDialogState);

            return data;
        } catch (err) {
            if (isApiError(err)) {
                logger.error(parseAuthErrorMessage(err.message) || 'Помилка входу', true, err);
            } else {
                logger.error('Помилка входу', true, err);
            }
        } finally {
            setAuthenticationProgressState(false);
            setOtpSendingTimestamp(null);
        }
    };

    const signupAsync = async (dto: SignupDto) => {
        try {
            const sanitizedDto: SignupDto = {
                ...dto,
                socialProfiles: ['https://loty-proty.com/'],
            };

            const { data } = await AuthApi.signup(sanitizedDto);

            setTokenAndNavigate(data.accessToken, setSignupDialogState);

            return data;
        } catch (err) {
            if (isApiError(err)) {
                logger.error(parseAuthErrorMessage(err.message) || 'Помилка реєстрації', true, err);
            } else {
                logger.error('Помилка реєстрації', true, err);
            }
        } finally {
            setAuthenticationProgressState(false);
            setOtpSendingTimestamp(null);
        }
    };

    const logoutAsync = async () => {
        try {
            unauthenticate();

            await AuthApi.logout();
        } catch (err) {
            logger.error('Помилка лоґаута', false, err);
        }
    };

    const checkUserExistsAsync = async (phoneNumber: string) => {
        const { data } = await AuthApi.checkUserExists(phoneNumber);

        return data.exists;
    };

    const sendOtpAsync = async (phoneNumber: string, userExists: boolean) => {
        const validPhoneNumber = phoneNumberTransform(phoneNumber);

        if (!validPhoneNumber) return false;

        const data = await AuthApi.sendOtp({
            phoneNumber: validPhoneNumber,

            action: userExists ? OtpAction.LOGIN : OtpAction.SIGN_UP,
        });

        if (process.env.REACT_APP_IS_DEV) {
            setDevOtp(data.data);
        }

        setOtpSendingTimestamp(new Date(Date.now() + OTP_RESEND_INTERVAL * 1000));
    };

    const handleSendOtp = async (phoneNumber: string) => {
        const validPhoneNumber = phoneNumberTransform(phoneNumber);

        if (!validPhoneNumber) return false;

        setAuthenticationProgressState(true);

        localStorage.setItem(AuthLocalStorageKeys.PHONE_NUMBER, validPhoneNumber);

        // Enabling re-send button in OTP_RESEND_INTERVAL sec
        setTimeout(() => {
            setAuthenticationProgressState(false);
        }, OTP_RESEND_INTERVAL * 1000);

        try {
            const userExists = await checkUserExistsAsync(validPhoneNumber);

            if (userExists) {
                setLoginDialogState(true);

                await sendOtpAsync(validPhoneNumber, userExists);
            } else {
                setSignupDialogState(true);
            }

            setOtpDialogState(false);
        } catch (err) {
            if (isApiError(err)) {
                logger.error(
                    parseAuthErrorMessage(err.message) || 'Помилка надсилання СМС',
                    true,
                    err,
                );
            } else {
                logger.error('Помилка надсилання СМС', true, err);
            }
        } finally {
            setAuthenticationProgressState(false);
        }
    };

    const refreshTokensAsync = async () => {
        const { data } = await AuthApi.refreshTokens();

        setAccessToken(data.accessToken);
        localStorage.setItem(AuthLocalStorageKeys.ACCESS_TOKEN, data.accessToken);

        return data.accessToken;
    };

    const refreshAndAuthenticate = async () => {
        if (authenticationInProgress) return null;

        setAuthenticationProgressState(true);

        try {
            await refreshTokensAsync();

            fetchUserDataAsync((authState) => {
                setAuthenticationState(authState);

                const savedState = location.state as Location | null;

                if (authState && savedState?.pathname) {
                    navigate(`${savedState.pathname}${savedState.search}`, {
                        state: savedState,
                    });
                }
            });
        } catch (err) {
            logger.error('Помилка оновлення токена', false, err);
            navigate(window.location.pathname, {
                replace: true,
                state: location.state,
            });
        } finally {
            setAuthenticationProgressState(false);
        }
    };

    const resendOtp = () => {
        const phoneNumber = localStorage.getItem(AuthLocalStorageKeys.PHONE_NUMBER);

        if (phoneNumber) {
            handleSendOtp(phoneNumber);
        }
    };

    return {
        accessToken,
        isAuthenticated,
        authenticationInProgress,
        otpSendingTimestamp,
        UNSAFE_devOtp,

        unauthenticate,
        loginAsync,
        signupAsync,
        logoutAsync,
        handleSendOtp,
        sendOtpAsync,
        refreshTokensAsync,
        refreshAndAuthenticate,
        resendOtp,
        clearOtpTimestamp: () => setOtpSendingTimestamp(null),

        setAuthenticationState,
    };
};

export type TAuthContext = ReturnType<typeof useAuthContext>;
