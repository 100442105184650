// Core
import React, { FC, ReactElement, useEffect } from 'react';

// MUI
import { Fade } from '@mui/material';

// Instruments
import { DefaultGridContainer } from 'domains/ui/components/_styled/DefaultGridContainer';
import { ProfileData } from './components/ProfileData';
import { Typography } from 'domains/ui/components/_styled/Text';
import { ProfileTabs } from './components/ProfileTabs';
import { delayedScrollingToTop } from 'utils/delayedScrolling';

export const ProfilePage: FC = (): ReactElement => {
    useEffect(() => {
        delayedScrollingToTop();
    }, []);

    return (
        <Fade in>
            <DefaultGridContainer
                rowGap={80}
                style={{
                    paddingTop: 80,
                }}
            >
                <Typography.H2 gridColumn='1 / -1' placeSelf='start'>
                    Профіль користувача
                </Typography.H2>

                <ProfileData gridColumn='1 / -1' />

                <ProfileTabs gridColumn='1 / -1' />
            </DefaultGridContainer>
        </Fade>
    );
};
