import { GridPlacement, gridPlacementCss } from 'domains/ui/components/_mixins/GridPlacement';
import styled from 'styled-components';

export const BannerContainer = styled.div<GridPlacement>`
    display: grid;
    place-items: center;
    /* grid-template-columns: 1fr max-content 1fr; */
    gap: 40px;

    ${gridPlacementCss}

    margin-bottom: -70px;

    & .vendor-logo {
        max-height: 130px;
        max-width: 95vw;
    }

    & #cross {
        width: 50px;

        transform: rotate(45deg);
        filter: invert(1);
    }
`;
