// Core
import React, { FC, MouseEvent, ReactElement } from 'react';

// MUI / Styles
// IconButton, Tooltip
import { Backdrop, Dialog } from '@mui/material';
import { Typography } from 'domains/ui/components/_styled/Text';
import { TextButton } from 'domains/ui/components/TextButton';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { ButtonsGrid } from './styled';
import { Tooltip } from '@mui/material';

// Instruments
import { AdditionalLotInfo, MerchantData } from 'domains/main/types/lots.types';
import { useBreakpoints } from 'domains/ui/hooks';

type MonoBankaInstructionsProps = {
    closeMonoBankaInstructions: (e: MouseEvent) => void;
    merchant: MerchantData;
    lotName: string;
    price: number;
    id: string;
    alternativePaymentLink?: AdditionalLotInfo['alternativePaymentLink'];
};

export const MonoBankaInstructions: FC<MonoBankaInstructionsProps> = ({
    closeMonoBankaInstructions,
    lotName,
    price,
    id,
    alternativePaymentLink,
}): ReactElement => {
    const { breakpoints } = useBreakpoints();

    const redirectToPayment = async () => {
        if (alternativePaymentLink) return window.open(alternativePaymentLink, '_blank');

        if (id === 'b6e6092e-385d-4c1f-adcb-31cdad1ccf1e') {
            return window.open('https://send.monobank.ua/jar/7Qe3hGxk9Y', '_blank');
        }

        // БФ "ПЕРЕМОГА"
        if (id === '04e760a9-3478-410a-a9ae-32190d9d36b9') {
            return window.open('https://send.monobank.ua/jar/12dRKNE58p', '_blank');
        }

        // Антитіла - Джавелін
        return window.open('https://send.monobank.ua/jar/7SGN3rBNRR', '_blank');
    };

    return (
        <Backdrop open onClick={closeMonoBankaInstructions}>
            <Dialog
                open
                onClose={closeMonoBankaInstructions}
                PaperProps={{
                    style: {
                        border: `${breakpoints.sm ? 35 : 10}px solid ${palette.MainYellow}`,
                        background: palette.MainBlack,
                        maxWidth: 650,
                        margin: 0,
                    },
                }}
            >
                <Typography.Paragraph
                    style={{
                        padding: breakpoints.sm ? '20px 20px 0' : '20px 20px 0',
                        fontSize: 24,
                        textAlign: 'center',
                    }}
                >
                    Вас буде перенаправлено на сторінку поповнення МоноБанки
                    <br />
                    <br />
                    Лот -{' '}
                    <Tooltip
                        title={<Typography.Tooltip>Клікніть, щоб скопіювати</Typography.Tooltip>}
                    >
                        <Typography.Inline
                            style={{
                                fontWeight: 'bold',
                                color: palette.MainYellow,
                                fontSize: 24,
                                cursor: 'pointer',
                            }}
                        >
                            {lotName}
                        </Typography.Inline>
                    </Tooltip>
                    <br />
                    Вартість одного "квитка" -{' '}
                    <Typography.Inline
                        style={{
                            fontWeight: 'bold',
                            color: palette.MainBlue,
                            fontSize: 24,
                        }}
                    >
                        {price} грн.
                    </Typography.Inline>
                    <br />
                    <br />
                    Більше квитків - більше шансів виграти!
                    <br />
                    <br />
                    <Typography.Inline
                        style={{
                            fontWeight: 'bold',
                            color: palette.MainYellow,
                            fontSize: 20,
                            cursor: 'pointer',
                            textTransform: 'uppercase',
                        }}
                    >
                        Важливо!{' '}
                    </Typography.Inline>
                    <br />
                    <Typography.Inline
                        style={{
                            fontWeight: 'bold',
                            color: palette.MainYellow,
                            fontSize: 18,
                            cursor: 'pointer',
                        }}
                    >
                        В коментарі до платежу учасник мусить вказати контакт, за яким ми зможемо
                        зв'язатися у випадку перемоги. Квитки без контактних даних не будуть
                        опрацьовані.
                    </Typography.Inline>
                </Typography.Paragraph>
                <ButtonsGrid>
                    <TextButton
                        onClick={closeMonoBankaInstructions}
                        text={'Скасувати'}
                        hideArrow
                        // borderless
                    />
                    <TextButton
                        onClick={redirectToPayment}
                        text={'Відкрити банку'}
                        overrideHoverColor={palette.MainYellow}
                        style={{
                            gap: 10,
                        }}
                        // borderless
                    />
                </ButtonsGrid>
            </Dialog>
        </Backdrop>
    );
};
