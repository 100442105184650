import { getQueryVariable } from './getQueryVariable';

export const delayedScrollingToTop = () => {
    const id = getQueryVariable('id');
    if (!id) {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
        window.scrollTo(0, 0);
    }
};

export const delayedScrollingToHash = (hash: string) => {
    if ('scrollRestoration' in window.history) {
        window.history.scrollRestoration = 'manual';
    }

    const elem = document.getElementById(hash.slice(1));
    if (elem) {
        setTimeout(() => {
            elem.scrollIntoView();
        }, 300);
    }
};
