// Core
import React, { FC, useState } from 'react';
import { Lot } from 'domains/main/types/lots.types';

// UI
import { GridPlacement } from '../_mixins/GridPlacement';
import { GridItem } from '../_styled/GridItem';
import { AuctioCard } from 'domains/main/components';
import { AuctioShowMore } from 'domains/main/components/AuctioShowMore';
import { CircularProgress } from '@mui/material';
import { palette } from '../_mixins/_palette';
import { RoundArrowButton } from '../RoundArrowButton';
import { AuctionCarouselInnerContainer, AuctionCarouselOuterContainer } from './styled';
import { useBreakpoints } from 'domains/ui/hooks';
import { AuctioCardWithBanka } from 'domains/main/components/AuctioCardWithBanka';
import { BANKA_LOTS_IDS } from 'utils/TEMP_bankaLots';

const TRANSLATE_VALUE_STEP = 410 + 30;

type AuctionLotsCarouselProps = {
    data: Lot[] | null;
    maxLength: number | false;
} & GridPlacement;

export const AuctionLotsCarousel: FC<AuctionLotsCarouselProps> = ({
    data,
    maxLength,
    ...gridPlacement
}) => {
    const { breakpoints } = useBreakpoints();

    const [translateValue, setTranslateValue] = useState(0);

    const sliced = maxLength && data ? data.slice(0, maxLength) : data;

    const handleForwardClick = () => {
        setTranslateValue((prev) => prev + TRANSLATE_VALUE_STEP);
    };

    const handleBackwardClick = () => {
        setTranslateValue((prev) => prev - TRANSLATE_VALUE_STEP);
    };

    return (
        <GridItem {...gridPlacement} style={{ width: '100%', position: 'relative' }}>
            <AuctionCarouselOuterContainer>
                <AuctionCarouselInnerContainer
                    style={{
                        transform: `translateX(-${translateValue}px)`,
                    }}
                >
                    {sliced ? (
                        <>
                            {sliced.map((el, index) =>
                                BANKA_LOTS_IDS.includes(el.id) || el.type === 'LOTTERY' ? (
                                    <AuctioCardWithBanka
                                        key={el.id}
                                        gridColumn={`${1 + index * 4} / ${5 + index * 4}`}
                                        gridRow={2}
                                        background={index % 2 === 0 ? 'blue' : 'yellow'}
                                        isOwner={false}
                                        isDesktop
                                        {...el}
                                    />
                                ) : (
                                    <AuctioCard
                                        key={el.id}
                                        gridColumn={`${1 + index * 4} / ${5 + index * 4}`}
                                        gridRow={2}
                                        background={index % 2 === 0 ? 'blue' : 'yellow'}
                                        isOwner={false}
                                        isDesktop
                                        {...el}
                                    />
                                ),
                            )}
                            <AuctioShowMore
                                gridColumn={`${1 + sliced.length * 4} / ${5 + sliced.length * 4}`}
                                gridRow={2}
                                placeSelf='start'
                            />
                            {/* <div
                                style={{
                                    minWidth: 300,
                                    maxWidth: 410,
                                    width: breakpoints.sm ? '100%' : '300px',
                                }}
                            /> */}
                        </>
                    ) : (
                        <CircularProgress
                            sx={{
                                gridColumn: 2,
                                gridRow: 2,
                                placeSelf: 'center',
                                color: palette.MainYellow,
                            }}
                        />
                    )}
                </AuctionCarouselInnerContainer>
            </AuctionCarouselOuterContainer>

            {breakpoints.lg && sliced && sliced.length > 2 && (
                <>
                    {translateValue > 0 && (
                        <RoundArrowButton
                            onClick={handleBackwardClick}
                            rotation={180}
                            gridColumn={1}
                            gridRow={2}
                            style={{
                                position: 'absolute',
                                left: 0,
                                top: '50%',

                                opacity: 0.9,
                                transform: 'translate(-50%, -50%)',
                            }}
                        />
                    )}

                    {translateValue < sliced.length * TRANSLATE_VALUE_STEP && (
                        <RoundArrowButton
                            onClick={handleForwardClick}
                            gridColumn={12}
                            gridRow={2}
                            style={{
                                position: 'absolute',
                                left: '100%',
                                top: '50%',

                                opacity: 0.9,
                                transform: 'translate(-50%, -50%)',
                            }}
                        />
                    )}
                </>
            )}
        </GridItem>
    );
};
