import styled from 'styled-components';
import { GridPlacement, gridPlacementCss } from '../_mixins/GridPlacement';
import { ButtonText, DarkButtonText } from '../_mixins/TextStyles';
import { palette } from '../_mixins/_palette';

type Props = {
    borderless?: boolean;
    dark?: boolean;
    overrideFocusColor?: string;
} & GridPlacement;

export const InputContainer = styled.input<Props>`
    ${gridPlacementCss}

    height: 55px;

    background: transparent;

    border: none;
    border-top: 1px solid transparent;
    border-bottom: 1px solid
        ${(p) => (p.borderless ? 'transparent' : p.dark ? palette.MainBlack : palette.MainWhite)};

    ${(p) => (p.dark ? DarkButtonText : ButtonText)}

    &:hover {
        border-bottom: 1px solid
            ${(p) =>
                p.borderless
                    ? 'transparent'
                    : p.overrideFocusColor
                    ? p.overrideFocusColor
                    : p.dark
                    ? palette.MainBlack
                    : palette.MainYellow};

        ${(p) => (p.overrideFocusColor ? `color: ${p.overrideFocusColor};` : '')}
    }

    &:focus {
        border-bottom: 1px solid
            ${(p) =>
                p.borderless
                    ? 'transparent'
                    : p.overrideFocusColor
                    ? p.overrideFocusColor
                    : p.dark
                    ? palette.MainBlack
                    : palette.MainYellow};

        ${(p) => (p.overrideFocusColor ? `color: ${p.overrideFocusColor};` : '')}
    }

    &:disabled {
        border-bottom: 1px solid ${(p) => (p.borderless ? 'transparent' : palette.DisabledGrey)};
    }

    &::placeholder {
        color: ${palette.DisabledGrey};
    }

    transition: all 300ms linear;
`;
