// Core
import { Typography } from 'domains/ui/components/_styled/Text';
import React, { FC, ReactElement, useEffect } from 'react';

import terms from './terms.json';
import { delayedScrollingToTop } from 'utils/delayedScrolling';

export const TermsOfUsePage: FC = (): ReactElement => {
    useEffect(() => {
        delayedScrollingToTop();
    }, []);

    return (
        <div
            style={{
                paddingTop: 80,
            }}
        >
            <Typography.H3>Глосарій</Typography.H3>
            <br />
            {Object.entries(terms.glossary).map(([key, value]) => (
                <Typography.Paragraph
                    key={key}
                    style={{
                        marginBottom: 30,
                    }}
                >
                    <Typography.Inline
                        style={{
                            fontWeight: 'bold',
                        }}
                    >
                        {key}
                    </Typography.Inline>{' '}
                    - {value}
                </Typography.Paragraph>
            ))}

            <br />
            <br />
            <Typography.H3>Загальні правила Платформи</Typography.H3>
            <br />
            <ol>
                {terms.generalRules.map((el) => (
                    <li
                        key={el}
                        style={{
                            marginBottom: 30,
                        }}
                    >
                        <Typography.Inline key={el}>{el}</Typography.Inline>
                    </li>
                ))}
            </ol>

            <br />
            <br />
            <Typography.H3>Правила виставлення лотів</Typography.H3>
            <br />
            <ol>
                {terms.sellerRules.map((el) => (
                    <li
                        key={el}
                        style={{
                            marginBottom: 30,
                        }}
                    >
                        <Typography.Inline key={el}>{el}</Typography.Inline>
                    </li>
                ))}
            </ol>

            <br />
            <br />
            <Typography.H3>Правила участі Покупця в аукціоні</Typography.H3>
            <br />
            <ol>
                {terms.buyerRules.map((el) => (
                    <li
                        key={el}
                        style={{
                            marginBottom: 30,
                        }}
                    >
                        <Typography.Inline key={el}>{el}</Typography.Inline>
                    </li>
                ))}
            </ol>
        </div>
    );
};
