import { api } from 'api/api';
import { AxiosResponse } from 'axios';
import { Lot } from 'domains/main/types/lots.types';
import { User } from '../types/profile.types';

export const UserApi = {
    // GETs
    fetchUserData: async (): Promise<AxiosResponse<User>> => await api.get('/users/me'),
};

export const UserLotsApi = {
    // GETs
    fetchUserLots: async (): Promise<AxiosResponse<Lot[]>> => await api.get('/lots/my'),

    fetchUserBids: async (): Promise<AxiosResponse<Lot[]>> => await api.get('/lots/bid'),

    fetchUserWins: async (): Promise<AxiosResponse<Lot[]>> => await api.get('/lots/won'),

    fetchLotWinner: async (id: string): Promise<AxiosResponse<User>> =>
        await api.get(`/lots/${id}/winner`),

    // POSTs
    placeBid: async (id: string, amount: number): Promise<AxiosResponse> =>
        await api.post(`/lots/${id}/bids/place`, { amount }),
};
