// Core
import React, { FC, MouseEvent, ReactElement, useContext } from 'react';

// MUI / Styles
// IconButton, Tooltip
import { Backdrop, Dialog } from '@mui/material';
import { Typography } from 'domains/ui/components/_styled/Text';
import { TextButton } from 'domains/ui/components/TextButton';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { ButtonsGrid } from './styled';
import { Tooltip } from '@mui/material';

// Instruments
import { AdditionalLotInfo, MerchantData } from 'domains/main/types/lots.types';
import { useBreakpoints } from 'domains/ui/hooks';
import { UserContext } from 'domains/profile/context/user.context';
import { notifications } from 'utils/notifications';

type PaymentInstructionsProps = {
    closePaymentInstructions: (e: MouseEvent) => void;
    merchant: MerchantData;
    lotName: string;
    price: number;
    alternativePaymentLink?: AdditionalLotInfo['alternativePaymentLink'];
};

export const PaymentInstructions: FC<PaymentInstructionsProps> = ({
    closePaymentInstructions,
    merchant,
    lotName,
    price,
    alternativePaymentLink,
}): ReactElement => {
    const { breakpoints } = useBreakpoints();
    const { userData } = useContext(UserContext);

    const redirectToPayment = async () => {
        if (alternativePaymentLink) return window.open(alternativePaymentLink, '_blank');

        switch (merchant.id) {
            //  Ch.IT
            case 'b001a107-2ce9-4ab6-a224-0dac01abd057':
                return window.open('https://go.koloua.com/chernihiv-it', '_blank');

            // Army sos
            case '7294a64b-696f-4568-9680-460ad5c68023':
                return window.open('https://armysos.com.ua/uk/vnesok/', '_blank');

            // Antytila
            case 'ef7e9751-fcd4-4d1c-bc2e-2462a46214bf':
                return window.open('https://send.monobank.ua/9wFQ4PMZcg', '_blank');

            // Zgraya
            case '3ec3dea2-c2ec-4143-a9d7-5af8b1eae8b7':
                return window.open('https://send.monobank.ua/A16u5KNrMT', '_blank');

            // UWVM
            case 'e2009967-d81f-44c9-a80d-e4f36b3269a7':
                return window.open('https://www.uwvm.org.ua/?page_id=3192', '_blank');

            // BEZMEZH
            case 'ee8082d6-f3df-4b3a-ae03-7281ff34535d':
                return window.open('https://send.monobank.ua/jar/2HoRQ4Wtqa', '_blank');

            // Modna Shtora
            case '029e260f-8268-49c7-9d52-713ace2370c5':
                return window.open('https://send.monobank.ua/jar/317K5YxmcD', '_blank');

            // BF Rij
            case '919c42d3-f542-43d3-80dd-810f125ef4e7':
                return window.open('https://send.monobank.ua/jar/5uE8svR4Ym', '_blank');

            // Лоти Проти
            case 'f9cc73c8-ee33-4725-ab96-266a628c664f':
                return window.open('https://send.monobank.ua/jar/7SGN3rBNRR', '_blank');

            // Власвія
            case 'a7abad46-1671-4eca-bf03-3852f1b365be':
                return window.open('https://send.monobank.ua/jar/6UavGsKWCx', '_blank');

            // Valtech
            case '5175f016-504c-4a07-a8f8-f4c73fbba55a':
                return window.open('https://send.monobank.ua/jar/8e59zTLFeM', '_blank');

            default:
                return null;
        }
    };

    const handleCopy = async (e: MouseEvent) => {
        e.stopPropagation();

        try {
            await navigator.clipboard.writeText(lotName);
            notifications.success('Скопійовано!');
        } catch (err) {
            console.log(err);
            notifications.error('Не вдалося скопіювати');
        }
    };

    return (
        <Backdrop open onClick={closePaymentInstructions}>
            <Dialog
                open
                onClose={closePaymentInstructions}
                PaperProps={{
                    style: {
                        border: `${breakpoints.sm ? 35 : 10}px solid ${palette.MainYellow}`,
                        background: palette.MainBlack,
                        maxWidth: 650,
                        margin: 0,
                    },
                }}
            >
                <Typography.Paragraph
                    style={{
                        padding: breakpoints.sm ? '40px 40px 0' : '20px 20px 0',
                        fontSize: 24,
                        textAlign: 'center',
                    }}
                >
                    Вас буде перенаправлено на сторінку отримувача платежу для вибору способу
                    оплати.
                    <br />
                    <br />
                    Лот -{' '}
                    <Tooltip
                        title={<Typography.Tooltip>Клікніть, щоб скопіювати</Typography.Tooltip>}
                    >
                        <Typography.Inline
                            style={{
                                fontWeight: 'bold',
                                color: palette.MainYellow,
                                fontSize: 24,
                                cursor: 'pointer',
                            }}
                            onClick={handleCopy}
                        >
                            {lotName}
                        </Typography.Inline>
                    </Tooltip>
                    <br />
                    Сума до сплати -{' '}
                    <Typography.Inline
                        style={{
                            fontWeight: 'bold',
                            color: palette.MainBlue,
                            fontSize: 24,
                        }}
                    >
                        {price} грн.
                    </Typography.Inline>
                    <br />
                    <br />
                    Після оплати просимо надіслати підтвердження платежу,
                    <br />
                    <Typography.Inline
                        style={{
                            fontWeight: 'bold',
                            color: palette.MainBlue,
                            fontSize: 28,
                            cursor: 'pointer',
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            window.open(
                                `https://docs.google.com/forms/d/e/1FAIpQLSeutNcgdqgT97d7S6qKhFsZ8kNDj7U5t62jHw7fUQGtFxpRdQ/viewform?usp=pp_url&entry.1004553605=${userData?.phoneNumber}&entry.370413701=${lotName}`,
                                '_blank',
                            );
                        }}
                    >
                        заповнивши цю форму
                    </Typography.Inline>
                </Typography.Paragraph>
                <ButtonsGrid>
                    <TextButton
                        onClick={closePaymentInstructions}
                        text={'Скасувати'}
                        hideArrow
                        borderless
                    />
                    <TextButton
                        onClick={redirectToPayment}
                        text={'До оплати'}
                        overrideHoverColor={palette.MainYellow}
                        hideArrow
                        borderless
                    />
                </ButtonsGrid>
            </Dialog>
        </Backdrop>
    );
};
