// Core
import React, { FC, ReactElement } from 'react';

// UI
import { DefaultGridContainer } from 'domains/ui/components/_styled/DefaultGridContainer';

// Instruments
import bg from 'theme/assets/png/landing_greeting-bg.png';
import { Typography } from 'domains/ui/components/_styled/Text';
import { TextButton } from 'domains/ui/components/TextButton';
import { useButtonActions, useBreakpoints } from 'domains/ui/hooks';
import { GridItem } from 'domains/ui/components/_styled/GridItem';
import { useTypewriter } from 'react-simple-typewriter';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { Tooltip } from '@mui/material';

export const Greeting: FC = (): ReactElement => {
    const { breakpoints } = useBreakpoints();
    const { handleCreateNewLot, openAuction } = useButtonActions();

    const { text } = useTypewriter({
        words: ['Сволоти', 'Русоти', 'Мерзоти'],
        loop: false,
        typeSpeed: 80,
        deleteSpeed: 100,
        delaySpeed: 3000,
    });

    return (
        <DefaultGridContainer
            rowGap={20}
            style={{
                paddingTop: breakpoints.lg ? 150 : 100,
            }}
        >
            <Typography.Paragraph
                gridColumn={{
                    xl: '1 / span 5',
                    lg: '1 / span 4',
                    xs: '1 / -1',
                }}
                gridRow={{ sm: 1, xs: 2 }}
                placeSelf='start'
                style={{
                    textAlign: 'left',
                    color: palette.MainYellow,
                    fontWeight: 'bold',
                    fontSize: breakpoints.md ? 24 : 18,
                    paddingBottom: 20,
                }}
            >
                Онлайн-аукціон для допомоги ЗСУ
            </Typography.Paragraph>

            <Typography.Paragraph
                gridColumn={{
                    xl: '1 / span 5',
                    lg: '1 / span 4',
                    sm: '1 / span 5',
                    xs: '1 / -1',
                }}
                gridRow={{ sm: 2, xs: 3 }}
                placeSelf='start'
                style={{
                    position: 'relative',
                    top: -20,

                    textAlign: 'left',
                    fontWeight: 'bold',
                    fontSize: breakpoints.lg ? 100 : breakpoints.sm ? 80 : 60,
                    lineHeight: '120%',
                    width: '110%',
                    overflowX: 'visible',

                    whiteSpace: breakpoints.lg ? 'nowrap' : 'normal',
                }}
            >
                Лоти Проти
                <br />
                {text.length ? text : '\xa0'}
                <span
                    style={{
                        display: 'inline-block',
                        marginLeft: 10,
                        height: '1em',
                        width: 15,
                        background: palette.MainYellow,
                        transform: 'translateY(20%)',
                        animation: 'blink 1500ms linear infinite',
                    }}
                />
            </Typography.Paragraph>

            <GridItem
                gridRow={{
                    lg: '2 / 6',
                    sm: '3 / 6',
                    xs: '1',
                }}
                gridColumn={{
                    lg: 'span 4 / -1',
                    sm: 'span 3 / -1',
                    xs: '1 / -1',
                }}
                placeSelf={{
                    md: 'start end',
                    sm: 'center',
                    xs: 'start',
                }}
                style={{ width: breakpoints.sm ? undefined : '100%' }}
            >
                <img
                    src={bg}
                    alt=''
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                />
            </GridItem>

            <Typography.Paragraph
                gridRow={{
                    sm: 3,
                    xs: 4,
                }}
                gridColumn={{
                    xl: '1 / span 6',
                    lg: '1 / span 4',
                    sm: '1 / span 3',
                    xs: '1 / -1',
                }}
                style={{
                    textAlign: 'left',
                    padding: '20px 0 20px 0',
                }}
                placeSelf='start'
            >
                Розміщуй та купуй товари чи послуги. Виручені на аукціоні кошти одразу
                відправляються на закриття потреб бойових підрозділів.
            </Typography.Paragraph>

            <TextButton
                onClick={handleCreateNewLot}
                text='Розмістити лот'
                placeSelf={{
                    sm: 'start',
                    xs: 'center',
                }}
                gridRow={{ sm: 4, xs: 5 }}
                gridColumn={{
                    lg: '1 / 4',
                    sm: '1 / 3',
                    xs: '1 / -1',
                }}
                style={{
                    width: breakpoints.sm ? undefined : '100%',
                }}
            />
            <TextButton
                onClick={openAuction}
                text='Зробити ставку'
                placeSelf={{
                    xl: 'start',
                    lg: 'start end',
                    sm: 'start',
                    xs: 'center',
                }}
                gridRow={{
                    lg: 4,
                    sm: 5,
                    xs: 6,
                }}
                gridColumn={{
                    xl: '5 / 9',
                    lg: '4 / 6',
                    sm: '1 / 3',
                    xs: '1 / -1',
                }}
                style={{
                    width: breakpoints.sm ? undefined : '100%',
                }}
            />
        </DefaultGridContainer>
    );
};
