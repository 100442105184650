// Core
import React, { FC, ReactElement } from 'react';
import { User } from 'domains/profile/types/profile.types';
import { Typography } from 'domains/ui/components/_styled/Text';

export const AuctioCardLotWinner: FC<User> = ({ firstName, phoneNumber }): ReactElement => {
    return (
        <>
            <Typography.ProductCardInfo>Ім'я</Typography.ProductCardInfo>
            <Typography.ProductCardInfo
                placeSelf='center end'
                style={{
                    whiteSpace: 'nowrap',
                }}
            >
                {firstName}
            </Typography.ProductCardInfo>

            <Typography.ProductCardInfo>Номер телефону</Typography.ProductCardInfo>
            <Typography.ProductCardInfo placeSelf='center end'>
                {phoneNumber}
            </Typography.ProductCardInfo>
        </>
    );
};
