import styled from 'styled-components';
import { GridPlacement, gridPlacementCss } from '../_mixins/GridPlacement';
import { palette } from '../_mixins/_palette';

export const ShareDialogContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    max-width: 650px;
    padding: 30px 20px;

    background: ${palette.MainWhite};

    border: 35px solid ${palette.MainYellow};
`;

export const CopyLinkField = styled.div<GridPlacement>`
    ${gridPlacementCss}
    cursor: pointer;

    display: grid;
    grid-template-columns: 1fr max-content;
    place-items: center start;
    gap: 35px;

    width: 100%;

    border-top: 1px solid transparent;
    border-bottom: 1px solid ${palette.MainYellow};
`;
