// Core
import { GridPlacement } from 'domains/ui/components/_mixins/GridPlacement';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { DefaultGridContainer } from 'domains/ui/components/_styled/DefaultGridContainer';
import { Typography } from 'domains/ui/components/_styled/Text';
import { useBreakpoints } from 'domains/ui/hooks';
import React, { FC, ReactElement } from 'react';
import { useTypewriter } from 'react-simple-typewriter';
import { Container } from '@mui/material';

// Assets
import logo from '../../assets/logo_uku.png';
import { SubContainer } from './styles';

export const CharityHeader: FC<GridPlacement> = (props): ReactElement => {
    const {
        variables: { minPaddingX, maxAppContainerWidth },
        isMobile,
        breakpoints,
        breakpoints: { lg, sm, md },
    } = useBreakpoints();

    const { text } = useTypewriter({
        words: ['Сволоти', 'Русоти', 'Мерзоти'],
        loop: false,
        typeSpeed: 80,
        deleteSpeed: 100,
        delaySpeed: 3000,
    });
    return (
        <Container
            sx={{
                position: 'absolute',
                top: 90,
                left: 0,
            }}
            style={{
                background: palette.MainWhite,
            }}
            maxWidth={false}
        >
            <SubContainer
                style={{
                    maxWidth: `${maxAppContainerWidth}px`,
                    padding: sm ? `0px ${minPaddingX}px` : undefined,
                    gap: md ? '40px' : '0',

                    gridTemplateRows: md ? '' : '1fr 1.5fr 0.75fr',
                    justifyContent: md ? '' : 'center',
                }}
            >
                <Typography.Paragraph className='title'>
                    Благодійний аукціон від:
                </Typography.Paragraph>
                <a href='' target='_blank' rel='noopener noreferrer'>
                    <img src={logo} alt='' />
                </a>

                <Typography.Paragraph
                    gridColumn={{ lg: 'unset', md: 'unset', xs: '1 / -1' }}
                    gridRow={{ lg: 'unset', md: 'unset', xs: '3' }}
                    placeSelf='start'
                    style={{
                        position: 'relative',
                        top: breakpoints.lg ? 25 : breakpoints.sm ? 12 : '0',
                        left: breakpoints.lg ? '' : breakpoints.xs ? '8%' : '',
                        textAlign: 'left',
                        fontWeight: 'bold',
                        fontSize: breakpoints.lg ? 24 : breakpoints.sm ? 22 : 20,
                        lineHeight: '120%',
                        width: breakpoints.lg ? '300px' : breakpoints.md ? '140px' : '100%',
                        overflowX: 'visible',
                        whiteSpace: breakpoints.lg ? 'nowrap' : 'normal',
                    }}
                >
                    Лоти Проти {text.length ? text : '\xa0'}
                    <span
                        style={{
                            display: 'inline-block',
                            marginLeft: 10,
                            height: '1em',
                            width: 15,
                            background: palette.MainBlack,
                            transform: 'translateY(20%)',
                            animation: 'blink 1500ms linear infinite',
                        }}
                    />
                </Typography.Paragraph>
            </SubContainer>
        </Container>
    );
};
