// Core
import React, { FC, ReactElement, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

// Styles
import 'theme/scss/index.scss';

// MUI
import { ThemeProvider as MaterialThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Context
import { LotsContext } from 'domains/main/context/lots.context';
import { useLotsContext } from 'domains/main/hooks/useLotsContext';
import { UserLotsContext } from 'domains/profile/context/profileLots.context';
import { useUserLotsContext } from 'domains/profile/hooks/useUserLotsContext';
import { AuthContext } from 'domains/auth/context/auth.context';
import { useAuthContext } from 'domains/auth/hooks/useAuthContext';
import { UiContext } from 'domains/ui/context/ui.context';
import { useUiContext, useBreakpoints } from 'domains/ui/hooks';
import { UserContext } from 'domains/profile/context/user.context';
import { useUserContext } from 'domains/profile/hooks/useUserContext';

// Components
import { Layout } from 'domains/ui/components';
import { MainPage } from 'domains/main';
import { ProfilePage } from 'domains/profile';
import { LandingPage } from 'domains/landing';

// Instruments
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { AxiosInterceptor } from 'api/api';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Settings } from 'luxon';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { AppRoutes } from 'routes';
import { RedirectPage } from 'domains/ui/pages/Redirect';
import { TermsOfUsePage } from 'domains/ui/pages/TermsOfUse';
import { PrivacyPolicyPage } from 'domains/ui/pages/PrivacyPolicy';
import { ConnectTelegamDialog } from 'domains/profile/components/ConnectTelegamDialog';
import { VendorMain } from 'domains/vendors/pages/VendorMain';
import { TeamPage } from 'domains/ui/pages/Team';
import { CardDetails } from 'domains/main/components/CardDetails';
import { getQueryVariable } from 'utils/getQueryVariable';
import { CharityAuctionMain } from 'domains/charity/pages/CharityAuctionMain';

Settings.defaultLocale = 'uk-UA';

const theme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: palette.Bg,
            paper: palette.Bg,
        },
        primary: {
            main: palette.MainYellow,
        },
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
    },
});

export const App: FC = (): ReactElement => {
    const uiContextData = useUiContext();
    const userContextData = useUserContext();
    const lotsContextData = useLotsContext();
    const userLotsContextData = useUserLotsContext();
    const authContextData = useAuthContext(uiContextData, userContextData);
    const breakpoints = useBreakpoints();

    const id = getQueryVariable('id');

    const {
        breakpoints: { lg },
    } = useBreakpoints();

    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            authContextData.refreshAndAuthenticate();
        }

        // eslint-disable-next-line
    }, []);

    return (
        <UiContext.Provider value={uiContextData}>
            <UserContext.Provider value={userContextData}>
                <AuthContext.Provider value={authContextData}>
                    <LotsContext.Provider value={lotsContextData}>
                        <UserLotsContext.Provider value={userLotsContextData}>
                            <AxiosInterceptor>
                                <StyledComponentsThemeProvider theme={breakpoints}>
                                    <MaterialThemeProvider theme={theme}>
                                        <CssBaseline />
                                        <ToastContainer
                                            autoClose={8000}
                                            progressStyle={{ height: '2px' }}
                                        />
                                        <ConnectTelegamDialog />
                                        <Layout>
                                            <Routes>
                                                <Route
                                                    path={AppRoutes.APP}
                                                    element={<MainPage />}
                                                />
                                                <Route
                                                    path={`${AppRoutes.LANDING}/r/:id`}
                                                    element={
                                                        <Navigate
                                                            to={`${AppRoutes.APP}?id=${
                                                                window.location.pathname
                                                                    .split('/')
                                                                    .reverse()[0]
                                                            }`}
                                                            replace
                                                        />
                                                    }
                                                />

                                                <Route
                                                    path={`${AppRoutes.APP}/:vendorName`}
                                                    element={<VendorMain />}
                                                />
                                                {authContextData.isAuthenticated && (
                                                    <Route
                                                        path={AppRoutes.PROFILE}
                                                        element={<ProfilePage />}
                                                    />
                                                )}
                                                <Route
                                                    path={AppRoutes.LANDING}
                                                    element={<LandingPage />}
                                                />
                                                <Route
                                                    path={AppRoutes.TERMS_OF_USE}
                                                    element={<TermsOfUsePage />}
                                                />
                                                <Route
                                                    path={AppRoutes.PRIVACY_POLICY}
                                                    element={<PrivacyPolicyPage />}
                                                />
                                                <Route
                                                    path={AppRoutes.TEAM}
                                                    element={<TeamPage />}
                                                />

                                                <Route
                                                    path={AppRoutes.CHARITY}
                                                    element={<CharityAuctionMain />}
                                                />

                                                <Route
                                                    path='*'
                                                    element={
                                                        <RedirectPage to={AppRoutes.APP} replace />
                                                    }
                                                />
                                            </Routes>
                                        </Layout>
                                        {id && (
                                            <CardDetails
                                                id={id}
                                                background='yellow'
                                                isDesktop={lg}
                                            />
                                        )}
                                    </MaterialThemeProvider>
                                </StyledComponentsThemeProvider>
                            </AxiosInterceptor>
                        </UserLotsContext.Provider>
                    </LotsContext.Provider>
                </AuthContext.Provider>
            </UserContext.Provider>
        </UiContext.Provider>
    );
};
