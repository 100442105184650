import styled from 'styled-components';
import { palette } from '../_mixins/_palette';

export const FooterOuterContainer = styled.div`
    display: grid;
    place-items: center;

    width: 100vw;
    padding: 80px 0 60px;

    background: ${palette.MainBlue};
    background-image: linear-gradient(
        179.36deg,
        rgba(0, 0, 0, 0) -5.33%,
        rgba(0, 0, 0, 0.6) 96.05%
    );

    overflow: hidden;
`;

export const FooterInnerContainer = styled.div`
    width: 100%;
    max-width: ${(p) => `${p.theme.variables.maxAppContainerWidth}px`};
    padding: ${(p) => `0 ${p.theme.variables.minPaddingX}px`};
    margin: 0 auto;
`;

export const FooterLink = styled.a`
    cursor: pointer;

    display: grid;
    grid-template-columns: repeat(2, max-content);
    place-items: center start;
    gap: 20px;

    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.05em;
    font-style: normal;
    font-weight: 400;
    color: ${palette.MainWhite};

    transition: all 300ms linear;

    &:hover {
        color: ${palette.MainYellow};
    }
`;
