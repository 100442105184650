// Core
import React, { ChangeEvent, FC, ReactElement, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { Typography } from 'domains/ui/components/_styled/Text';
import { Link } from 'react-router-dom';
import { AppRoutes } from 'routes';
import { useBreakpoints } from 'domains/ui/hooks';

type SignupCheckboxesProps = {
    containerRef: React.MutableRefObject<HTMLDivElement | undefined>;
    setCheckboxesChecked: (bool: boolean) => void;
};

export const SignupCheckboxes: FC<SignupCheckboxesProps> = ({
    containerRef,
    setCheckboxesChecked,
}): ReactElement => {
    const [checkBoxes, updateCheckboxes] = useState({
        sms: false,
        policies: false,
        personalInfo: false,
    });
    const { breakpoints } = useBreakpoints();

    const toggleCheck = (e: ChangeEvent, name: keyof typeof checkBoxes) => {
        e.stopPropagation();
        updateCheckboxes((prev) => ({
            ...prev,
            [name]: !prev[name],
        }));

        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        setCheckboxesChecked(
            (Object.keys(checkBoxes) as (keyof typeof checkBoxes)[]).every((el) => checkBoxes[el]),
        );
    }, [checkBoxes, setCheckboxesChecked]);

    return (
        <FormGroup
            sx={{
                padding: '20px 0 10px',
                mx: breakpoints.sm ? '40px' : '20px',
                gap: '5px',
            }}
        >
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checkBoxes.sms}
                        onChange={(e) => toggleCheck(e, 'sms')}
                        style={{ transform: 'translate(0px, 7px)', padding: '0px 9px 0' }}
                    />
                }
                label="Я погоджуюся на отримання СМС (обов'язково)"
                style={{ alignItems: 'baseline' }}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checkBoxes.policies}
                        onChange={(e) => toggleCheck(e, 'policies')}
                        style={{ transform: 'translate(0px, 7px)', padding: '0px 9px 0' }}
                    />
                }
                style={{ alignItems: 'baseline' }}
                label={
                    <Typography.Span
                        style={{
                            fontSize: 16,
                        }}
                    >
                        Я ознайомився/ознайомилася і погоджуюся з{' '}
                        <Link to={AppRoutes.TERMS_OF_USE} target='_blank'>
                            Правилами платформи
                        </Link>{' '}
                        та{' '}
                        <Link to={AppRoutes.PRIVACY_POLICY} target='_blank'>
                            Політикою конфіденційності
                        </Link>{' '}
                        (обов'язково)
                    </Typography.Span>
                }
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checkBoxes.personalInfo}
                        onChange={(e) => toggleCheck(e, 'personalInfo')}
                        style={{ transform: 'translate(0px, 7px)', padding: '0px 9px 0' }}
                    />
                }
                style={{ alignItems: 'baseline' }}
                label="Я погоджуюся на обробку персональних даних (обов'язково)"
            />
        </FormGroup>
    );
};
