// Core
import React, { FC, ReactElement } from 'react';

type YoutubeVideoProps = {
    link: string;
};

export const YoutubeVideo: FC<YoutubeVideoProps> = ({ link }): ReactElement => {
    return (
        <span
            style={{
                position: 'relative',
                display: 'block',
                paddingBottom: '56.25%',
                overflow: 'hidden',
            }}
        >
            <iframe
                src={link}
                title='YouTube video player'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen
                style={{
                    overflow: 'hidden',
                    border: 0,
                    alignSelf: 'center',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                }}
            ></iframe>
        </span>
    );
};
