// Core
import React, { FC, ReactElement, useContext } from 'react';

// UI
import { DefaultGridContainer } from 'domains/ui/components/_styled/DefaultGridContainer';
import { Typography } from 'domains/ui/components/_styled/Text';
import { TextButton } from 'domains/ui/components/TextButton';
import { GridItem } from 'domains/ui/components/_styled/GridItem';

// Assets
import flag from 'theme/assets/svg/flag.svg';
import { Accordion } from 'domains/ui/components/Accordion';

// Instruments
import { useBreakpoints, useButtonActions } from 'domains/ui/hooks';
import { AuthContext } from 'domains/auth/context/auth.context';
import { UiContext } from 'domains/ui/context/ui.context';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { LandingMarkupIds } from '../types';

export const WhatToDo: FC = (): ReactElement => {
    const { handleCreateNewLot, openAuction, handleOpenFeedback } = useButtonActions();
    const { isAuthenticated } = useContext(AuthContext);
    const { setOtpDialogState } = useContext(UiContext);
    const { breakpoints } = useBreakpoints();

    const styleButton = breakpoints.md ? { marginTop: 80 } : { marginTop: 80, width: '100%' };
    const checkAuthAndOpenAuction = () => {
        if (isAuthenticated) {
            openAuction();
        } else {
            setOtpDialogState(true);
        }
    };

    const checkAuthAndOpenProfile = () => {
        if (isAuthenticated) {
            openAuction();
        } else {
            setOtpDialogState(true);
        }
    };

    return (
        <DefaultGridContainer
            rowGap={breakpoints.lg ? 80 : 50}
            id={LandingMarkupIds.WHAT_TO_DO}
            style={{ paddingTop: breakpoints.lg ? '150px' : '120px' }}
        >
            <Typography.H2 gridColumn='1 / -1' placeSelf='start'>
                найпоширеніші запитання
            </Typography.H2>

            <GridItem
                gridColumn={{
                    lg: '1 / span 7',
                    xs: '1 / -1',
                }}
                gridRow={{
                    lg: 2,
                    xs: 3,
                }}
                style={{ width: '100%', height: '100%' }}
            >
                <Accordion
                    data={[
                        {
                            title: 'Як розмістити лот?',
                            content: (
                                <>
                                    <Typography.Caption>
                                        Спершу треба створити профіль користувача на платформі.
                                        Після цього, натиснувши на кнопку “розмістити лот” ви
                                        зможете заповнити заявку на розміщення лота.
                                        <br />
                                        <br />
                                        Наші модератори розглянуть її, зв‘яжуться з вами для
                                        підтвердження лота і розмістять його на платформі. Ми
                                        розміщуємо найрізноманітніші пропозиції: від дитячого
                                        малюнку до сувенірних гільз із фронту чи уроків з
                                        програмування!
                                        <br />
                                        <br /> Коли ваш лот буде придбано, платформа зв‘яже вас із
                                        покупцем.
                                    </Typography.Caption>

                                    <TextButton
                                        onClick={handleCreateNewLot}
                                        text='розмістити лот'
                                        borderless
                                        style={styleButton}
                                    />
                                </>
                            ),
                        },
                        {
                            title: 'як зробити ставку?',
                            content: (
                                <>
                                    <Typography.Caption>
                                        Придбавши будь-який із наших лотів, Ви також допомагаєте
                                        армії, адже кошти, виручені з аукціону, перераховуються
                                        напряму на потреби бойових підрозділів!
                                        <br />
                                        <br />
                                        Для участі в аукціоні потрібно:
                                        <br />
                                        <br />
                                        1){' '}
                                        <span
                                            onClick={checkAuthAndOpenAuction}
                                            style={{
                                                cursor: 'pointer',
                                                color: palette.MainYellow,
                                            }}
                                        >
                                            Зареєструватися
                                        </span>{' '}
                                        на платформі
                                        <br />
                                        <br />
                                        2) Обрати лот до смаку і зробити ставку
                                        <br />
                                        <br />
                                        3) Якщо по закінченню аукціону Ваша ставка буде найвищою,
                                        Вам надійде повідомлення і лот можна буде оплатити зі
                                        сторінки "Виграні лоти"{' '}
                                        <span
                                            onClick={checkAuthAndOpenProfile}
                                            style={{
                                                cursor: 'pointer',
                                                color: palette.MainYellow,
                                            }}
                                        >
                                            в профілі
                                        </span>
                                        <br />
                                        <br />
                                        4) Після підтвердження оплати, ми надамо Ваш контакт
                                        продавцеві для реалізації лоту!
                                    </Typography.Caption>

                                    <TextButton
                                        onClick={openAuction}
                                        borderless
                                        text='Переглянути лоти'
                                        style={styleButton}
                                    />
                                </>
                            ),
                        },
                        {
                            title: 'Як оплатити лот?',
                            content: (
                                <>
                                    <Typography.Caption>
                                        Після отримання повідомлення про те, що ви стали переможцем
                                        в аукціоні, потрібно зайти на сторінку вашого профілю на
                                        сайті Лоти Проти та перейти на вкладку “виграні лоти“.
                                        <br />
                                        <br />
                                        Лот, який потребує оплати, буде знаходитися там, а картка
                                        лоту міститиме кнопку, яка перенаправить вас на сторінку
                                        оплати.
                                    </Typography.Caption>
                                </>
                            ),
                        },
                        {
                            title: 'Що робити після оплати лота?',
                            content: (
                                <>
                                    <Typography.Caption>
                                        Після того, як переможець аукціону здійснив оплату лота і ми
                                        отримали підтвердження платежу, ми надаємо власникові лота
                                        контактні дані переможця і 24 години на те, щоб зв‘язатися
                                        та узгодити всі деталі доставлення. <br />
                                        <br /> Як покупцеві, вам потрібно тільки оплатити та
                                        зачекати, доки продавець зв‘яжеться з вами.
                                        <br />
                                        <br /> У випадку, якщо цього не сталося — радимо написати
                                        нам у службу підтримки, щоб ми могли допомогти розв‘язати цю
                                        проблему.
                                    </Typography.Caption>

                                    <TextButton
                                        onClick={handleOpenFeedback}
                                        text='Служба підтримки'
                                        borderless
                                        style={styleButton}
                                    />
                                </>
                            ),
                        },
                        {
                            title: 'Як зв‘язатися з покупцем мого лота?',
                            content: (
                                <>
                                    <Typography.Caption>
                                        Після того, як лот був оплачений і ми отримали підтвердження
                                        оплати, вам буде надана контактна інформація переможця —
                                        ім‘я та номер телефону.
                                        <br />
                                        <br /> Після цього у вас є 24 години на те, щоб зв’язатися з
                                        покупцем і узгодити деталі передачі лота (час, дату, спосіб
                                        доставлення тощо).
                                    </Typography.Caption>
                                </>
                            ),
                        },
                    ]}
                />
            </GridItem>

            <Typography.Paragraph
                gridColumn={breakpoints.lg ? '9 / -1' : '1 / -1'}
                style={
                    breakpoints.lg
                        ? { fontSize: 24, gridRow: 2 }
                        : breakpoints.md
                        ? {
                              gridColumn: '1 / -1',
                              display: 'grid',
                              gridAutoFlow: 'column',
                              gridTemplateColumns: '1fr 2fr',
                              fontSize: 18,
                              paddingBottom: 10,
                          }
                        : {
                              gridColumn: '1 / -1',
                              display: 'grid',
                              fontSize: 16,
                              paddingBottom: 10,
                              gap: 30,
                          }
                }
            >
                У цьому розділі ми зібрали найпоширеніші запитання і намагалися дати на них
                розгорнуту відповідь.
                <br />
                <br />
                Тут ви знайдете інструкцію до того як працює “Лоти Проти”
                <img
                    src={flag}
                    alt=''
                    style={breakpoints.lg ? { marginTop: 60 } : { gridColumn: 1 }}
                />
            </Typography.Paragraph>
        </DefaultGridContainer>
    );
};
