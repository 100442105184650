export enum AppRoutes {
    LANDING = '/',
    PROFILE = '/profile',
    ITEM = '/item',
    APP = '/app',
    ARCHIVE = '/app?status=archive',

    TERMS_OF_USE = '/terms',
    PRIVACY_POLICY = '/policy',
    TEAM = '/team',
    CHARITY = '/ucu',
}
