// Core
import React, { FC, ReactElement, useEffect } from 'react';
import { Navigate, NavigateProps } from 'react-router-dom';

//  Instruments
import { CircularProgress } from '@mui/material';
import { GridItem } from 'domains/ui/components/_styled/GridItem';
import { RedirectPageContainer } from './styled';
import { useRedirects } from 'domains/ui/hooks';
import { delayedScrollingToTop } from 'utils/delayedScrolling';

type RedirectPageProps = {} & NavigateProps;

export const RedirectPage: FC<RedirectPageProps> = (props): ReactElement => {
    useEffect(() => {
        delayedScrollingToTop();
    }, []);

    console.log(props);

    const redirectPreparationFinished = useRedirects();

    return (
        <RedirectPageContainer>
            {redirectPreparationFinished ? (
                <Navigate {...props} />
            ) : (
                <GridItem
                    placeSelf='center'
                    gridColumn='1 / -1'
                    gridRow='1 / -1'
                    style={{
                        display: 'grid',
                        placeItems: 'center',
                        gap: 50,
                        maxWidth: 600,
                        textAlign: 'center',
                    }}
                >
                    <CircularProgress />
                </GridItem>
            )}
        </RedirectPageContainer>
    );
};
