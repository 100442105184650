// Core
import React, { FC, ReactElement } from 'react';

// UI
import { DefaultGridContainer } from 'domains/ui/components/_styled/DefaultGridContainer';
import { Typography } from 'domains/ui/components/_styled/Text';

// Assets
import { Socials } from 'domains/ui/components/Socials';
import { GridItem } from 'domains/ui/components/_styled/GridItem';
import { LandingMarkupIds } from '../types';
import { useBreakpoints } from 'domains/ui/hooks';

export const LandingSocials: FC = (): ReactElement => {
    const { breakpoints } = useBreakpoints();

    return (
        <DefaultGridContainer
            id={LandingMarkupIds.SOCIALS}
            style={{ paddingTop: breakpoints.lg ? '150px' : '120px' }}
        >
            <Typography.H2
                gridColumn='1 / -1'
                placeSelf='start'
                style={{
                    paddingBottom: breakpoints.lg ? 50 : 20,
                }}
            >
                дізнавайтесь про нові лоти
            </Typography.H2>

            <GridItem gridColumn='1 / -1'>
                <Socials />
            </GridItem>
        </DefaultGridContainer>
    );
};
