// Core
import React, { FC, ReactElement, useContext, useEffect } from 'react';

// Instruments
import { TextField } from '@mui/material';
import { SignupDto } from 'domains/auth/types/auth';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { useCountdown } from 'domains/ui/hooks';
import { AuthContext } from 'domains/auth/context/auth.context';
import { Typography } from 'domains/ui/components/_styled/Text';

type OtpStepProps = {
    otpCode: string;
    phoneNumber: string;
    handleSignupDataChange: <K extends keyof SignupDto>(key: K, value: SignupDto[K]) => void;
};

export const OtpStep: FC<OtpStepProps> = ({
    otpCode,
    phoneNumber,
    handleSignupDataChange,
}): ReactElement => {
    const { otpSendingTimestamp, clearOtpTimestamp, resendOtp, sendOtpAsync } =
        useContext(AuthContext);

    const { seconds } = useCountdown(otpSendingTimestamp, clearOtpTimestamp);

    useEffect(() => {
        sendOtpAsync(phoneNumber, false);
        // eslint-disable-next-line
    }, []);

    const otpHelperText =
        seconds > 0 ? (
            `Повторне надсилання смс буде доступне за ${seconds} сек.`
        ) : !otpSendingTimestamp ? (
            <Typography.Span
                onClick={resendOtp}
                style={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    color: palette.MainYellow,
                    fontSize: 16,
                }}
            >
                Надіслати повторно
            </Typography.Span>
        ) : (
            ''
        );

    return (
        <TextField
            margin='dense'
            label={`Код з СМС *`}
            helperText={otpHelperText}
            FormHelperTextProps={{
                style: {
                    color: palette.MainBlue,
                    fontSize: 16,
                },
            }}
            type={'tel'}
            fullWidth
            variant='standard'
            inputProps={{
                inputMode: 'numeric',

                style: {
                    fontFamily: 'Montserrat',
                },
            }}
            value={otpCode}
            onChange={(e) => handleSignupDataChange('otpCode', e.target.value)}
        />
    );
};
