// Core
import React, { CSSProperties, FC, ReactElement, useState } from 'react';

// Instruments
import { Arrow } from '../Arrow';
import { GridPlacement } from '../_mixins/GridPlacement';
import { palette } from '../_mixins/_palette';
import { RoundButtonContainer } from './styled';

type TextButtonProps = {
    onClick: () => void;
    variant?: 'small' | 'big';
    hoverColor?: 'blue' | 'yellow';
    disabled?: boolean;
    rotation?: number;
    style?: CSSProperties;
} & GridPlacement;

export const RoundArrowButton: FC<TextButtonProps> = ({
    onClick,
    disabled,
    style,
    rotation = 0,
    variant = 'big',
    hoverColor = 'blue',
    ...gridPlacement
}): ReactElement => {
    const [entered, setEnteredState] = useState(false);

    const handleMouseEnter = () => {
        setEnteredState(true);
    };

    const handleMouseLeave = () => {
        setEnteredState(false);
    };

    const getArrowHoverColor = () =>
        hoverColor === 'blue' ? palette.MainWhite : palette.MainBlack;

    return (
        <RoundButtonContainer
            disabled={disabled}
            onClick={onClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
                ...(style || {}),
                transform: `${style?.transform || ''} rotate(${rotation}deg)`,
            }}
            variant={variant}
            hoverColor={hoverColor}
            {...gridPlacement}
        >
            <Arrow
                color={
                    disabled
                        ? palette.DisabledGrey
                        : entered
                        ? getArrowHoverColor()
                        : palette.MainBlack
                }
                style={{
                    width: '100%',
                }}
            />
        </RoundButtonContainer>
    );
};
