import styled from 'styled-components';
import { GridPlacement, gridPlacementCss } from '../_mixins/GridPlacement';
import { palette } from '../_mixins/_palette';

type Props = {
    color: 'blue' | 'yellow';
    padding: 'left' | 'right';
} & GridPlacement;

export const ImageWithPadding = styled.img<Props>`
    ${gridPlacementCss}

    ${(p) => (p.padding === 'left' ? 'padding-left: 80px;' : 'padding-right: 80px;')}

    background: ${(p) => (p.color === 'blue' ? palette.MainBlue : palette.MainYellow)};

    width: 100%;
    max-height: 100%;
`;
