// Core
import React, { FC, ReactElement, useEffect } from 'react';

// MUI
import { Fade, Grid } from '@mui/material';

import { useBreakpoints } from 'domains/ui/hooks';
import { delayedScrollingToTop } from 'utils/delayedScrolling';
import { teamData } from './data';
import { Typography } from 'domains/ui/components/_styled/Text';

export const TeamPage: FC = (): ReactElement => {
    const { breakpoints } = useBreakpoints();

    useEffect(() => {
        delayedScrollingToTop();
    }, []);

    return (
        <Fade in>
            <Grid container style={{ marginBottom: breakpoints.lg ? '150px' : '120px' }}>
                {teamData.map((item, id) => (
                    <div key={id}>
                        <Typography.H2 gridColumn='1 / -1' placeSelf='start'>
                            {item.title}
                        </Typography.H2>
                        <div>
                            {item.team.map((teamx, idx) => (
                                <div></div>
                            ))}
                        </div>
                    </div>
                ))}
            </Grid>
        </Fade>
    );
};
