// Core
import React, { FC, ReactElement } from 'react';

// MUI
import { Backdrop, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Carousel from 'react-material-ui-carousel';

// Instruments
import { palette } from 'domains/ui/components/_mixins/_palette';
import { ImageType } from 'domains/main/types/lots.types';

type DetailsImagePopupProps = {
    close: () => void;
    imageId: number;
    images: ImageType[];
};

export const DetailsImagePopup: FC<DetailsImagePopupProps> = ({
    close,
    images,
    imageId,
}): ReactElement => {
    return (
        <Backdrop
            open
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: '#000000e9',
                zIndex: 1,
            }}
            onClick={close}
        >
            <div
                style={{
                    position: 'relative',
                    background: palette.MainBlue,
                    padding: 35,
                    display: 'flex',
                }}
            >
                <Carousel
                    className='carousele-big'
                    navButtonsAlwaysVisible={images.length > 1}
                    interval={5000}
                    navButtonsWrapperProps={{ style: { opacity: 0.5 } }}
                    navButtonsAlwaysInvisible={images.length === 1}
                    indicators={images.length > 1}
                    index={imageId}
                >
                    {images.map((item) => (
                        <img
                            alt=''
                            src={item.url}
                            style={{
                                maxWidth: 800,
                                maxHeight: 600,
                                minWidth: 400,
                                minHeight: 400,
                                width: '100%',
                                height: '100%',
                            }}
                        />
                    ))}
                </Carousel>
                <Tooltip title='Закрити'>
                    <CloseIcon
                        style={{
                            color: palette.MainWhite,
                            width: 35,
                            height: 35,
                            position: 'absolute',
                            cursor: 'pointer',
                            top: 5,
                            right: 5,
                        }}
                    />
                </Tooltip>
            </div>
        </Backdrop>
    );
};
