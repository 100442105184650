import { GridPlacement, gridPlacementCss } from 'domains/ui/components/_mixins/GridPlacement';
import { palette } from 'domains/ui/components/_mixins/_palette';
import styled from 'styled-components';

type Props = {
    background: 'yellow' | 'blue';
} & GridPlacement;

export const AuctionCardContainer = styled.div<Props>`
    ${gridPlacementCss}

    cursor: pointer;

    display: grid;
    grid-template-rows: ${(p) => (p.theme.breakpoints.md ? '350px 290px' : '255px 266px')};

    min-width: ${(p) =>
        p.theme.breakpoints.md ? `${Math.min(window.innerWidth - 10, 410)}px` : ''};
    max-width: ${(p) =>
        p.theme.breakpoints.md ? `${Math.min(window.innerWidth - 10, 410)}px` : ''};
    width: ${(p) => (p.theme.breakpoints.md ? '100%' : '330px')};
    // height: 100%;
    height: auto;
    background: ${(p) => (p.background === 'yellow' ? palette.MainYellow : palette.MainBlue)};
`;

export const AuctionCardData = styled.div`
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: 1fr repeat(3, max-content) 1fr;
    gap: 10px;
    width: ${(p) => (p.theme.breakpoints.md ? '100%' : '330px')};

    padding: 20px;

    background: ${palette.MainWhite};
`;
