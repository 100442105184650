// Core
import React, { FC, ReactElement } from 'react';

// Instruments
import { TextField } from '@mui/material';
import { SignupDto } from 'domains/auth/types/auth';
import { palette } from 'domains/ui/components/_mixins/_palette';

type SocialsStepProps = {
    socialProfiles: string[];
    handleSignupDataChange: <K extends keyof SignupDto>(key: K, value: SignupDto[K]) => void;
};

export const SocialsStep: FC<SocialsStepProps> = ({
    socialProfiles,
    handleSignupDataChange,
}): ReactElement => {
    const isUrlValid = (urls: string[]) => {
        return !!urls.find(
            (item) => item.length < 2 || !item.includes('.') || !item.startsWith('http'),
        );
    };
    return (
        <TextField
            margin='dense'
            label={`(опціонально) Посилання на соціальні мережі`}
            // helperText={'Twitter/Facebook/Instagram розділяючи комою'}
            FormHelperTextProps={{
                style: {
                    color: palette.MainBlue,
                    fontSize: 16,
                },
            }}
            type={'text'}
            style={{ marginTop: 0 }}
            fullWidth
            error={isUrlValid(socialProfiles)}
            variant='standard'
            value={socialProfiles}
            onChange={(e) => handleSignupDataChange('socialProfiles', e.target.value.split(','))}
        />
    );
};
