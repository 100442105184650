// Core
import React, { FC, ReactElement, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// MUI
import { AuctioCard } from 'domains/main/components';
import { GridPlacement } from 'domains/ui/components/_mixins/GridPlacement';
import { UserLotsContext } from 'domains/profile/context/profileLots.context';
import { ProfileTabsContainer, Tab } from './styled';
import { CircularProgress } from '@mui/material';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { AuctioCreateLot } from 'domains/main/components/AuctioCreateLot';
import { GridItem } from 'domains/ui/components/_styled/GridItem';
import { AuctionGridContainer } from 'domains/ui/components/_styled/AuctionGrid';
import { notifications } from 'utils/notifications';
import { useBreakpoints } from 'domains/ui/hooks';
import { LotsContext } from 'domains/main/context/lots.context';
import { LotStatus } from 'domains/main/types/lots.types';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { AuctioDoBid } from 'domains/main/components/AuctioDoBid';
import flag from 'theme/assets/svg/flag.svg';
import { Typography } from 'domains/ui/components/_styled/Text';

const tabs: Record<TabsType, string> = {
    'my-lots': 'Мої лоти',
    'actual-bids': 'Активні ставки',
    'won-lots': 'Виграні лоти',
};

type TabsType = 'my-lots' | 'actual-bids' | 'won-lots';
type LocationState = {
    'payment-success'?: string;
    tab?: number;
};

export const ProfileTabs: FC<GridPlacement> = (props): ReactElement => {
    const location = useLocation();
    const [currentTab, setCurrentTab] = useState<TabsType>('my-lots');
    const { breakpoints } = useBreakpoints();

    const styleItem = breakpoints.lg
        ? { margin: '20px 0 50px' }
        : { margin: '15px 0 40px', fontSize: 16 };

    const styleItemTitle = breakpoints.lg ? {} : { fontSize: 18 };

    const [expand, setExpand] = useState(false);

    const { updateLotStatusLocally } = useContext(LotsContext);

    const {
        breakpoints: { sm },
    } = useBreakpoints();
    const { userLots, userBids, userWins, getUserLotsAsync, getUserBidsAsync, getUserWinsAsync } =
        useContext(UserLotsContext);

    const arrayOfitems = { 'my-lots': userLots, 'actual-bids': userBids, 'won-lots': userWins };

    const items = arrayOfitems[currentTab];

    useEffect(() => {
        getUserLotsAsync();
        getUserBidsAsync();
        getUserWinsAsync();
    }, []);

    useEffect(() => {
        const lotId = (location.state as LocationState)?.['payment-success'];

        const { tab } = (location.state as LocationState) || {};

        if (lotId) {
            setCurrentTab('won-lots');

            updateLotStatusLocally(lotId, LotStatus.PAID);

            notifications.success('Оплата успішна!');
        } else if (tab) {
            switch (tab) {
                case 0:
                    return setCurrentTab('my-lots');
                case 1:
                    return setCurrentTab('actual-bids');
                default:
                    return setCurrentTab('won-lots');
            }
        }
    }, [location, updateLotStatusLocally]);

    return (
        <ProfileTabsContainer {...props}>
            {sm ? (
                <GridItem
                    gridColumn='1 / -1'
                    gridRow={1}
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr',
                        columnGap: 30,
                    }}
                >
                    {Object.entries(tabs).map(([key, value]) => (
                        <Tab
                            key={value}
                            text={`${value} (${arrayOfitems[key as TabsType]?.length || 0})`}
                            active={currentTab === key}
                            onClick={() => setCurrentTab(key as TabsType)}
                            hideArrow
                            borderless={currentTab !== key}
                            style={
                                currentTab === key
                                    ? { borderBottom: `1px solid ${palette.MainYellow}` }
                                    : {}
                            }
                        />
                    ))}
                </GridItem>
            ) : (
                <GridItem
                    gridColumn='1 / -1'
                    gridRow={1}
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr ',
                        columnGap: 30,
                        overflow: 'hidden',
                        height: expand ? 171 : 57,
                        position: 'relative',
                        transition: 'all 300ms ease-in-out',
                    }}
                >
                    {Object.entries(tabs)
                        .filter(([key, _]) => key === currentTab)
                        .map(([key, value]) => (
                            <Tab
                                key={value}
                                text={`${value} (${arrayOfitems[key as TabsType]?.length || 0})`}
                                active={currentTab === key}
                                onClick={() => {
                                    setCurrentTab(key as TabsType);
                                    setExpand(false);
                                }}
                                hideArrow
                                style={
                                    currentTab === key
                                        ? { borderBottom: `1px solid ${palette.MainYellow}` }
                                        : {}
                                }
                            />
                        ))}
                    {Object.entries(tabs)
                        .filter(([key, _]) => key !== currentTab)
                        .map(([key, value]) => (
                            <Tab
                                key={value}
                                text={`${value} (${arrayOfitems[key as TabsType]?.length || 0})`}
                                active={currentTab === key}
                                onClick={() => {
                                    setCurrentTab(key as TabsType);
                                    setExpand(false);
                                }}
                                hideArrow
                                style={
                                    currentTab === key
                                        ? { borderBottom: `1px solid ${palette.MainYellow}` }
                                        : {}
                                }
                            />
                        ))}
                    <ArrowBackIosNewIcon
                        fontSize='large'
                        style={{
                            ...{
                                color: palette.MainYellow,
                                position: 'absolute',
                                right: 5,
                                top: 10,
                            },
                            ...{ transform: expand ? 'rotate(90deg)' : 'rotate(270deg)' },
                        }}
                        onClick={() => setExpand(!expand)}
                    />
                </GridItem>
            )}
            {currentTab === 'won-lots' && (
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '30px' }}>
                    <GridItem
                        gridColumn={{
                            md: '1 / 3',
                            xs: '1 / -1',
                        }}
                        style={{ width: '90%' }}
                    >
                        <Typography.H3 style={styleItemTitle}>1. оплата лоту</Typography.H3>
                        <Typography.Caption style={styleItem}>
                            Натисніть кнопку “Оплатити лот” в правому нижньому кутку картки
                            виграного лоту. Вас буде перенаправлено на сторінку оплати. Після
                            переведення коштів, лот буде відображатись як оплачений у вашому
                            персональному кабінеті.
                        </Typography.Caption>

                        <Typography.H3 style={styleItemTitle}>2. Координація процесу</Typography.H3>
                        <Typography.Caption style={styleItem}>
                            Після проведення оплати, координатор платформи “ЛотиПроти” передасть
                            ваші контакти автору лоту та проконтролює, щоб він/вона зв’язались із
                            вами за допомогою того методу, який ви вказали при реєстрації.
                        </Typography.Caption>

                        <Typography.H3 style={styleItemTitle}>3. отримання лоту</Typography.H3>
                        <Typography.Caption style={styleItem}>
                            З’ясувавши усі деталі щодо отримання виграного лоту, насолоджуйтесь
                            своїм виграшем та внеском у перемогу.
                        </Typography.Caption>
                    </GridItem>
                    <GridItem
                        gridColumn={{
                            md: '3',
                            xs: '1 / -1',
                        }}
                        gridRow={{
                            xs: '1',
                        }}
                        style={
                            breakpoints.md
                                ? {}
                                : breakpoints.sm
                                ? {
                                      display: 'grid',
                                      gridAutoFlow: 'column',
                                      alignItems: 'center',
                                      marginBottom: '20px',
                                      gridTemplateColumns: '1fr 3fr',
                                  }
                                : {}
                        }
                    >
                        <Typography.Paragraph style={{ fontSize: 24 }}>
                            Що робити, якщо ваша ставка стала виграшною?
                        </Typography.Paragraph>

                        <img
                            src={flag}
                            alt=''
                            style={breakpoints.md ? { marginTop: 60 } : { gridColumn: 1 }}
                        />
                    </GridItem>
                </div>
            )}
            <AuctionGridContainer>
                {items ? (
                    <>
                        {items.map((el, index) => (
                            <AuctioCard
                                key={el.id}
                                background={index % 2 === 0 ? 'blue' : 'yellow'}
                                isOwner={currentTab === 'my-lots'}
                                isDesktop
                                isWinner={currentTab === 'won-lots'}
                                {...el}
                            />
                        ))}
                        {currentTab === 'my-lots' ? (
                            <AuctioCreateLot placeSelf='start' />
                        ) : (
                            currentTab && (
                                <AuctioDoBid
                                    placeSelf='start'
                                    lotsCount={arrayOfitems['actual-bids']?.length || 0}
                                />
                            )
                        )}
                        <div
                            style={{
                                width: 365,
                            }}
                        />
                    </>
                ) : (
                    <CircularProgress
                        sx={{
                            gridColumn: '1 / -1',
                            gridRow: '3 / -1',
                            placeSelf: 'center',
                            color: palette.MainYellow,
                        }}
                    />
                )}
            </AuctionGridContainer>
        </ProfileTabsContainer>
    );
};
