// Core
import React, { FC, ReactElement, useContext, useEffect, useState } from 'react';

// UI
import { CircularProgress, Fade } from '@mui/material';

// Ctx
import { LotsContext } from './context/lots.context';
import { useLocation } from 'react-router-dom';
import { UserContext } from 'domains/profile/context/user.context';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { DefaultGridContainer } from 'domains/ui/components/_styled/DefaultGridContainer';
import { Typography } from 'domains/ui/components/_styled/Text';
import { AuctioCreateLot } from './components/AuctioCreateLot';
import { AuctioCard } from './components';
import { useBreakpoints } from 'domains/ui/hooks';
import { AuctionGridContainer } from 'domains/ui/components/_styled/AuctionGrid';
import { delayedScrollingToTop } from 'utils/delayedScrolling';
import { Lot, LotStatus } from './types/lots.types';
import { Filters } from './components/Filters';
import { useArchiveLots } from './hooks/useArchiveLots';
import { ResultsCounters } from './components/ResultsCounters';
import { AuctioCardWithBanka } from './components/AuctioCardWithBanka';
import { BANKA_LOTS_IDS } from 'utils/TEMP_bankaLots';
import InfiniteScroll from 'react-infinite-scroll-component';

export const MainPage: FC = (): ReactElement => {
    const {
        breakpoints: { lg, md, sm },
    } = useBreakpoints();

    useEffect(() => {
        delayedScrollingToTop();
    }, []);

    const {
        lots,
        showMoreAvailable: showMoreActual,
        updatePage: updatePageActual,
    } = useContext(LotsContext);

    const {
        archiveLots,
        showMoreAvailable: showMoreArchive,
        updatePage: updatePageArchive,
        setIsArchivePage,
    } = useArchiveLots();

    const location = useLocation();

    const [actualLots, setActualLots] = useState<Lot[] | null>(null);
    const [statusActualLots, setstatusActualLots] = useState<boolean | null>(null);
    const { userData } = useContext(UserContext);

    useEffect(() => {
        if (location.search.includes('status=')) {
            const status = location.search.replace('?status=', '');
            if (status === 'archive') {
                setstatusActualLots(false);
                setIsArchivePage(true);
            }
        } else {
            setstatusActualLots(true);
        }

        // eslint-disable-next-line
    }, [location.search]);

    useEffect(() => {
        if (statusActualLots !== null) {
            setActualLots(statusActualLots ? lots : archiveLots);
        }
    }, [statusActualLots, lots, archiveLots]);
    const showMoreStatus = statusActualLots ? showMoreActual : showMoreArchive;

    const loadingMoreLots = () => {
        if (statusActualLots) {
            updatePageActual();
        } else {
            updatePageArchive();
        }
    };

    return (
        <Fade in>
            <DefaultGridContainer
                rowGap={50}
                style={{
                    paddingTop: 80,
                }}
            >
                <div
                    style={
                        md
                            ? {
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                  gridColumn: ' 1 / -1',
                                  position: 'relative',
                              }
                            : {
                                  display: 'grid',
                                  width: '100%',
                                  gridColumn: ' 1 / -1',
                                  position: 'relative',
                                  //   gridTemplateRows: '1fr 1fr',
                                  gridTemplateRows: '1fr',
                                  //   gap: '25px',
                              }
                    }
                >
                    <Typography.H2 gridColumn='1 / -1' placeSelf='start'>
                        {statusActualLots ? 'Актуальні' : 'Виграні'} лоти
                    </Typography.H2>
                    {statusActualLots && <Filters />}
                </div>
                {!statusActualLots && (
                    <ResultsCounters
                        styles={
                            md
                                ? {
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      width: '100%',
                                      gridColumn: ' 1 / -1',
                                      position: 'relative',
                                  }
                                : {
                                      display: 'grid',
                                      width: '100%',
                                      gridColumn: ' 1 / -1',
                                      position: 'relative',
                                      gridTemplateRows: '1fr',
                                      gap: 30,
                                  }
                        }
                    />
                )}

                <AuctionGridContainer
                    gridColumn='1 / -1'
                    style={
                        (actualLots && actualLots?.length === 0) || !actualLots
                            ? { justifyContent: 'center' }
                            : showMoreStatus
                            ? { paddingBottom: 0 }
                            : {}
                    }
                >
                    <InfiniteScroll
                        style={
                            (actualLots && actualLots?.length === 0) || !actualLots
                                ? {
                                      justifyContent: 'center',
                                      display: 'flex',
                                      flexWrap: 'wrap',
                                      rowGap: '30px',
                                      overflow: 'hidden',
                                  }
                                : showMoreStatus
                                ? {
                                      paddingBottom: 0,
                                      display: 'flex',
                                      flexWrap: 'wrap',
                                      justifyContent: md ? 'space-between' : 'center',
                                      rowGap: '30px',
                                      overflow: 'hidden',
                                  }
                                : {
                                      display: 'flex',
                                      flexWrap: 'wrap',
                                      justifyContent: md ? 'space-between' : 'center',
                                      rowGap: '30px',
                                      overflow: 'hidden',
                                  }
                        }
                        dataLength={actualLots ? actualLots.length : 0}
                        next={loadingMoreLots}
                        hasMore={showMoreStatus}
                        loader={
                            <CircularProgress
                                sx={{
                                    gridColumn: '1 / -1',
                                    gridRow: '1 / -1',
                                    placeSelf: 'center',
                                    color: palette.MainYellow,
                                }}
                            />
                        }
                    >
                        {actualLots && actualLots?.length > 0 ? (
                            <>
                                {actualLots.map((el, index) =>
                                    BANKA_LOTS_IDS.includes(el.id) || el.type === 'LOTTERY' ? (
                                        <AuctioCardWithBanka
                                            key={el.id}
                                            background={index % 2 === 0 ? 'blue' : 'yellow'}
                                            isOwner={userData?.id === el.creator.id}
                                            isDesktop={lg}
                                            {...el}
                                            status={
                                                statusActualLots ? el.status : LotStatus.FINISHED
                                            }
                                        />
                                    ) : (
                                        <AuctioCard
                                            key={el.id}
                                            background={index % 2 === 0 ? 'blue' : 'yellow'}
                                            isOwner={userData?.id === el.creator.id}
                                            isDesktop={lg}
                                            {...el}
                                            status={
                                                statusActualLots ? el.status : LotStatus.FINISHED
                                            }
                                        />
                                    ),
                                )}
                                <AuctioCreateLot placeSelf='start' />

                                <div
                                    style={{
                                        minWidth: 365,
                                        maxWidth: 410,
                                        width: sm ? '100%' : '365px',
                                    }}
                                />
                            </>
                        ) : (
                            <CircularProgress
                                sx={{
                                    gridColumn: '1 / -1',
                                    gridRow: '1 / -1',
                                    placeSelf: 'center',
                                    color: palette.MainYellow,
                                }}
                            />
                        )}
                    </InfiniteScroll>
                </AuctionGridContainer>
            </DefaultGridContainer>
        </Fade>
    );
};
