// Core
import React, { FC, ReactElement } from 'react';
import { GridPlacement } from 'domains/ui/components/_mixins/GridPlacement';

// Assets
import logo from '../../../assets/pivnich/logo.png';
import { BannerContainer } from './styles';

export const PivnichBanner: FC<GridPlacement> = (props): ReactElement => {
    return (
        <BannerContainer {...props}>
            <a href='https://instagram.com/vpivnich' target='_blank' rel='noopener noreferrer'>
                <img
                    src={logo}
                    alt=''
                    className='vendor-logo'
                    style={{
                        filter: 'invert(1)',
                    }}
                />
            </a>
        </BannerContainer>
    );
};
