// Core
import React, { FC, ReactElement } from 'react';

// UI
import { DefaultGridContainer } from 'domains/ui/components/_styled/DefaultGridContainer';
import { Typography } from 'domains/ui/components/_styled/Text';

// Assets
import flag from 'theme/assets/svg/flag.svg';
import { GridItem } from 'domains/ui/components/_styled/GridItem';
import { LandingMarkupIds } from '../types';
import { useBreakpoints } from 'domains/ui/hooks';

export const HowItWorks: FC = (): ReactElement => {
    const { breakpoints } = useBreakpoints();

    const styleItem = breakpoints.lg
        ? { margin: '20px 0 50px' }
        : { margin: '15px 0 40px', fontSize: 16 };

    const styleItemTitle = breakpoints.lg ? {} : { fontSize: 18 };

    return (
        <DefaultGridContainer
            id={LandingMarkupIds.HOW_IT_WORKS}
            style={{ rowGap: 0, paddingTop: breakpoints.lg ? '150px' : '120px' }}
        >
            <Typography.H2
                gridColumn='1 / -1'
                placeSelf='start'
                style={{
                    paddingBottom: breakpoints.lg ? 80 : 50,
                }}
            >
                про аукціон
            </Typography.H2>

            <Typography.Paragraph
                gridColumn={breakpoints.lg ? '1 / span 4' : '1 / -1'}
                style={
                    breakpoints.lg
                        ? { fontSize: 24 }
                        : breakpoints.md
                        ? {
                              gridColumn: '1 / -1',
                              display: 'grid',
                              gridAutoFlow: 'column',
                              gridTemplateColumns: '1fr 2fr',
                              fontSize: 18,
                              paddingBottom: 50,
                          }
                        : {
                              gridColumn: '1 / -1',
                              display: 'grid',
                              fontSize: 16,
                              paddingBottom: 50,
                              gap: 30,
                          }
                }
            >
                Кожен Ваш талант, вміння чи річ можуть наздобути нової цінності в нинішніх умовах і
                принести надвелику користь нашим захистникам.
                <br />
                <br />
                Ми контролюємо кожен платіж і гарантуємо, що кошти за Ваш лот дійдуть до отримувача.
                <br />
                <img
                    src={flag}
                    alt=''
                    style={breakpoints.lg ? { marginTop: 60 } : { gridColumn: 1 }}
                />
            </Typography.Paragraph>

            <GridItem
                gridColumn={{
                    xl: '6 / -1',
                    lg: '5 / -1',
                    xs: '1 / -1',
                }}
            >
                <Typography.H3 style={styleItemTitle}>1. Створення лоту</Typography.H3>
                <Typography.Caption style={styleItem}>
                    Розмістіть ваш лот (що завгодно, від уроку малювання, до підбитого танку). Лот
                    перевіряється та розміщується на платформі.
                </Typography.Caption>

                <Typography.H3 style={styleItemTitle}>2. Проведення аукціону</Typography.H3>
                <Typography.Caption style={styleItem}>
                    Аукціон триває від одного до семи днів, аби всі охочі могли зробити ставку.
                    Після підтвердження оплати, продавець отримає контакти покупця для відправлення
                    товару.
                </Typography.Caption>

                <Typography.H3 style={styleItemTitle}>3. Допомога ЗСУ</Typography.H3>
                <Typography.Caption style={styleItem}>
                    Наші модератори контролюють надходження кожного платежу до отримувача.
                </Typography.Caption>
            </GridItem>
        </DefaultGridContainer>
    );
};
