import { gridPlacementCss } from 'domains/ui/components/_mixins/GridPlacement';
import { palette } from 'domains/ui/components/_mixins/_palette';
import styled from 'styled-components';

export const SubContainer = styled.div`
    display: grid;
    grid-auto-flow: column;
    place-items: center;

    margin: 0 auto;
    justify-content: space-between;

    & p {
        color: ${palette.MainBlack};
    }
    & .title {
        font-weight: 700;
        font-size: 19px;
        line-height: 120%;
        text-transform: uppercase;
        letter-spacing: 0.03em;
    }
    & img {
        height: 75px;
    }
`;
