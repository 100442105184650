import styled from 'styled-components';

export const ButtonsGrid = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap-reverse;
    gap: 15px 30px;

    width: 100%;
    padding: 20px 10px;
`;
