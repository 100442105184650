// Core
import React, { CSSProperties } from 'react';

type Props = {
    color?: string;
    style?: CSSProperties;
    transitionDuration?: number | false;
};

export const Arrow = ({ color, transitionDuration = 300, style = {} }: Props) => (
    <svg
        width='49'
        height='24'
        viewBox='0 0 49 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        style={{
            transition: transitionDuration ? `all ${transitionDuration}ms linear` : undefined,
            ...style,
        }}
    >
        <path
            d='M48.9844 12L38.7187 1.30862C38.4727 1.0469 38.1055 0.937525 37.7578 1.02346C37.4102 1.1094 37.1328 1.37502 37.0352 1.71877C36.9336 2.06643 37.0273 2.43752 37.2812 2.69143L45.2539 11L2 11C1.64062 10.9961 1.30469 11.1836 1.12109 11.4961C0.941406 11.8086 0.941406 12.1914 1.12109 12.5039C1.30469 12.8164 1.64062 13.0039 2 13L45.2539 13L37.2812 21.3086C37.0273 21.5625 36.9336 21.9336 37.0352 22.2813C37.1328 22.625 37.4102 22.8906 37.7578 22.9766C38.1055 23.0625 38.4727 22.9531 38.7187 22.6914L48.9844 12Z'
            fill={color || '#ffffff'}
            style={{
                transition: transitionDuration ? `all ${transitionDuration}ms linear` : undefined,
            }}
        />
    </svg>
);
