import { InfoLocalStorageKeys } from 'domains/auth/types/auth';
import { LandingApi } from 'domains/landing/api';
import { DashboardStats } from 'domains/landing/types';
import { UserContext } from 'domains/profile/context/user.context';
import { useContext, useEffect, useState } from 'react';

export const useUiContext = () => {
    const [otpDialog, setOtpDialogState] = useState(false);
    const [loginDialog, setLoginDialogState] = useState(false);
    const [signupDialog, setSignupDialogState] = useState(false);
    const [telegramDialog, setTelegramDialogState] = useState(false);
    const [dashboardStats, setDashboardStats] = useState<DashboardStats | null>(null);
    const { userData } = useContext(UserContext);

    const getDashboardStatsAsync = async () => {
        try {
            const { data } = await LandingApi.getDashboardStats();

            setDashboardStats(data);
        } catch (err) {
            console.error(err);
            setDashboardStats({
                paidLotsCount: 0,
                paidLotsSum: 0,
                registeredUsers: 0,
            });
        }
    };

    useEffect(() => {
        getDashboardStatsAsync();
    }, []);

    const showTelegramDialog = (localStorageKey: InfoLocalStorageKeys) => {
        const telegramLinked =
            localStorage.getItem(InfoLocalStorageKeys.TELEGRAM_POPUP_VALUE) === 'true';

        if (
            !telegramLinked &&
            localStorage.getItem(localStorageKey) !== new Date().toLocaleDateString()
        ) {
            localStorage.setItem(localStorageKey, new Date().toLocaleDateString());
            setTelegramDialogState(true);
        }
    };
    return {
        otpDialog,
        loginDialog,
        signupDialog,
        dashboardStats,
        telegramDialog,

        setOtpDialogState,
        setLoginDialogState,
        setSignupDialogState,
        setTelegramDialogState,
        showTelegramDialog,
    };
};

export type UiContext = ReturnType<typeof useUiContext>;
