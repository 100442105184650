import { InfoLocalStorageKeys } from 'domains/auth/types/auth';
import { useState } from 'react';
import { logger } from 'utils/logger';
import { UserApi, UserLotsApi } from '../api';
import { User } from '../types/profile.types';

export const useUserContext = () => {
    const [userData, setUserData] = useState<User>();

    const fetchUserDataAsync = async (setAuthenticationState?: (state: boolean) => void) => {
        try {
            const { data } = await UserApi.fetchUserData();

            setUserData(data);

            // For telegram popup
            localStorage.setItem(
                InfoLocalStorageKeys.TELEGRAM_POPUP_VALUE,
                String(data.telegramLinked),
            );

            if (setAuthenticationState) {
                setAuthenticationState(true);
            }

            return data;
        } catch (error) {
            logger.error('Помилка завантаження даних користувача', true, error);
        }
    };

    const getLotWinnerDataAsync = async (id: string) => {
        try {
            const data = await UserLotsApi.fetchLotWinner(id);

            return data.data;
        } catch (error) {
            logger.error('Помилка завантаження даних переможця', true, error);
        }
    };

    return {
        userData,

        fetchUserDataAsync,
        getLotWinnerDataAsync,
    };
};

export type TUserContext = ReturnType<typeof useUserContext>;
