// Core
import { GridPlacement } from 'domains/ui/components/_mixins/GridPlacement';
import React, { FC, ReactElement } from 'react';

// Assets
import logo from '../../../assets/antytila/logo_notblack.svg';
import { BannerContainer } from './styles';

export const AntytilaBanner: FC<GridPlacement> = (props): ReactElement => {
    return (
        <BannerContainer {...props}>
            <a href='https://www.antytila.ua/pro-fond/' target='_blank' rel='noopener noreferrer'>
                <img src={logo} alt='' className='vendor-logo' />
            </a>
        </BannerContainer>
    );
};
