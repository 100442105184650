// Core
import React, { FC, ReactElement, useState } from 'react';

// Instruments
import { TextButton } from 'domains/ui/components/TextButton';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { GridItem } from 'domains/ui/components/_styled/GridItem';
import { Typography } from 'domains/ui/components/_styled/Text';
import { PivnichBanner } from '../PivnichBanner';

type AntytilaHeaderProps = {
    className?: string;
};

export const PivnichHeader: FC<AntytilaHeaderProps> = ({ className }): ReactElement => {
    const [detailsOpened, setDetailsOpenedState] = useState(false);

    return (
        <>
            <PivnichBanner gridColumn='1 / -1' />

            <Typography.H3
                gridColumn='1 / -1'
                placeSelf='center'
                style={{
                    color: palette.MainYellow,
                    fontSize: 15,
                    textAlign: 'center',
                }}
            >
                Системно, оперативно та ефективно організовуємо постачання необхідного до
                підрозділів,
                <br />
                котрі виконують військові завдання по обороні країни.
            </Typography.H3>

            <GridItem
                gridColumn='1  / -1'
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    gap: 20,
                    width: '100%',
                    marginTop: -40,
                    paddingBottom: 40,
                    borderBottom: `1px solid ${palette.MainYellow}`,
                }}
            >
                <TextButton
                    text='Про фонд '
                    onClick={() => window.open('https://www.instagram.com/vpivnich', '_blank')}
                    style={{
                        minWidth: 330,
                    }}
                />

                <TextButton
                    text='Про аукціон'
                    onClick={() => setDetailsOpenedState((prev) => !prev)}
                    style={{
                        minWidth: 330,
                    }}
                />

                <TextButton
                    text='Швидкий донат'
                    onClick={() => window.open('https://send.monobank.ua/jar/6ttreAyxFM', '_blank')}
                    style={{
                        minWidth: 330,
                    }}
                />
            </GridItem>

            <GridItem
                gridColumn='1  / -1'
                style={{
                    width: '100%',
                    borderBottom: `1px solid ${palette.MainYellow}`,
                    marginTop: -81,

                    overflow: 'hidden',

                    maxHeight: detailsOpened ? '100vh' : 0,

                    transition: 'all 0.5s ease-in-out',
                }}
            >
                <Typography.H3 style={{ fontSize: 20, marginTop: 40 }}>
                    Аукціон від "Волонтери Північ"
                </Typography.H3>
                <br />
                <Typography.Paragraph style={{ marginBottom: 40 }}>
                    "Волонтери Північ" - це група волонтерів та волонтерських організацій, котрі
                    об'єдналися для того щоб максимально ефективно та консолідовано допомагати
                    військовим.
                    <br />
                    <br />
                    Ми співпрацюємо{' '}
                    <Typography.Span style={{ color: palette.MainYellow, display: 'inline' }}>
                        напряму з ОК "Північ"
                    </Typography.Span>{' '}
                    , в підпорядкуванні якого знаходяться військові шести північних областей та
                    міста Києва. Усі ми маємо великий досвід волонтерства, та вирішили обєднатись
                    саме у співпраці з командуванням, так як лише вони володіють повною інформацією
                    щодо нагальних потреб, пріорітетів та поточної ситуації.
                    <Typography.Span
                        style={{ color: palette.MainYellow, display: 'inline' }}
                    ></Typography.Span>{' '}
                </Typography.Paragraph>
            </GridItem>
        </>
    );
};
