import { useCallback, useContext, useEffect } from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import { parse } from 'query-string';
import { AppRoutes } from 'routes';

import { AuthContext } from 'domains/auth/context/auth.context';

export const useRedirects = (): Boolean | void => {
    const location = useLocation();
    const navigate = useNavigate();

    const { isAuthenticated } = useContext(AuthContext);

    useEffect(() => {
        navigate('/redirect', { state: location, replace: true });

        // eslint-disable-next-line
    }, []);

    const handleProfileRedirect = useCallback(
        (state: Location) => {
            if (isAuthenticated) {
                return navigate(AppRoutes.PROFILE, {
                    replace: true,
                    state: parse(state.search),
                });
            }
        },
        [isAuthenticated, navigate],
    );

    // Checking `pathname` to see if there is a redirection handler for that case
    //
    // If there is no one, then returning `true` in order to handle default redirect
    switch ((location.state as Location)?.pathname) {
        case undefined:
            return false;

        case AppRoutes.PROFILE:
            return handleProfileRedirect(location.state as Location);

        default:
            return true;
    }
};
