// Core
import React, { FC, ReactElement } from 'react';

// Instruments
import { TextField } from '@mui/material';
import { SignupDto } from 'domains/auth/types/auth';
import { palette } from 'domains/ui/components/_mixins/_palette';

type PhoneNumberStepProps = {
    phoneNumber: string;
    handleSignupDataChange: <K extends keyof SignupDto>(key: K, value: SignupDto[K]) => void;
};

export const PhoneNumberStep: FC<PhoneNumberStepProps> = ({
    phoneNumber,
    handleSignupDataChange,
}): ReactElement => {
    return (
        <TextField
            margin='dense'
            label={`Номер телефону *`}
            // helperText={'Приймуються тільки українські номери'}
            FormHelperTextProps={{
                style: {
                    color: palette.MainBlue,
                    fontSize: 16,
                },
            }}
            type={'tel'}
            fullWidth
            variant='standard'
            inputProps={{
                inputMode: 'numeric',

                style: {
                    fontFamily: 'Montserrat',
                },
            }}
            value={phoneNumber}
            onChange={(e) => handleSignupDataChange('phoneNumber', e.target.value)}
        />
    );
};
