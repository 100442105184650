// Core
import React, { ChangeEvent, CSSProperties, FC, ReactElement } from 'react';

// Instruments
import { GridPlacement } from '../_mixins/GridPlacement';
import { InputContainer } from './styled';

type TextButtonProps = {
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    value: string;
    placeholder?: string;
    disabled?: boolean;
    style?: CSSProperties;
    borderless?: boolean;
    dark?: boolean;
    overrideFocusColor?: string;
} & GridPlacement;

export const Input: FC<TextButtonProps> = (props): ReactElement => {
    return <InputContainer {...props} />;
};
