import styled from 'styled-components';
import { GridPlacement, gridPlacementCss } from '../_mixins/GridPlacement';
import { ButtonText, DarkButtonText } from '../_mixins/TextStyles';
import { palette } from '../_mixins/_palette';

type Props = {
    borderless?: boolean;
    hideArrow?: boolean;
    dark?: boolean;
    overrideHoverColor?: string;
} & GridPlacement;

export const TextButtonContainer = styled.button<Props>`
    cursor: pointer;

    display: grid;
    grid-template-columns: 1fr max-content;
    place-items: center start;
    gap: ${(p) => (p.hideArrow ? 0 : '55px')};
    ${gridPlacementCss}

    height: 55px;

    background: transparent;

    border: none;
    border-top: 1px solid transparent;
    border-bottom: 1px solid
        ${(p) => (p.borderless ? 'transparent' : p.dark ? palette.MainBlack : palette.MainWhite)};

    ${(p) => (p.dark ? DarkButtonText : ButtonText)}

    &:hover {
        border-bottom: 1px solid
            ${(p) =>
                p.borderless
                    ? 'transparent'
                    : p.overrideHoverColor
                    ? p.overrideHoverColor
                    : p.dark
                    ? palette.MainBlack
                    : palette.MainYellow};

        ${(p) => (p.overrideHoverColor ? `color: ${p.overrideHoverColor};` : '')}
    }

    &:disabled {
        cursor: default;

        border-bottom: 1px solid ${(p) => (p.borderless ? 'transparent' : palette.DisabledGrey)};

        &:hover {
            border-bottom: 1px solid ${(p) => (p.borderless ? 'transparent' : palette.DisabledGrey)};
            color: ${palette.DisabledGrey};
        }
    }

    transition: all 300ms linear;
`;
