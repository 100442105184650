import { useBreakpoints } from 'domains/ui/hooks';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Container } from './styles';
import PlaceIcon from '@mui/icons-material/Place';

export const CharityCycledInfo = (): ReactElement => {
    const { breakpoints } = useBreakpoints();
    const [position, setPostion] = useState(0);

    useEffect(() => {
        const element = document.getElementById('about-charity');
        var bodyRect = document.body.getBoundingClientRect();
        if (element) {
            var bodyRect = document.body.getBoundingClientRect(),
            elemRect = element.getBoundingClientRect(),
            offset = elemRect.bottom - bodyRect.top;
            
            setPostion(offset - offset * 0.005);

            const listener = () => {
                setTimeout(() => {
                    var bodyRect = document.body.getBoundingClientRect(),
                        elemRect = element.getBoundingClientRect(),
                        offset = elemRect.bottom - bodyRect.top;
                    setPostion(offset - offset * 0.025);
                }, 10);

                console.log('Element is ' + offset + ' vertical pixels from <body>');
            };
            window.addEventListener('resize', listener);
            return () => {
                window.removeEventListener('resize', listener);
            };
        }
    }, []);
    return (
        <Container style={{ top: position }}>
            <div className='marquee' style={{ fontSize: breakpoints.sm ? '19px' : '15px' }}>
                <p>
                    <span>30 Березня о 18:00</span>
                    {breakpoints.sm ? <></> : <br />}
                    {'       '}Центр Шептицького, УКУ{'       '}
                    {breakpoints.sm && '·'}
                </p>

                {breakpoints.sm && (
                    <p>
                        <span>30 Березня о 18:00</span>
                        {breakpoints.sm ? <></> : <br />}
                        {'       '}Центр Шептицького, УКУ{'       '}·
                    </p>
                )}
            </div>
        </Container>
    );
};
