// Core
import React, { FC, ReactElement } from 'react';

// Instruments
import { Typography } from 'domains/ui/components/_styled/Text';
import { DurationObjectUnits } from 'luxon';
import { MerchantData } from 'domains/main/types/lots.types';
import { BANKA_LOTS_IDS } from 'utils/TEMP_bankaLots';

type LotDataProps = {
    auctionFinished: boolean;
    extendedEndDate: Date | null;
    toEndDate: DurationObjectUnits;
    price: number;
    merchant: MerchantData;
    id: string;
};

export const AuctioCardLotData: FC<LotDataProps> = ({
    auctionFinished,
    extendedEndDate,
    toEndDate,
    price,
    merchant,
    id,
}): ReactElement => {
    return (
        <>
            <Typography.ProductCardInfo>
                {extendedEndDate ? 'Бонусний час:' : 'До закриття лоту:'}
            </Typography.ProductCardInfo>
            <Typography.ProductCardInfo
                placeSelf='center end'
                style={{
                    whiteSpace: 'nowrap',
                }}
            >
                {auctionFinished
                    ? 'Закінчено'
                    : `${toEndDate.days ? `${toEndDate.days} д` : ''}\xa0\xa0${`${
                          toEndDate.hours || 0
                      } год`}\xa0\xa0${toEndDate.minutes ? `${toEndDate.minutes} хв` : ''}`}
            </Typography.ProductCardInfo>

            <Typography.ProductCardInfo>Бенефіціар:</Typography.ProductCardInfo>
            <Typography.ProductCardInfo placeSelf='center end'>
                {merchant.name}
            </Typography.ProductCardInfo>

            <Typography.ProductCardInfo>
                {BANKA_LOTS_IDS.includes(id) ? 'Вартість квитка' : 'Найвища ставка:'}
            </Typography.ProductCardInfo>
            <Typography.ProductCardInfo placeSelf='center end'>
                ₴ {price}
            </Typography.ProductCardInfo>
        </>
    );
};
