import { AxiosResponse } from 'axios';

export enum AuthLocalStorageKeys {
    PHONE_NUMBER = 'auctio-rp-phonenum',
    ACCESS_TOKEN = 'auctio-rp-at',
}

export enum InfoLocalStorageKeys {
    TELEGRAM_POPUP_BID = 'auctio-rp-telegram-after-bid',
    TELEGRAM_POPUP_PAY = 'auctio-rp-telegram-after-pay',
    TELEGRAM_POPUP_VALUE = 'auctio-rp-telegram-value',
}
export enum OtpAction {
    SIGN_UP = 'sign-up',
    LOGIN = 'log-in',
}

export type OtpRequestDto = {
    action: OtpAction;
    phoneNumber: string;
};

export type SignupDto = {
    phoneNumber: string;
    firstName: string;
    lastName: string;
    socialProfiles: string[];
    avatar: string;
    otpCode: string;
};

export type SigninDto = {
    phoneNumber: string;
    otpCode: string;
};

export type AccessTokenResponse = AxiosResponse<{
    accessToken: string;
}>;
