import { GridPlacement } from 'domains/ui/components/_mixins/GridPlacement';
import { palette } from 'domains/ui/components/_mixins/_palette';
import styled from 'styled-components';

type Props = {
    background: 'yellow' | 'blue';
} & GridPlacement;

export const CardDetailsContainer = styled.div<Props>`
    display: grid;
    grid-template-rows: max-content max-content;

    position: relative;
    ${(p) =>
        p.theme.breakpoints.md
            ? ''
            : `
        margin-top: calc(60px);
    `}

    max-width: 820px;
    max-height: 70vh;

    background: ${(p) => (p.background === 'yellow' ? palette.MainYellow : palette.MainBlue)};

    overflow-y: auto;
    width: 90%;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: ${palette.MainBlack};
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
`;

export const CardDetailsLotData = styled.div`
    position: relative;

    display: grid;
    grid-template-columns: ${(p) => (p.theme.breakpoints.md ? '270px 1fr' : '1fr')};
    grid-template-rows: ${(p) => (p.theme.breakpoints.md ? 'auto 2fr' : 'minmax(270px, 300px)')};
    grid-auto-rows: max-content;
    gap: 30px;

    width: 100%;
    height: 100%;
    padding: ${(p) => (p.theme.breakpoints.md ? '55px 40px' : '70px 15px 20px')};
`;

export const CardDetailsImage = styled.div.attrs((props: { color: string }) => props)`
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    grid-column: 1;
    grid-row: 1 / -1;
    width: 100%;
    height: 100%;
    min-width: 270px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &::before {
        background-image: url(${(props) => props.color});
    }
    ${(p) =>
        p.theme.breakpoints.md &&
        `&::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        z-index: -1;

        display: block;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 102%;

        -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        -o-filter: blur(5px);
        -ms-filter: blur(5px);
        filter: blur(5px);
    }
    & svg {
        opacity: 0;
        font-size: 50px;
    }
    &:hover {
        filter: brightness(1);
        & svg {
            opacity: 1;
        }
    }`};
`;

export const CardDetailsBidControls = styled.div`
    position: relative;

    display: grid;
    grid-template-rows: auto 1fr 50px;
    gap: 15px;

    width: 100%;
    height: 100%;
    padding: 40px;

    padding: ${(p) => (p.theme.breakpoints.md ? '40px' : '15px')};

    background: ${palette.MainWhite};
`;

export const CardLotDataText = styled.div`
    display: grid;
    grid-template-columns: 1fr max-content;
    justify-content: space-between;
    gap: 10px;
`;
