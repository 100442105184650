import { css, DefaultTheme } from 'styled-components';

type DataWithBreakpoints<T = string | number> = {
    xl?: T;
    lg?: T;
    md?: T;
    sm?: T;
    xs: T;
};

type GridPlacementData<T = string | number> = T | DataWithBreakpoints<T>;

export type GridPlacement = {
    gridColumn?: GridPlacementData;
    gridRow?: GridPlacementData;
    placeSelf?: GridPlacementData<string>;
};

const parsePlacementObject = (
    key: string,
    data: DataWithBreakpoints,
    { breakpoints: { xl, lg, md, sm } }: DefaultTheme,
): string => {
    //  XL breakpoint + CSS value suitable for XL
    if (data.xl && xl) return `${key}: ${data.xl};`;

    //  XL/LG breakpoint + CSS value suitable for LG
    if (data.lg && [xl, lg].some(Boolean)) return `${key}: ${data.lg};`;

    //  XL/LG/MD breakpoint + CSS value suitable for MD
    if (data.md && [xl, lg, md].some(Boolean)) return `${key}: ${data.md};`;

    // SM
    if (data.sm && [xl, lg, md, sm].some(Boolean)) return `${key}: ${data.sm};`;

    // XS
    return `${key}: ${data.xs};`;
};

export const gridPlacementCss = ({ gridColumn, gridRow, placeSelf }: GridPlacement) => css`
    ${(p) => {
        if (!gridColumn) return '';

        if (typeof gridColumn === 'string' || typeof gridColumn === 'number')
            return `grid-column: ${gridColumn};`;

        return parsePlacementObject('grid-column', gridColumn, p.theme);
    }}

    ${(p) => {
        if (!gridRow) return '';

        if (typeof gridRow === 'string' || typeof gridRow === 'number')
            return `grid-row: ${gridRow};`;

        return parsePlacementObject('grid-row', gridRow, p.theme);
    }}

    ${(p) => {
        if (!placeSelf) return '';

        if (typeof placeSelf === 'string' || typeof placeSelf === 'number')
            return `place-self: ${placeSelf};`;

        return parsePlacementObject('place-self', placeSelf, p.theme);
    }}
`;
