import styled from 'styled-components';
import { GridPlacement, gridPlacementCss } from 'domains/ui/components/_mixins/GridPlacement';
import { TextButton } from 'domains/ui/components/TextButton';
import { palette } from 'domains/ui/components/_mixins/_palette';

export const ProfileTabsContainer = styled.div<GridPlacement>`
    ${gridPlacementCss}

    display: grid;
    row-gap: ${(p) => (p.theme.breakpoints.md ? '80px' : '50px')};

    width: 100%;
`;

export const Tab = styled(TextButton)<{ active?: boolean }>`
    ${(p) => (p.active ? `color: ${palette.MainYellow};` : '')}
`;
