// Core
import React, { FC, ReactElement } from 'react';

// UI
import { GridPlacement } from 'domains/ui/components/_mixins/GridPlacement';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { GridItem } from 'domains/ui/components/_styled/GridItem';
import { Typography } from 'domains/ui/components/_styled/Text';

//  Assets
import fallback from 'theme/assets/svg/userpic-placeholder.svg';
import { notifications } from 'utils/notifications';
import { useBreakpoints } from 'domains/ui/hooks';

type ProfilePhotoProps = {
    src?: string;
} & GridPlacement;

export const ProfilePhoto: FC<ProfilePhotoProps> = ({ src, ...gridPlacement }): ReactElement => {
    const { breakpoints } = useBreakpoints();

    return (
        <GridItem
            {...gridPlacement}
            style={{
                display: 'grid',
                gridTemplateRows: breakpoints.sm ? '300px 50px' : 'auto 50px',

                width: breakpoints.sm ? 300 : '100%',
            }}
        >
            <img
                src={src || fallback}
                alt='profile'
                style={{
                    height: '100%',
                    width: '100%',
                    background: palette.MainWhite,
                }}
            />

            <button
                style={{
                    cursor: 'pointer',
                    border: 'none',
                    background: palette.MainYellow,
                }}
            >
                <Typography.Span
                    style={{ color: palette.MainBlack }}
                    onClick={() =>
                        notifications.info('Оновлення фото буде доступне трошечки згодом')
                    }
                >
                    Оновити фото
                </Typography.Span>
            </button>
        </GridItem>
    );
};
