export const phoneNumberTransform = (phoneNumber: string): string | false => {
    if (phoneNumber.startsWith('+')) return phoneNumber;

    const pureNumber = phoneNumber.replace(/\D/g, '');

    if (pureNumber.length !== 10 && pureNumber.length !== 12) return false;
    if (pureNumber.length === 12 && !pureNumber.startsWith('380')) return false;
    if (pureNumber.length === 10 && !pureNumber.startsWith('0')) return false;

    return pureNumber.length === 12 ? `+${pureNumber}` : `+38${pureNumber}`;
};
